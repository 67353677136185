import React, { Component} from 'react';
import Logo from '../images/logo_main.png';
import LogoSmall from '../images/logo_small.png';
import NoAvatar from '../images/no-avatar.svg';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import { userAction } from '../actions/userAction';
import {common} from '../utility/common';
import { connect } from "react-redux";
import {permissionAction} from '../actions/permissionAction';
class AppHeader extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false,
          open: this.props.open ? this.props.open : false,
          rs: _.get(window.ePayment,'resource'),
          fullName: common.getFullName(),
          provider: common.getProvider(),
        };
    }

    toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
    }
    componentWillMount(){
        this.props.getUserInfo();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.open !== this.state.open){
          this.setState({open:nextProps.open});
        }
    }

    render(){
        return (
            <header className="app-header">
                <Navbar expand="md" fixed={`top`}>
                    <div className="logo-area">
                        <NavbarBrand href="/"><img className="full_logo" src={Logo} alt="logo"/><img className="small_logo" src={LogoSmall} alt="logo"/></NavbarBrand>
                    </div>
                    <button type="button" data-toggle="sidebar" className="sidebar_toggle" onClick={this.props.onClick}>
                        <i className="fa fa-bars"></i>
                    </button>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                <img src={NoAvatar} alt="avarta" className="img-circle img-inline" />
                                <span>{this.state.fullName} <i className="fas fa-igloo"></i></span>
                            </DropdownToggle>
                            <DropdownMenu right>
                            <DropdownItem>
                            <NavLink className="nav-link" to={'/doi-mat-khau'} activeClassName="active">
                            <i className="fa fa-user"></i> {_.get(this.state.rs,'appHeader.changepassword')}
                            </NavLink>
                            </DropdownItem>
                            <DropdownItem onClick={() => this.props.logout()}>
                            <i className="fa fa-lock"></i> {_.get(this.state.rs,'appHeader.logout')}
                            </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </header>
        )
    }
}

export default connect(null, {getUserInfo: permissionAction.getUserInfo, logout: userAction.logout})(AppHeader);
