import React, { Component } from "react";
import _ from 'lodash';
import makeAnimated from "react-select/animated";
import MySelect from "./renderReactSelectMulti";
import { components } from "react-select";

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const allOption = {
  label: "Select all",
  value: "*"
};

const ValueContainer = ({ children, ...rest }) => {
  let currentValues = rest.getValue();
  let selectedCount = rest.getValue().length;
  let conditional = (selectedCount < 2);
  let selectedAll = currentValues.some(val => val.value === allOption.value);

  let firstChild = []

  if (!conditional) {
    firstChild = [children[0].shift(), children[1]]
  }
  return (
    <components.ValueContainer {...rest}>
      {conditional ? children : firstChild}
      {!conditional && !selectedAll && ` and ${selectedCount - 1} others`}
    </components.ValueContainer>
  )
}

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All is selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const animatedComponents = makeAnimated();
const Example = ({input, data, valueField, textField, meta, showError, showWarn, ...rest}) => {
    return (
      <span
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please selecet account(s)"
      >
        <MySelect
            {...input}
            options={data}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            placeholder=""
            components={{
                Option,
                MultiValue,
                ValueContainer,
                animatedComponents
            }}
            className="multi-select-custom"
            onChange={input.onChange} 
            onBlur={() => input.onBlur(input.value)} 
            allowSelectAll={true}
            isClearable
            value={input.value || []}
        />
      </span>
    );
}

const SingleSelect = ({input, data, valueField, textField, meta, showError, showWarn, ...rest}) => {
  return (
    <span
      data-toggle="popover"
      data-trigger="focus"
      data-content="Please selecet account(s)"
    >
      <MySelect
          {...input}
          options={data}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          placeholder=""
          components={{
              ValueContainer,
              animatedComponents
          }}
          className="multi-select-custom"
          onChange={input.onChange} 
          onBlur={() => input.onBlur(input.value)} 
          isClearable
          value={input.value || []}
      />
    </span>
  );
}

export {Example, SingleSelect};