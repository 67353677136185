import React, {Component} from 'react';
import _ from 'lodash';
import UserListing from '../components/UserListing';


class UserPage extends Component {
    constructor (props, context) {
        super(props,context);
        this.state={
            catalogName: null,
            rs: _.get(window.ePayment,'resource'),
            actionType: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        // this.setState({'catalogName': 'user'});
    }
    componentDidMount(){
        this.setState({'catalogName': 'user'});
    }

    _handleFormUser=(type_action)=>{
        this.setState({actionType: type_action});
    }

    _handleChangeTypeAction = () => {
      this.setState({actionType: null});
    }

    render() {
        const catalogName = this.state.catalogName;

        return (
            <div className="main-box">
                <h5 className="page-header">Quản lý nhân viên</h5>

                <UserListing
                  catalogName={catalogName}
                  actionType={this.state.actionType}
                  handleFormUser={this._handleFormUser}
                  handleChangeTypeAction={this._handleChangeTypeAction}/>
            </div>
        )
    }
}

export default UserPage;
