import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Container,Row, Col } from 'reactstrap';
import _ from 'lodash';
import {actionTypes} from '../constants/actionTypes';
import { Field} from 'redux-form';
import renderInput from '../controls/renderInput';
import renderInputFile from '../controls/renderInputFile';
import renderCheckbox from '../controls/renderCheckbox';
import validate from '../validate/validate';
import {matchInput} from '../validate/ismatch';

class UserFormModal extends React.Component {
	constructor(props,context) {
		super(props,context);
		this.state={
			passwordDefault:true
		}
    }
    
    toggleCheckboxPasswordDefault =()=>{
        this.setState({passwordDefault:!this.state.passwordDefault});
    }

	render(){
        const rs = _.get(window.ePayment,'resource');
        const { isOpen, toggleFn, typeForm, dispatch } = this.props;
        const { handleSubmit, pristine, submitting, valid} = this.props.props;
		return(
			<div>
            <Modal isOpen={isOpen} toggle={toggleFn} wrapClassName='e-safety' backdrop='static'>
                <form onSubmit={handleSubmit} autoComplete="off" role="presentation">
                    <ModalHeader toggle={toggleFn}>{typeForm ==='add' ? <>{_.get(rs,'appModal.addTitle')}</> : typeForm ==='update' ? <>{_.get(rs,'appModal.editTitle')}</> : typeForm ==='import' ? <>{_.get(rs,'appModal.importUser')}</>:<>{_.get(rs,'appModal.resetUserPassword')}</>}</ModalHeader>
                    <ModalBody>
                      <Container fluid>
                          <Row>
                          {typeForm !== 'import' && typeForm !== 'reset' &&<><Col md='12'>
                                <FormGroup>
                                    <Label>Họ tên</Label>
                                    <Field name="fullName" validate={validate.required} component={renderInput}/>
                                </FormGroup>
                            </Col>
                             <Col md='12'>
                                  <FormGroup>
                                      <Label>Email</Label>
                                      <Field name="email" validate={[validate.required, validate.email]} component={renderInput}/>
                                  </FormGroup>
                              </Col></>}
                              {typeForm === 'add' && <Col md='12'>
                                  <FormGroup>
                                      <Label>Tên đăng nhập</Label>
                                      <Field name="userName" validate={[validate.required, validate.maxLengthUserName]} component={renderInput}/>
                                  </FormGroup>
                              </Col>}
                              {typeForm ==='update' && (<Col md='12'>
                                  <FormGroup>
                                  <Row style={{marginLeft:'0px'}}>
                                      <Field name="lockoutEnabled"  type='checkbox' component={renderCheckbox}/>
                                      <Label>&nbsp;&nbsp;Khóa tài khoản</Label>
                                  </Row>
                                  </FormGroup>
                              </Col>)}
                              {typeForm ==='add' && (<Col md='12'>
                                  <FormGroup>
                                  <Row style={{marginLeft:'0px'}}>
                                      <Field name="UseDefaultPassword" onChange={this.toggleCheckboxPasswordDefault} checked={this.state.passwordDefault} type='checkbox' component={renderCheckbox}/>
                                      <Label> &nbsp;&nbsp;Sử dụng mật khẩu mặc định</Label>
                                  </Row>
                                  </FormGroup>
                              </Col>)}
                              {typeForm === 'add' && !this.state.passwordDefault && <><Col md='12'>
                                  <FormGroup>
                                      <Label>Mật khẩu</Label>
                                      <Field type="password" name="password" autoComplete='new-password' validate={[validate.required, validate.minLengthPassword, matchInput]} component={renderInput}/>
                                  </FormGroup>
                              </Col>
                              <Col md='12'>
                                  <FormGroup>
                                      <Label>Xác nhận Mật khẩu</Label>
                                      <Field type="password" name="confirmPassword"  validate={[validate.required, validate.minLengthPassword, matchInput]} component={renderInput}/>
                                  </FormGroup>
                              </Col></>}
                              {typeForm === 'import' && <Col md='12'>
                                  <FormGroup>
                                      <Field name="import" validate={[validate.required]} component={renderInputFile} type={"file"} />
                                  </FormGroup>
                              </Col>}
                              {typeForm === 'reset' && <Col md='12'>
                              <FormGroup>
                                      <Label>Tên đăng nhập</Label>
                                      <Field disabled name="userName" component={renderInput}/>
                                  </FormGroup>
                              </Col>}
                          </Row>
                      </Container>
                    </ModalBody>
                    <ModalFooter>
                        {typeForm === 'reset' && 
                        <Button color="info" type="submit" >{_.get(rs,'btnNames.ok')}</Button>}
                        {typeForm !== 'reset' && 
                        <Button color="info" type="submit" disabled={!valid || pristine || submitting}>{_.get(rs,'btnNames.save')}</Button>}{' '}
                        <Button color="secondary" onClick={e => {
                            toggleFn&&toggleFn();
                            dispatch({type: actionTypes.CATELOGORY_ITEM_SELECTED,data: null});
                        }}>{_.get(rs,'btnNames.cancel')}</Button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
		)
	}
}

// const UserFormModal = props =>  {
//     const rs = _.get(window.ePayment,'resource');
//     const { isOpen, toggleFn, typeForm, dispatch } = props;
//     const { handleSubmit, pristine, submitting, valid} = props.props;
//     return(
//         <div>
//             <Modal isOpen={isOpen} toggle={toggleFn} wrapClassName='e-safety' backdrop='static'>
//                 <form onSubmit={handleSubmit} autoComplete="off" role="presentation">
//                     <ModalHeader toggle={toggleFn}>{typeForm ==='add' ? <>{_.get(rs,'appModal.addTitle')}</> : typeForm ==='update' ? <>{_.get(rs,'appModal.editTitle')}</> : <>{_.get(rs,'appModal.importUser')}</>}</ModalHeader>
//                     <ModalBody>
//                       <Container fluid>
//                           <Row>
//                           {typeForm !== 'import' && <><Col md='12'>
//                                 <FormGroup>
//                                     <Label>Họ tên</Label>
//                                     <Field name="fullName" validate={validate.required} component={renderInput}/>
//                                 </FormGroup>
//                             </Col>
//                              <Col md='12'>
//                                   <FormGroup>
//                                       <Label>Email</Label>
//                                       <Field name="email" validate={[validate.required, validate.email]} component={renderInput}/>
//                                   </FormGroup>
//                               </Col></>}
//                               {typeForm === 'add' && <Col md='12'>
//                                   <FormGroup>
//                                       <Label>Tên đăng nhập</Label>
//                                       <Field name="userName" validate={[validate.required, validate.maxLengthUserName]} component={renderInput}/>
//                                   </FormGroup>
//                               </Col>}
//                               {typeForm ==='update' && (<Col md='12'>
//                                   <FormGroup>
//                                       <Label>Khóa tài khoản</Label>
//                                       <Field name="lockoutEnabled"  type='checkbox' component={renderCheckbox}/>
//                                   </FormGroup>
//                               </Col>)}
//                               {typeForm ==='add' && (<Col md='12'>
//                                   <FormGroup>
//                                       <Label>Dùng Mật Khẩu Mặc Định</Label>
//                                       <Field name="passwordDefault" onClick={this.toggle.bind(this)} type='checkbox' component={renderCheckbox}/>
//                                   </FormGroup>
//                               </Col>)}
//                               {typeForm === 'add' && <><Col md='12'>
//                                   <FormGroup>
//                                       <Label>Mật khẩu</Label>
//                                       <Field type="password" name="password" autoComplete='new-password' validate={[validate.required, validate.minLengthPassword, matchInput]} component={renderInput}/>
//                                   </FormGroup>
//                               </Col>
//                               <Col md='12'>
//                                   <FormGroup>
//                                       <Label>Xác nhận Mật khẩu</Label>
//                                       <Field type="password" name="confirmPassword"  validate={[validate.required, validate.minLengthPassword, matchInput]} component={renderInput}/>
//                                   </FormGroup>
//                               </Col></>}
//                               {typeForm === 'import' && <Col md='12'>
//                                   <FormGroup>
//                                       <Field name="import" validate={[validate.required]} component={renderInputFile} type={"file"} />
//                                   </FormGroup>
//                               </Col>}
//                           </Row>
//                       </Container>
//                     </ModalBody>
//                     <ModalFooter>
//                         <Button color="info" type="submit" disabled={!valid || pristine || submitting}>{_.get(rs,'btnNames.save')}</Button>{' '}
//                         <Button color="secondary" onClick={e => {
//                             toggleFn&&toggleFn();
//                             dispatch({type: actionTypes.CATELOGORY_ITEM_SELECTED,data: null});
//                         }}>{_.get(rs,'btnNames.cancel')}</Button>
//                     </ModalFooter>
//                 </form>
//             </Modal>
//         </div>
//     )
// }

export default UserFormModal;
