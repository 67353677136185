import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {permissionAction} from '../actions/permissionAction';
import {common} from '../utility/common';

const withPermission = (options) => (Component) => {
    class permissionHOC extends React.Component{
        checkPermissionFeature = (code, mode) =>{
            let isCheck = false;
            let item;
            let userInfo = this.props.userInfo;
            if (userInfo !== null && userInfo !== undefined)
                item = _.find(userInfo.securityUserPermissions, (s) => {return s.featureCode === code;});
            if(item !== null && item !== undefined){
              if(mode === 'view') isCheck = item.view;
              if(mode === 'add') isCheck = item.add;
              if(mode === 'edit') isCheck = item.edit;
              if(mode === 'delete') isCheck = item.delete;
            }
            return isCheck;
        }
        render(){
            return (<div>
                {this.props.userInfo !== null && <Component {...this.props} checkPermissionFeature={this.checkPermissionFeature} />}
            </div>)
        }
    }
    const mapStateToProps = state => {
        return {
            userInfo: state.userReducer.userInfo,
        }
    }
    return connect(mapStateToProps,{getUserInfo: permissionAction.getUserInfo})(permissionHOC);
}

export default withPermission;
