import React from 'react';
import PropTypes from 'prop-types';
import {FieldProps} from './FieldProps';
import {inputError} from './inputError';
import { Multiselect } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';


const renderMultiselect = ({input, data, defaultValue, groupBy, valueField, textField, allowCreate, dropUp, filter, disabled, meta, showError, showWarn, ...rest}) =>{
    return (
        <Multiselect {...input}
            onBlur={() => input.onBlur()}
            value={input.value || []}
            data={data}  
            textField={textField}
            defaultValue={defaultValue}
            valueField={valueField}
            disabled={disabled}
            dropUp={dropUp}
            filter={filter}
            onChange={input.onChange} 
            className={`${meta.error ? ' is-invalid' : ''}`}
        />
    );
}


renderMultiselect.propTypes = {
    groupBy: PropTypes.string,
    ...FieldProps
};

export default inputError(renderMultiselect);