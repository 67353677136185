import React from 'react';
import _ from 'lodash';
import { FormGroup, Label, Container,Row, Col } from 'reactstrap';
import { Field} from 'redux-form';
import renderInput from '../controls/renderInput';
import renderCheckbox from '../controls/renderCheckbox';
import renderTextarea from '../controls/renderTextarea';
import renderDropdownList from '../controls/renderDropdownList';
import validate from '../validate/validate';

const PunishmentForm = props => {
        const rs = _.get(window.ePayment,'resource');
        return(
            <Container fluid>
                <Row>
                    <Col md='12'>
                        <FormGroup>
                            <Label>{_.get(rs,'punishmentCategory.code')}</Label>
                            <Field name="punishmentCode" type='text' validate={validate.required} component={renderInput}/>
                        </FormGroup>
                    </Col>
                    <Col md='12'>
                        <FormGroup>
                            <Label>{_.get(rs,'punishmentCategory.name')}</Label>
                            <Field name="punishmentName" type='text' validate={validate.required} component={renderInput}/>
                        </FormGroup>
                    </Col>
                    <Col md='12'>
                        <FormGroup>
                            <Label>{_.get(rs,'categories.parrent')}</Label>
                            <Field name="parentID"
                                    component={renderDropdownList}
                                    valueField ='id'
                                    textField ='punishmentName'
                                    data={props.punishmentParents !== null ? props.punishmentParents : []}/>
                        </FormGroup>
                    </Col>
                    <Col md='12'>
                        <FormGroup>
                            <Label>{_.get(rs,'punishmentCategory.penaltyfee')}</Label>
                            <Field name="penaltyFee" type='text' validate={validate.required} component={renderInput}/>
                        </FormGroup>
                    </Col>
                    <Col md='6'>
                        <FormGroup>
                            <Label>{_.get(rs,'categories.sortorder')}</Label>
                            <Field name="sortOrder" type='text' validate={[validate.number, validate.integer]} component={renderInput}/>
                        </FormGroup>
                    </Col>
                    <Col md='6'>
                        <FormGroup>
                            <Label>{_.get(rs,'categories.active')}</Label>
                            <Field name="isActive" type='checkbox' component={renderCheckbox}/>
                        </FormGroup>
                    </Col>
                    <Col md='12'>
                        <FormGroup>
                            <Label>{_.get(rs,'categories.note')}</Label>
                            <Field name="note" type='text' component={renderTextarea}/>
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
        )
    }

export default PunishmentForm;
