import React, {Component} from 'react';
import {compose} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import $ from 'jquery';
import config from 'config';
import { FormGroup, Label, Row,Col, Container } from 'reactstrap';
import { reduxForm, reset, Field , getFormValues, formValueSelector, change} from 'redux-form';
import {actionTypes} from '../constants/actionTypes';
import {categoryAction} from '../actions/categoryAction';
import { commonActions } from '../actions/commonAction';
import renderDatePicker from '../controls/renderDatePicker';
import commonWrapped from '../hocs/commonWrapped';
import withPermission from '../hocs/withPermission';
import {common} from '../utility/common';
import {Example, SingleSelect} from "../controls/renderMultiCheckbox";

class ReportListing extends Component {
    constructor (props, context){
        super(props, context);
        this.state={
            selectedItem: null,
            typeForm: null,
            pageOfItems: [],
            initialPage: 1,
            pageSize: 20,
            search: "",
            afterFilter: [],
            reportName: null,
            originalList: null,
            openFilter: false,
            actionType: null,
            fromDate: this.props.initialValues.fromDate,
            toDate: this.props.initialValues.toDate,
            projectID: null,
            firstLoad: false,
            //selectProject: 0,
        };
    }


    componentDidMount(){
        this.setState({openFilter: false});
        // this.props.dispatch(reset(this.props.form));
        this.props.dispatch(commonActions.request(actionTypes.REPORT_DATA_REQUEST));
        this.props.dispatch(categoryAction.getViolationCategoryType())
        Promise.all([this._loadUser(), this._loadProjectByUser({userName: ''}),this._loadAllContractor(),this._loadAllTeam()]).then(results=>{
            this.props.dispatch({type: actionTypes.REPORT_HAZAROUS_SUCCESS, data: results[1]});
            this.props.dispatch(commonActions.request(actionTypes.REPORT_DATA_SUCCESS, {results}));
            setTimeout(function(){
                $('#testSearch').trigger('click');
            }, 500);
        },err=>{
            this.props.dispatch(commonActions.request(actionTypes.REPORT_DATA_FAILURE, err));
            setTimeout(function(){
                $('#testSearch').trigger('click');
            }, 500);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let sefl = this;
        const reportName = this.props.reportName;
        if(prevProps.reportName !== reportName){
            setTimeout(function(){
                $('#token').val(common.getToken());
                $('#fromDateReport').val(moment(sefl.props.fromDate,'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'));
                $('#toDateReport').val(moment(sefl.props.toDate,'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'));
                $('#testSearch').trigger('click');
            }, 500);
            this.props.dispatch(reset(this.props.form));
            this._resetListForm();
            this._loadProjectByUser(null);
            this.setState({
                openFilter: false
            });
        }
        if(!this.state.firstLoad && prevProps.reportName !== reportName){
            this.setState({firstLoad: true});
        }
    }

    _resetListForm = () => {
        const resetListValue = [
            actionTypes.GET_TOWER_BY_PROJECT_SUCCESS,
            actionTypes.GET_LEVEL_BY_PROJECT_TOWER_SUCCESS,
            actionTypes.GET_ZONE_BY_PROJECT_LEVEL_SUCCESS
        ]
        for(let i=0; i<resetListValue.length; i++){
            this.props.dispatch({type: resetListValue[i], data: []});
        }
    }

    _loadUser = () => {
      return new Promise((resolve, reject) => {
          this.props.dispatch(categoryAction.getEngineers(resolve, reject));
      });
    }

    _loadProjectByUser = (e) => {
      let data = e;
      if(e === null){
        data = {userName: ""}
      }
      const resetValue = ['project','tower','level','zone'];
      const resetListValue = [
        actionTypes.GET_TOWER_BY_PROJECT_SUCCESS,
        actionTypes.GET_LEVEL_BY_PROJECT_TOWER_SUCCESS,
        actionTypes.GET_ZONE_BY_PROJECT_LEVEL_SUCCESS
      ]
      for(let i=0; i<resetValue.length; i++){
        this.props.dispatch(change(this.props.form, resetValue[i], null));
      }
      for(let i=0; i<resetListValue.length; i++){
        this.props.dispatch({type: resetListValue[i], data: []});
      }
      return new Promise((resolve, reject) => {
        this.props.dispatch(categoryAction.getProjectByEngineer(data, resolve, reject));
        });
    }

    _loadAllContractor= (e) => {
        return new Promise((resolve, reject) => {
            this.props.dispatch(categoryAction.getAllContractor(resolve, reject));
            //this.props.dispatch(categoryAction.getTowerFacZoneByProjectReport({projectID: e[0].id, parentID: null }, 'tower'));
        });
    }

    _loadAllTeam= (e) => {
        return new Promise((resolve, reject) => {
            this.props.dispatch(categoryAction.getAllTeam(resolve, reject));
        });
    }

    _loadTowerByProject =(e)=>{
        const resetValue = ['project','tower','level','zone'];
        for(let i=0; i<resetValue.length; i++){
            this.props.dispatch(change(this.props.form, resetValue[i], null));
        }
        const resetListValue = [
            actionTypes.GET_TOWER_BY_PROJECT_SUCCESS,
            actionTypes.GET_LEVEL_BY_PROJECT_TOWER_SUCCESS,
            actionTypes.GET_ZONE_BY_PROJECT_LEVEL_SUCCESS
          ]
          for(let i=0; i<resetListValue.length; i++){
            this.props.dispatch({type: resetListValue[i], data: []});
          }
        if(e.length!==null){
            if(e.length==1){
                //Nếu chọn 1 dự án thì load Tháp
                return new Promise((resolve, reject) => {
                    this.props.dispatch(categoryAction.getTowerFacZoneByProjectReport({projectID: e[0].id, parentID: null }, 'tower'));
                }); 
            }else{
                //Nếu 0 chọn hoặc chọn >1 ->update lại reducer null
                return new Promise((resolve, reject) => {
                    this.props.dispatch({type: actionTypes.GET_NULL_TOWER_SUCCESS});
                });
            } 
        }  
    }

    _loadLevelByTower = (e) => {
        const resetValue = ['level','zone'];
        for(let i=0; i<resetValue.length; i++){
            this.props.dispatch(change(this.props.form, resetValue[i], null));
        }
        const resetListValue = [
            actionTypes.GET_LEVEL_BY_PROJECT_TOWER_SUCCESS,
            actionTypes.GET_ZONE_BY_PROJECT_LEVEL_SUCCESS
          ]
          for(let i=0; i<resetListValue.length; i++){
            this.props.dispatch({type: resetListValue[i], data: []});
          }
        if(e.length!==null){
            if(e.length==1){
                //Nếu chọn 1 Tháp thì load Tầng
                const objTmp = e[0];
                const data = {
                projectID: objTmp !== undefined ? objTmp.projectID : null,
                parentID: objTmp !== undefined ? objTmp.id : null
                }
               return new Promise((resolve, reject) => {
                this.props.dispatch(categoryAction.getTowerFacZoneByProjectReport(data, 'level'));
               }); 
           }else{
                 //Nếu 0 chọn hoặc chọn >1 ->update lại reducer null
               return new Promise((resolve, reject) => {
                   this.props.dispatch({type: actionTypes.GET_NULL_LEVEL_SUCCESS});
                   this.props.dispatch({type: actionTypes.GET_NULL_ZONE_SUCCESS});
               });
           } 
        }
    }

    _loadZoneByLevel = (e) => {
            const resetValue = ['zone'];
            for(let i=0; i<resetValue.length; i++){
                this.props.dispatch(change(this.props.form, resetValue[i], null));
            }
            const resetListValue = [
                actionTypes.GET_ZONE_BY_PROJECT_LEVEL_SUCCESS
              ]
              for(let i=0; i<resetListValue.length; i++){
                this.props.dispatch({type: resetListValue[i], data: []});
              }
            const data = {
                projectID: (e !== undefined && e !== null) ? e.projectID : null,
                parentID: (e !== undefined && e !== null) ? e.id : null
            }
           return new Promise(() => {
            this.props.dispatch(change(this.props.form, 'zone', null));
            this.props.dispatch(categoryAction.getTowerFacZoneByProjectReport(data, 'zone'));
           }); 
    }

    _toggleFilter=()=>{
        this.setState({
            openFilter: !this.state.openFilter
        });
    }
    
    submit(data, projectsByUser){
        var arrayContractor=[] , arrayProject =[] , arrayTeam = [], arrayTower =[],arrayProjectsByUser =[] ;
        $('#token').val(common.getToken());
        $('#fromDateReport').val(moment(data.fromDate,'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'));
        $('#toDateReport').val(moment(data.toDate,'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss'));
        $('#userNameReport').val((data.user !== undefined && data.user !== null) ? data.user.userName : null);
        if(projectsByUser!=null){
            for(let i =0;i<projectsByUser.length;i++){
                arrayProjectsByUser.push(projectsByUser[i].id); 
              }
        }
        if(data.project!=null){
            for(let i =0;i<data.project.length;i++){
                arrayProject.push(data.project[i].id); 
              }
        }
        $('#projectIDReport').val((data.project !== undefined && data.project !== null && data.project.length>0) ? arrayProject : arrayProjectsByUser);
        if(data.contractor!=null){
            for(let i =0;i<data.contractor.length;i++){
                arrayContractor.push(data.contractor[i].id); 
              }
        }
        $('#contractorIDReport').val((data.contractor !== undefined && data.contractor !== null) ? arrayContractor : null);

        if(data.team!=null){
            for(let i =0;i<data.team.length;i++){
                arrayTeam.push(data.team[i].id); 
              }
        }
        $('#teamIDReport').val((data.team !== undefined && data.team !== null) ? arrayTeam : null);
        
        if(data.tower!=null){
            for(let i =0;i<data.tower.length;i++){
                arrayTower.push(data.tower[i].id); 
              }
        }
        $('#towerIDReport').val((data.tower !== undefined && data.tower !== null) ? arrayTower : null);

        $('#zoneIDReport').val((data.zone !== undefined && data.zone !== null) ? data.zone.id : null);
        $('#levelIDReport').val((data.level !== undefined && data.level !== null) ? data.level.id : null);
        $('#violationCatTypeReport').val((data.violationType !== undefined && data.violationType !== null) ? data.violationType.generalCategoryCode : null);
        var form = $('#myIframe');
        var action = $(this).data('action');
        form.attr('action', action);
        form.submit();
    }

    render(){
        const { openFilter } = this.state;
        const { checkPermissionFeature, featureName, violationCategoryType} = this.props;
        return(
            checkPermissionFeature(featureName, 'view') ? <Container fluid>
                
                  {renderActionFilterForm(openFilter, violationCategoryType, {...this.props}, {
                    toggleFilter: this._toggleFilter,
                    loadProjectByUser: this._loadProjectByUser,
                    loadTowerByProject:this._loadTowerByProject,
                    loadLevelByTower: this._loadLevelByTower,
                    loadZoneByLevel: this._loadZoneByLevel,
                    submit: this.submit
                  })}
                <Row>

                <iframe style={{width: '100%', height: 'calc(100vh - 210px)', border: 'none'}} src="" name="myIframe"></iframe>
                
                </Row>
            </Container> : <Container><div><h5 className="text-center">Bạn không có quyền truy cập trang này, vui lòng liên hệ admin!</h5></div></Container>
        );
    }
}

const renderActionFilterForm = (openFilter,types, props, fns) => {
    const {reportName} = props;
    const {toggleFilter, loadProjectByUser, loadLevelByTower, loadZoneByLevel, submit,loadTowerByProject}=fns;
    const apiUrl = (reportName === 'hazardous') ? 'hazardreport' : (reportName === 'violation') ? 'violationreport' : null;
    return(
      <>
      <div className="filter-group">
        <div className="action-group">
          <button className="btn btn-primary" type="button" onClick={e => {toggleFilter()}}>
          {openFilter ? <><i className="fa fa-times" aria-hidden="true"></i> Đóng</> : <><i className="fa fa-search" aria-hidden="true"></i> Tìm kiếm</>}
          </button>
        </div>
      </div>
      <div className={"form-sub-category filter " + (openFilter ? 'show' : 'hide')}>
        <Container fluid>
            <Row className='filter'>
                <Col className='pl-0 mt-2'></Col>
                <Col className="pr-0 text-right col">
                  <form style={{display:'inline-block'}} target="myIframe" action={`${config.apiUrl}/home/${apiUrl}`} method="post">
                      <input type="hidden" id="token" name="token" value="" />
                      <input type="hidden" id="fromDateReport" name="fromDate"/>
                      <input type="hidden" id="toDateReport" name="toDate"/>
                      <input type="hidden" id="userNameReport" name="username" />
                      <input type="hidden" id="contractorIDReport" name="contractorIDs" />
                      <input type="hidden" id="projectIDReport" name="projectIDs"/>
                      <input type="hidden" id="teamIDReport" name="teamIDs"/>
                      <input type="hidden" id="towerIDReport" name="towerFacIDs" />
                      <input type="hidden" id="zoneIDReport" name="zoneIDs"/>
                      <input type="hidden" id="levelIDReport" name="levelIDs"/>
                      <input type="hidden" id="violationCatTypeReport" name="violationCatType" />
                      <input type="submit" className="btn btn-primary" id="testSearch" value="Tìm kiếm" onClick={() => {submit(props.formValues, props.projectsByUser)}}/>
                  </form>
              </Col>
            </Row>
            <form>
            <Row>

                <Col md={reportName === 'hazardous' ? '4' : reportName === 'violation' ? '2' : '4'}> 
                    <FormGroup>
                        <Label>Từ ngày</Label>
                        <Field name="fromDate" component={renderDatePicker}/>
                    </FormGroup>
                </Col>
                <Col md={reportName === 'hazardous' ? '4' : reportName === 'violation' ? '2' : '4'}> 
                    <FormGroup>
                        <Label>Đến ngày</Label>
                        <Field name="toDate" component={renderDatePicker}/>
                    </FormGroup>
                </Col>
                {reportName === 'violation' && <><Col md='4'>
                    <FormGroup>
                        <Label>Loại vi phạm</Label>
                        <Field name="violationType" component={SingleSelect} data={types !== null ? types : []}/>
                    </FormGroup>
                </Col></>}
                <Col md='4'>
                    <FormGroup>
                        <Label>Kỹ sư</Label>
                        <Field name="user"
                                component={SingleSelect}
                                onChange={(e) => {loadProjectByUser(e)}}
                                data={props.engineers !== null ? props.engineers : []}/>
                    </FormGroup>
                </Col>
                <Col md='4'>
                    <FormGroup>
                        <Label>Dự án</Label>
                        <Field name="project"
                                component={Example}
                               onChange={(e) => {if(e.length!==null)loadTowerByProject(e)}}
                                data={props.projectsByUser !== null ? props.projectsByUser : []}/>
                    </FormGroup>
                </Col>
                <Col md='4'>
                    <FormGroup>
                        <Label>Nhà thầu</Label>
                        <Field name="contractor"
                              component={Example}
                                data={props.allContractor !== null ? props.allContractor : []}/>
                    </FormGroup>
                </Col>
                <Col md='4'>
                    <FormGroup>
                        <Label>Tổ đội</Label>
                        <Field name="team"
                                component={Example}
                                data={props.allTeam !== null ? props.allTeam : []}/>
                    </FormGroup> 
                </Col>
                <Col md='4'>
                    <FormGroup>
                        <Label>Tháp</Label>
                        <Field name="tower"
                                component={Example}
                                onChange={(e) => {if(e.length!==null)loadLevelByTower(e)}}
                                data={props.towersByProject !== null ? props.towersByProject : []}/>
                                
                    </FormGroup>
                </Col>
                <Col md='4'>
                    <FormGroup>
                        <Label>Tầng</Label>
                        <Field name="level"
                                component={SingleSelect}
                                onChange={(e) => {loadZoneByLevel(e)}}
                                data={props.levelsByProjectTower !== null ? props.levelsByProjectTower : []}/>
                    </FormGroup>
                </Col>
                <Col md='4'>
                    <FormGroup>
                        <Label>Zone</Label>
                        <Field name="zone"
                                component={SingleSelect}
                                data={props.zonesByProjectLevel !== null ? props.zonesByProjectLevel : []}/>
                    </FormGroup>
                </Col>
            </Row>
            </form>
        </Container>
      </div></>
    );
  }

const formatInfoForSelect = (arr, value, label) => {
    if(arr && arr.length) {arr.map(item => {
        item.value = item[value];
        item.label = item[label];
    })}
    return arr;
}

const selector = formValueSelector('filterReportForm');
const mapStateToProps = state => {
    let filterDefault;
    if(common.getReportFilter() === null){
        filterDefault = {
            fromDate: moment().startOf('month').format('DD-MM-YYYY'),
            toDate: moment().endOf('month').format('DD-MM-YYYY')
        }
    }else{
        const obj = common.getReportFilter()
        filterDefault = {
            fromDate: moment(obj.fromDate).format('DD-MM-YYYY'),
            toDate: moment(obj.toDate).format('DD-MM-YYYY')
        }
    }
    return {
        initialValues: filterDefault,
        formValues: selector(state, 'project', 'contractor', 'team', 'tower', 'level', 'zone', 'fromDate', 'toDate', 'user', 'violationType'),
        engineers: formatInfoForSelect(state.categoryReducer.engineers,'rowID', 'userName'),
        categories: state.categoryReducer.categories,
        projectsByUser: formatInfoForSelect(state.categoryReducer.projectsByUser,'id', 'projectName'),
        allContractor: formatInfoForSelect(state.categoryReducer.allContractor,'id', 'contractorName'),
        allTeam: formatInfoForSelect(state.categoryReducer.allTeam,'id', 'teamName'),
        towersByProject: formatInfoForSelect(state.categoryReducer.towersByProject,'id', 'name'),
        levelsByProjectTower: formatInfoForSelect(state.categoryReducer.levelsByProjectTower,'id', 'name'),
        zonesByProjectLevel: formatInfoForSelect(state.categoryReducer.zonesByProjectLevel,'id', 'name'),
        hazarousFilter: state.categoryReducer.hazarousFilter,
        violationCategoryType: formatInfoForSelect(state.categoryReducer.violationCategoryType,'generalCategoryCode', 'generalCategoryName'),
    }
}


export default compose(
    connect(mapStateToProps, null),
    reduxForm({
        form: 'filterReportForm',
        enableReinitialize : true, // this is needed to refresh initial-values!!,
    }),
    commonWrapped({showLoadingWholePage: true}),
    withPermission()
)(ReportListing);
