import React from 'react';
import {compose} from 'redux';
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from 'redux-form';
import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import { userAction } from '../actions/userAction';
import { loadingUtility } from '../utility/loading';
import renderInput from '../controls/renderInput';
import commonWrapped from '../hocs/commonWrapped';
import { NavLink } from 'react-router-dom';
import {globalConstants} from '../constants/global';
import {history} from '../utility/history';
import {common} from '../utility/common';
import microsoftIcon from '../images/Microsoft-512.png';
class LoginPage extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount(){
        if(common.getToken() !== null) history.push('/');
    }

    submitAction = (values) =>{
        const self = this;
        return this.props.onSubmit(values, this.props.dispatch).then(res=>{
            if (res.access_token) {
                const userInfo = {...res};
                if(userInfo.provider=== undefined){
                    userInfo.provider = ""; 
                }
                localStorage.setItem(globalConstants.USER, JSON.stringify(userInfo));
                this.props.history.push('/');
            }
        },err=>{
            throw new SubmissionError({
                _error: "Vui lòng kiểm tra lại Tên đăng nhập và Mật khẩu!"
            });
        });
    }

    render(){
        const { handleSubmit, submitting, error } = this.props;
        const rs = _.get(window.ePayment,'resource.loginForm');
        //const useMicrosoftAccount = common.useMicrosoftAccount();
        return(
            <form onSubmit={handleSubmit(this.submitAction)}>
                <Row>
                    <Col xs={12} sm={12}><h2>{_.get(rs,'title')}</h2></Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <Field
                            placeholder={_.get(rs,'userName')}
                            name="username"
                            component={renderInput}
                            type="text"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <Field
                            name="password"
                            component={renderInput}
                            placeholder={_.get(rs,'password')}
                            type="password"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} className="text-center login-action">
                        {error && <div className="text-danger"><strong>{error}</strong><br/><br/></div> }
                        <button type="submit" className='btn btn-outline-default btn-sm btn-submit' disabled={submitting}>{_.get(rs,'submitBtn')}</button>
                        <a className="btn btn-outline-default microsoft-sign-in" href="javascript:;" onClick={(e)=>{
                                e.preventDefault();
                                window.externalLogin.loginMicrosoft();
                            }}>
                            <img src={microsoftIcon} alt="Logo" width="30px" style={{marginRight:'5px'}}/>
                            Microsoft Account
                        </a>
                        {/* <button type="button" disabled={pristine || submitting} onClick={reset}>{_.get(rs,'resetBtn')}</button>
                        <NavLink className="reset_pass" to={'/change-password'} activeClassName="active">
                          Thay đổi mật khẩu
                        </NavLink>*/}
                    </Col>
                </Row>
            </form>
        )
    }
}
const loadingSelector = loadingUtility(['USER_LOGIN']);
const mapStateToProps = (state) => ({ isFetching: loadingSelector(state) });
// link up desired behavior onSubmit
// this is a sample alert with the values
const mapDispatchToProps = dispatch => ({
    onSubmit: values => new Promise((resolve, reject)=>{
        dispatch(userAction.login(values.username, values.password, resolve, reject))
    })
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'userSignForm' // a unique identifier for this form
    }),
    commonWrapped({showLoadingWholePage:true})
)(LoginPage);
