import {globalConstants} from '../constants/global';
import moment from 'moment';
export const common = {
    getToken,
    getFullName,
    getRefreshToken,
    isTokenExpired,
    clearToken,
    formatDate,
    getReportFilter,
    getUserInfo,
    useMicrosoftAccount,
    getProvider
};
const expandTime = 0;
function getToken(){
    let user = JSON.parse(localStorage.getItem(globalConstants.USER));
    return user === null ? null :  user.access_token;
}
function getFullName(){
    let user = JSON.parse(localStorage.getItem(globalConstants.USER));
    return user === null ? null :  user.fullName;
}
function getRefreshToken(){
    let user = JSON.parse(localStorage.getItem(globalConstants.USER));
    return user.refresh_token;
}
function clearToken(){
    localStorage.removeItem(globalConstants.USER);
}
function isTokenExpired(){//true: token is expired
    let userInfo = JSON.parse(localStorage.getItem(globalConstants.USER));
    if(userInfo==null) return true;

    const expired = moment(userInfo[".expires"]).add(expandTime, 'minutes').toDate();
    let currDate = new Date();
    currDate = moment(currDate).toDate();
    return expired < currDate;
}
function formatDate(date){
    return moment(date).format('DD-MM-YYYY');
}

function getReportFilter(){
    let filter = JSON.parse(localStorage.getItem(globalConstants.FORMREPORT));
    return filter === null ? null :  filter;
}

function getUserInfo(){
    let userInfo = JSON.parse(localStorage.getItem(globalConstants.USERINFO));
    return userInfo === null ? null :  userInfo;
}
function useMicrosoftAccount(){
    let mic = JSON.parse(localStorage.getItem("microsoft-account"));
    return mic === null ? false : true;
}
function getProvider(){
    let user = JSON.parse(localStorage.getItem(globalConstants.USER));
    return user === null ? null :  user.provider;
}