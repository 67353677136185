import { actionTypes } from '../constants/actionTypes';
import { userService } from  '../services/userService';
import { history } from '../utility/history';
import { commonActions } from './commonAction';
import {toastr} from 'react-redux-toastr';
import {common} from '../utility/common';

export const userAction = {
    login,
    logout,
    changePassword,
    getActiveUsers,
    register,
    getAllUsers,
    updateUser,
    importUsers,
    resetUserPassword
};

function login(username, password, resolve, reject) {
    return dispatch => {
        dispatch(commonActions.request(actionTypes.USER_LOGIN_REQUEST, { username }));
        userService.login(username, password)
            .then(
                user => {
                    dispatch(commonActions.success(actionTypes.USER_LOGIN_SUCCESS, user.data));
                    // history.push('/');
                    resolve(user.data);
                },
                error => {
                    dispatch(commonActions.failure(actionTypes.USER_LOGIN_FAILURE, error));
                    reject(error);
                }
            );
    };
}

function logout() {
    userService.logout();
    history.push('/');
    localStorage.setItem('FORMREPORT', JSON.stringify(null));
    window.location.reload();
    return { type: actionTypes.USER_LOGOUT };
}

function changePassword(oldPass, newPass, confirmPass, resolve, reject){
    return dispatch => {
        dispatch(commonActions.request(actionTypes.USER_CHANGEPASS_REQUEST));
        userService.changePassword(oldPass, newPass, confirmPass)
            .then(
                result => {
                    dispatch(commonActions.success(actionTypes.USER_CHANGEPASS_SUCCESS, result));
                    resolve(result)
                },
                error => {
                    dispatch(commonActions.failure(actionTypes.USER_CHANGEPASS_FAILURE, error));
                    reject(error)
                }
            ).catch(error=>{
                dispatch(commonActions.failure(actionTypes.USER_CHANGEPASS_FAILURE, error));
                reject(error);
            });
    };
}

function getActiveUsers(resolve, reject) {
    return dispatch => {
        userService.getActiveUsers()
            .then(
                user => {
                    dispatch(commonActions.success(actionTypes.LIST_ACTIVEUSERS_SUCCESS, user));
                    resolve(resolve);
                },
                error => {
                    dispatch(commonActions.failure(actionTypes.LIST_ACTIVEUSERS_FAILURE, error));
                    reject(error);
                }
            );
    };
}

function registerSuccess(action, type_action, catalog_name, request, response) {
    return {
        type: action,
        data:{
            catalog_name, type_action, request, response,
        }
    }
}

function register(props, data){
  return dispatch => {
      let type_action = props.type_action;
      dispatch(commonActions.request(actionTypes.USER_REGISTER_REQUEST));
      userService.register(data)
          .then(
              user => {
                  dispatch(registerSuccess(actionTypes.USER_REGISTER_SUCCESS, type_action, props.catalogName, data, user));
                  dispatch(commonActions.closeDialog());
                  // dispatch(commonActions.success(actionTypes.USER_REGISTER_SUCCESS, user));
              },
              error => {
                  if(error.response.data.content.itemExists.length > 0) toastr.error('Error', 'Nhân viên này đã tồn tại!');
                  dispatch(commonActions.failure(actionTypes.USER_REGISTER_FAILURE, error));
              }
          );
  };
}

function updateUser(props, data){
  return dispatch => {
      let type_action = props.type_action;
      dispatch(commonActions.request(actionTypes.USER_REGISTER_REQUEST));
      userService.updateUser(data)
          .then(
              user => {
                  dispatch(registerSuccess(actionTypes.USER_REGISTER_SUCCESS, type_action, props.catalogName, data, user));
                  dispatch(commonActions.closeDialog());
                  // dispatch(commonActions.success(actionTypes.USER_REGISTER_SUCCESS, user));
              },
              error => {
                  dispatch(commonActions.failure(actionTypes.USER_REGISTER_FAILURE, error));
              }
          );
  };
}

function resetUserPassword(props,data){
    return dispatch => {
        let type_action = props.type_action;
       dispatch(commonActions.request(actionTypes.USER_RESET_PASSWORD_REQUEST));
        userService.resetUserPassword(data)
            .then(
                res => {
                    if(res === 200){
                        toastr.success('Thành Công', 'Đổi mật khẩu thành công!');
                        dispatch(commonActions.closeDialog());  
                    }else{
                        toastr.error('Thất Bại', 'Đổi mật khẩu thất bại!');
                        dispatch(commonActions.closeDialog());  
                    }  
                }
            );
    }; 
}

function importUsers(props,data){
    return dispatch => {
        let type_action = props.type_action;
        dispatch(commonActions.request(actionTypes.USER_IMPORT_REQUEST));
        userService.importUsers(data)
            .then(
                user => {
                    //getAllUsers();
                    dispatch(importUsersReducer(actionTypes.USER_IMPORT_SUCCESS, type_action, props.catalogName, data, user));
                    dispatch(commonActions.closeDialog());
                },
                error => {
                    dispatch(importUsersReducer(actionTypes.USER_IMPORT_FAILURE, type_action, props.catalogName, data, error));
                    dispatch(commonActions.closeDialog());
                }
            );
    }; 
}
function importUsersReducer(action, type_action, catalog_name, request, response) {
    return {
        type: action,
        data:{
            catalog_name, type_action, request, response,
        }
    }
}
function getKindUserList(action, obj) {
    return {
        type: action,
        data: obj
    }
}

function getAllUsers(resolve, reject) {
    return dispatch => {
        userService.getAllUsers()
            .then(
                obj => {
                    if(resolve){
                      dispatch(getKindUserList(actionTypes.LIST_USERS_SUCCESS, obj));
                      resolve(obj);
                    }else{
                        dispatch(commonActions.success(actionTypes.LIST_USERS_SUCCESS, obj));
                    }
                },
                error => {
                    dispatch(commonActions.failure(actionTypes.LIST_USERS_FAILURE, error));
                    reject&&reject(error);
                }
            );
    };
}
