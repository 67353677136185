import config from 'config';
import axios from 'axios';
import ApiEndpoints from '../constants/ApiEndpoints';


const imageService = {
    add,
    update,
    get,
    gets,
    remove
}

function add(data){
    let url = `${config.apiUrl}/${ApiEndpoints.imageCate}/PUT`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function update(data){
    let url = `${config.apiUrl}/${ApiEndpoints.imageCate}/PUT`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function get(id){
  return new Promise((resolve, reject) => {
      resolve();
  });
}

function gets(){
//   let url = `./resources/imageCate.json`;
  let url = `${config.apiUrl}/${ApiEndpoints.imageCate}/items`;
  return new Promise((resolve, reject) => {
      axios.get(url).then(res=>{
          resolve(res.data.content.items);
      },function(e){
          reject(e);
      });
  });
}

function remove(item){
  let url = `${config.apiUrl}/${ApiEndpoints.imageCate}/DELETE/${item.id}`; 
  return new Promise((resolve, reject) => {
      axios.delete(url).then(res=>{
          resolve(res.data.content.items);
      },function(e){
          reject(e);
      });
  });
}

export default imageService;
