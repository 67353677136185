import React from 'react';
import _ from 'lodash';
import { Table, Button } from 'reactstrap';

const renderNoData = (categoryList, colSpan) => {
  return(
    <>{categoryList !== null && categoryList !== undefined && categoryList.length === 0 && (
        <tr>
            <td colSpan={colSpan} className="text-center">Không có dữ liệu</td>
        </tr>
    )}</>
  )
}

const renderProject = (rs, categoryList, viewItemFn, editItemFn, deleteItemFn, checkPermissionFeature, featureName) => {
  return(
    <Table bordered responsive striped hover>
        <thead>
        <tr>
            {/* {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <th className="action"></th>} */}
            <th className="action"></th>
            <th className="w-px-150">{_.get(rs,'projectCategory.code')}</th>
            <th>{_.get(rs,'projectCategory.name')}</th>
            <th className="w-px-100">{_.get(rs,'projectCategory.status')}</th>
            <th className="w-px-100">Admin dự án</th>
            <th className="w-px-100">{_.get(rs,'projectCategory.siteEngineer')}</th>
            <th className="w-px-100">{_.get(rs,'projectCategory.siteManager')}</th>
            <th className="w-px-100">Nhóm nhận thông báo</th>
        </tr>
        </thead>
        <tbody>
            {renderNoData(categoryList, 8)}
            {categoryList !== null && categoryList !== undefined && categoryList.length > 0 &&
            (categoryList.map((item, i) => {
                return (
                    <tr key={`project-${i}`}>
                <td className="text-center">
                    {(checkPermissionFeature(featureName, 'edit') || (item.canUpdateDetail !== undefined && item.canUpdateDetail === true)) && <button type="button" className="i-btn cta cta-edit" onClick={(e)=>{editItemFn&&editItemFn(item);}}>
                    <i className='fa fa-pencil' aria-hidden="true"></i></button>}
                    {checkPermissionFeature(featureName, 'delete') && <button type="button" className="i-btn cta cta-delete" onClick={(e)=>{deleteItemFn&&deleteItemFn(item);}}>
                    <i className="fa fa-trash-o" aria-hidden="true"></i></button>}
                </td>
                <td><Button color="link" onClick={(e)=>{viewItemFn&&viewItemFn(item)}} style={{padding: '0'}}>{item.projectCode}</Button></td>
                <td>{item.projectName}</td>
                <td>{item.statusName}</td>
                <td>{item.users !== null && item.users !== undefined && item.users.length > 0 && item.users.map((item, i) => {
                    return (
                        <span key={`user-${i}`}>{item.userName}<br /></span>
                    );
                })}</td>
                <td>{item.engineers !== null && item.engineers !== undefined && item.engineers.length > 0 && item.engineers.map((item, i) => {
                    return (
                        <span key={`engineer-${i}`}>{item.userName}<br /></span>
                    );
                })}</td>
                <td>{item.siteCommanders !== null && item.siteCommanders !== undefined && item.siteCommanders.length > 0 && item.siteCommanders.map((item, i) => {
                    return (
                        <span key={`siteCommander-${i}`}>{item.userName}<br /></span>
                    );
                })}</td>
                <td>{item.groupsNotifications !== null && item.groupsNotifications !== undefined && item.groupsNotifications.length > 0 && item.groupsNotifications.map((item, i) => {
                    return (
                        <span key={`groupsNotifications-${i}`}>{item.userName}<br /></span>
                    );
                })}</td>
            </tr>
                );
            }))}

        </tbody>
    </Table>
  );
}
const renderAccident = (rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName) => {
  return(
    <Table bordered responsive striped hover>
        <thead>
            <tr>
                {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <th className="action"></th>}
                <th>{_.get(rs,'accidentCategory.name')}</th>
                <th className="w-px-200">{_.get(rs,'categories.note')}</th>
                <th className="w-px-100">{_.get(rs,'categories.sortorder')}</th>
                <th className="w-px-100">{_.get(rs,'categories.active')}</th>
            </tr>
        </thead>
        <tbody>
            {renderNoData(categoryList, 5)}
            {categoryList !== null && categoryList !== undefined && categoryList.length > 0 &&
            (categoryList.map((item, i) => {
                return (
                    <tr key={i}>
                        {checkPermissionFeature(featureName, 'edit') && <td className="text-center">
                            <button type="button" className="i-btn cta cta-edit" onClick={(e)=>{editItemFn&&editItemFn(item);}}>
                            <i className='fa fa-pencil' aria-hidden="true"></i></button>
                        </td>}
                        <td>{item.accidentName}</td>
                        <td>{item.note}</td>
                        <td>{item.sortOrder}</td>
                        <td className="text-center">
                            <div className="checkbox">
                                <label>
                                    <input type="checkbox" checked={item.isActive} disabled/>
                                    <span className="icon-checked">
                                        <svg className="icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title></title><defs><path id="am" d="M8.926 18.652L4 13.725l1.767-1.768 3.074 3.074L17.959 5l1.849 1.682z"></path></defs><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="bm" fill="#fff"><use xlinkHref="#am"></use></mask><path d="M0 0h24v24H0z" mask="url(#bm)" fill="#212121"></path></g></svg>
                                    </span>
                                </label>
                            </div>
                        </td>
                    </tr>
                );
            }))}

        </tbody>
    </Table>
  );
}
const renderContractor = (rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName) => {
    console.log('render table');
    console.log(categoryList);
  return(
    <Table bordered responsive striped hover>
        <thead>
            <tr>
                {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <th className="action"></th>}
                <th className="w-px-150">{_.get(rs,'contractorCategory.code')}</th>
                <th>{_.get(rs,'contractorCategory.name')}</th>
                <th className="w-px-200">{_.get(rs,'categories.note')}</th>
                <th className="w-px-100">{_.get(rs,'categories.active')}</th>
            </tr>
        </thead>
        <tbody>
        {renderNoData(categoryList, 5)}
            {categoryList !== null && categoryList !== undefined && categoryList.length > 0 &&
            (categoryList.map((item, i) => {
                return (
                    <tr key={`contractor-${i}`}>
                        {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <td className="text-center">
                            {checkPermissionFeature(featureName, 'edit') && <button type="button" className="i-btn cta cta-edit" onClick={(e)=>{editItemFn&&editItemFn(item);}}>
                            <i className='fa fa-pencil' aria-hidden="true"></i></button>}
                            {checkPermissionFeature(featureName, 'delete') && <button type="button" className="i-btn cta cta-delete" onClick={(e)=>{deleteItemFn&&deleteItemFn(item);}}>
                            <i className="fa fa-trash-o" aria-hidden="true"></i></button>}
                        </td>}
                        <td>{item.contractorCode}</td>
                        <td>{item.contractorName}</td>
                        <td>{item.note}</td>
                        <td className="text-center">
                            <div className="checkbox">
                                <label>
                                    <input type="checkbox" checked={item.isActive} disabled/>
                                    <span className="icon-checked">
                                        <svg className="icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title></title><defs><path id="am" d="M8.926 18.652L4 13.725l1.767-1.768 3.074 3.074L17.959 5l1.849 1.682z"></path></defs><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="bm" fill="#fff"><use xlinkHref="#am"></use></mask><path d="M0 0h24v24H0z" mask="url(#bm)" fill="#212121"></path></g></svg>
                                    </span>
                                </label>
                            </div>
                        </td>
                    </tr>
                );
            }))}

        </tbody>
    </Table>
  );
}
const renderImage = (rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName) => {
  return(
    <Table bordered responsive striped hover>
        <thead>
        <tr>
            {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <th className="action"></th>}
            <th className="w-px-150">{_.get(rs,'imageCategory.code')}</th>
            <th>{_.get(rs,'imageCategory.name')}</th>
            <th className="w-px-100">{_.get(rs,'categories.sortorder')}</th>
            <th className="w-px-100">{_.get(rs,'categories.active')}</th>
        </tr>
        </thead>
        <tbody>
        {renderNoData(categoryList, 5)}
            {categoryList !== null && categoryList !== undefined && categoryList.length > 0 &&
            (categoryList.map((item, i) => {
                return (
                    <tr key={i}>
                {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <td className="text-center">
                    {checkPermissionFeature(featureName, 'edit') && <button type="button" className="i-btn cta cta-edit" onClick={(e)=>{editItemFn&&editItemFn(item);}}>
                    <i className='fa fa-pencil' aria-hidden="true"></i></button>}
                    {checkPermissionFeature(featureName, 'delete') && <button type="button" className="i-btn cta cta-delete" onClick={(e)=>{deleteItemFn&&deleteItemFn(item);}}>
                    <i className="fa fa-trash-o" aria-hidden="true"></i></button>}
                </td>}
                <td>{item.categoryCode}</td>
                <td>{item.categoryName}</td>
                <td>{item.sortOrder}</td>
                <td className="text-center">
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" checked={item.isActive} disabled/>
                            <span className="icon-checked">
                                <svg className="icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title></title><defs><path id="am" d="M8.926 18.652L4 13.725l1.767-1.768 3.074 3.074L17.959 5l1.849 1.682z"></path></defs><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="bm" fill="#fff"><use xlinkHref="#am"></use></mask><path d="M0 0h24v24H0z" mask="url(#bm)" fill="#212121"></path></g></svg>
                            </span>
                        </label>
                    </div>
                </td>
            </tr>
                );
            }))}

        </tbody>
    </Table>
  );
}
const renderTraining = (rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName) => {
  return(
    <Table bordered responsive striped hover>
        <thead>
        <tr>
            {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <th className="action"></th>}
            <th className="w-px-150">{_.get(rs,'trainingCategory.code')}</th>
            <th>{_.get(rs,'trainingCategory.name')}</th>
            <th className="w-px-100">{_.get(rs,'categories.sortorder')}</th>
            <th className="w-px-100">{_.get(rs,'categories.active')}</th>
        </tr>
        </thead>
        <tbody>
        {renderNoData(categoryList, 5)}
            {categoryList !== null && categoryList !== undefined && categoryList.length > 0 &&
            (categoryList.map((item, i) => {
                return (
                    <tr key={i}>
                {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <td className="text-center">
                    {checkPermissionFeature(featureName, 'edit') && <button type="button" className="i-btn cta cta-edit" onClick={(e)=>{editItemFn&&editItemFn(item);}}>
                    <i className='fa fa-pencil' aria-hidden="true"></i></button>}
                    {checkPermissionFeature(featureName, 'delete') && <button type="button" className="i-btn cta cta-delete" onClick={(e)=>{deleteItemFn&&deleteItemFn(item);}}>
                    <i className="fa fa-trash-o" aria-hidden="true"></i></button>}
                </td>}
                <td>{item.trainingCode}</td>
                <td>{item.trainingName}</td>
                <td>{item.sortOrder}</td>
                <td className="text-center">
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" checked={item.isActive} disabled/>
                            <span className="icon-checked">
                                <svg className="icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title></title><defs><path id="am" d="M8.926 18.652L4 13.725l1.767-1.768 3.074 3.074L17.959 5l1.849 1.682z"></path></defs><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="bm" fill="#fff"><use xlinkHref="#am"></use></mask><path d="M0 0h24v24H0z" mask="url(#bm)" fill="#212121"></path></g></svg>
                            </span>
                        </label>
                    </div>
                </td>
            </tr>
                );
            }))}

        </tbody>
    </Table>
  );
}
const renderViolation = (rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName) => {
  return(
    <Table bordered responsive striped hover>
        <thead>
            <tr>
                {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <th className="action"></th>}
                <th className="w-px-150">{_.get(rs,'violationCategory.code')}</th>
                <th>{_.get(rs,'violationCategory.name')}</th>
                <th className="w-px-200">Biện pháp kiểm soát</th>
                <th className="w-px-100">Loại vi phạm</th>
                <th className="w-px-100">{_.get(rs,'categories.sortorder')}</th>
                <th className="w-px-100">{_.get(rs,'categories.active')}</th>
            </tr>
        </thead>
        <tbody>
            {renderNoData(categoryList, 7)}
            {categoryList !== null && categoryList !== undefined && categoryList.length > 0 &&
            (categoryList.map((item, i) => {
                return (
                    <tr key={i} className={item.parentID === null ? 'table-primary' : ''}>
                {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <td className="text-center">
                    {checkPermissionFeature(featureName, 'edit') && <button type="button" className="i-btn cta cta-edit" onClick={(e)=>{editItemFn&&editItemFn(item);}}>
                    <i className='fa fa-pencil' aria-hidden="true"></i></button>}
                    {checkPermissionFeature(featureName, 'delete') && <button type="button" className="i-btn cta cta-delete" onClick={(e)=>{deleteItemFn&&deleteItemFn(item);}}>
                    <i className="fa fa-trash-o" aria-hidden="true"></i></button>}
                </td>}
                <td>{item.violationCode}</td>
                <td>{item.violationName}</td>
                <td>{item.riskControl}</td>
                <td>{item.typeName}</td>
                <td>{item.sortOrder}</td>
                <td className="text-center">
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" checked={item.isActive} disabled/>
                            <span className="icon-checked">
                                <svg className="icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title></title><defs><path id="am" d="M8.926 18.652L4 13.725l1.767-1.768 3.074 3.074L17.959 5l1.849 1.682z"></path></defs><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="bm" fill="#fff"><use xlinkHref="#am"></use></mask><path d="M0 0h24v24H0z" mask="url(#bm)" fill="#212121"></path></g></svg>
                            </span>
                        </label>
                    </div>
                </td>
            </tr>
                );
            }))}

        </tbody>
    </Table>
  );
}
const renderPunishment = (rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName) => {
  return(
    <Table bordered responsive striped hover>
        <thead>
            <tr>
                {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <th className="action"></th>}
                <th className="w-px-150">{_.get(rs,'punishmentCategory.code')}</th>
                <th>{_.get(rs,'punishmentCategory.name')}</th>
                <th>{_.get(rs,'punishmentCategory.penaltyfee')}</th>
                <th className="w-px-200">{_.get(rs,'categories.note')}</th>
                <th className="w-px-100">{_.get(rs,'categories.sortorder')}</th>
                <th className="w-px-100">{_.get(rs,'categories.active')}</th>
            </tr>
        </thead>
        <tbody>
            {renderNoData(categoryList, 7)}
            {categoryList !== null && categoryList !== undefined && categoryList.length > 0 &&
            (categoryList.map((item, i) => {
                return (
                    <tr key={i} className={item.parentID === null ? 'table-primary' : ''}>
                        {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <td className="text-center">
                            {checkPermissionFeature(featureName, 'edit') && <button type="button" className="i-btn cta cta-edit" onClick={(e)=>{editItemFn&&editItemFn(item);}}>
                            <i className='fa fa-pencil' aria-hidden="true"></i></button>}
                            {checkPermissionFeature(featureName, 'delete') && <button type="button" className="i-btn cta cta-delete" onClick={(e)=>{deleteItemFn&&deleteItemFn(item);}}>
                            <i className="fa fa-trash-o" aria-hidden="true"></i></button>}
                        </td>}
                        <td>{item.punishmentCode}</td>
                        <td>{item.punishmentName}</td>
                        <td className="text-right">{new Intl.NumberFormat('vi-VN').format(item.penaltyFee)}</td>
                        <td>{item.note}</td>
                        <td>{item.sortOrder}</td>
                        <td className="text-center">
                            <div className="checkbox">
                                <label>
                                    <input type="checkbox" checked={item.isActive} disabled/>
                                    <span className="icon-checked">
                                        <svg className="icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title></title><defs><path id="am" d="M8.926 18.652L4 13.725l1.767-1.768 3.074 3.074L17.959 5l1.849 1.682z"></path></defs><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="bm" fill="#fff"><use xlinkHref="#am"></use></mask><path d="M0 0h24v24H0z" mask="url(#bm)" fill="#212121"></path></g></svg>
                                    </span>
                                </label>
                            </div>
                        </td>
                    </tr>
                );
            }))}

        </tbody>
    </Table>
  );
}
const renderHazardous = (rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName) => {
  return(
    <Table bordered responsive striped hover>
        <thead>
        <tr>
            {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <th className="action"></th>}
            {/*<th className="w-px-150">{_.get(rs,'hazardCategory.code')}</th>*/}
            <th>{_.get(rs,'hazardCategory.name')}</th>
            <th>Biện pháp kiểm soát</th>
            <th className="w-px-100">{_.get(rs,'categories.sortorder')}</th>
            <th className="w-px-100">{_.get(rs,'categories.active')}</th>
        </tr>
        </thead>
        <tbody>
        {renderNoData(categoryList, 5)}
            {categoryList !== null && categoryList !== undefined && categoryList.length > 0 &&
            (categoryList.map((item, i) => {
                return (
                    <tr key={i} className={item.parentID === null ? 'table-primary' : ''}>
                {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <td className="text-center">
                    {checkPermissionFeature(featureName, 'edit') && <button type="button" className="i-btn cta cta-edit" onClick={(e)=>{editItemFn&&editItemFn(item);}}>
                    <i className='fa fa-pencil' aria-hidden="true"></i></button>}
                    {checkPermissionFeature(featureName, 'delete') && <button type="button" className="i-btn cta cta-delete" onClick={(e)=>{deleteItemFn&&deleteItemFn(item);}}>
                    <i className="fa fa-trash-o" aria-hidden="true"></i></button>}
                </td>}
                {/*<td>{item.hazardCode}</td>*/}
                <td>{item.hazardName}</td>
                <td>{item.riskControl}</td>
                <td>{item.sortOrder}</td>
                <td className="text-center">
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" checked={item.isActive} disabled/>
                            <span className="icon-checked">
                                <svg className="icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title></title><defs><path id="am" d="M8.926 18.652L4 13.725l1.767-1.768 3.074 3.074L17.959 5l1.849 1.682z"></path></defs><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="bm" fill="#fff"><use xlinkHref="#am"></use></mask><path d="M0 0h24v24H0z" mask="url(#bm)" fill="#212121"></path></g></svg>
                            </span>
                        </label>
                    </div>
                </td>
            </tr>
                );
            }))}

        </tbody>
    </Table>
  );
}
const renderTeam = (rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName) => {
  return(
    <Table bordered responsive striped hover>
        <thead>
        <tr>
            {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <th className="action"></th>}
            {/*<th className="w-px-150">{_.get(rs,'teamCategory.code')}</th>*/}
            <th>{_.get(rs,'teamCategory.name')}</th>
            {/*<th className="w-px-100">{_.get(rs,'categories.note')}</th>*/}
            <th className="w-px-100">{_.get(rs,'categories.sortorder')}</th>
            <th className="w-px-100">{_.get(rs,'categories.active')}</th>
        </tr>
        </thead>
        <tbody>
            {renderNoData(categoryList, 6)}
            {categoryList !== null && categoryList !== undefined && categoryList.length > 0 &&
            (categoryList.map((item, i) => {
                return (
                    <tr key={i}>
                {(checkPermissionFeature(featureName, 'edit') || checkPermissionFeature(featureName, 'delete')) && <td className="text-center">
                    {checkPermissionFeature(featureName, 'edit') && <button type="button" className="i-btn cta cta-edit" onClick={(e)=>{editItemFn&&editItemFn(item);}}>
                    <i className='fa fa-pencil' aria-hidden="true"></i></button>}
                    {checkPermissionFeature(featureName, 'delete') && <button type="button" className="i-btn cta cta-delete" onClick={(e)=>{deleteItemFn&&deleteItemFn(item);}}>
                    <i className="fa fa-trash-o" aria-hidden="true"></i></button>}
                </td>}
                {/*<td>{item.teamCode}</td>*/}
                <td>{item.teamName}</td>
                {/*<td>{item.note}</td>*/}
                <td>{item.sortOrder}</td>
                <td className="text-center">
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" checked={item.isActive} disabled/>
                            <span className="icon-checked">
                                <svg className="icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title></title><defs><path id="am" d="M8.926 18.652L4 13.725l1.767-1.768 3.074 3.074L17.959 5l1.849 1.682z"></path></defs><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="bm" fill="#fff"><use xlinkHref="#am"></use></mask><path d="M0 0h24v24H0z" mask="url(#bm)" fill="#212121"></path></g></svg>
                            </span>
                        </label>
                    </div>
                </td>
            </tr>
                );
            }))}
        </tbody>
    </Table>
  );
}

const CategoryTable = props => {
    const {catalogName, categoryList, viewItemFn, editItemFn, deleteItemFn, checkPermissionFeature, featureName} = props;
    const rs = _.get(window.ePayment,'resource');
    return(
        <div>
          {catalogName === 'project' && renderProject(rs, categoryList, viewItemFn, editItemFn, deleteItemFn, checkPermissionFeature, featureName)}
          {catalogName === 'accident' && renderAccident(rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName)}
          {catalogName === 'contractor' && renderContractor(rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName)}
          {catalogName === 'image' && renderImage(rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName)}
          {catalogName === 'training' && renderTraining(rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName)}
          {catalogName === 'violation' && renderViolation(rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName)}
          {catalogName === 'punishment' && renderPunishment(rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName)}
          {catalogName === 'hazardous' && renderHazardous(rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName)}
          {catalogName === 'team' && renderTeam(rs, categoryList, editItemFn, deleteItemFn, checkPermissionFeature, featureName)}
        </div>
    )
}

export default CategoryTable;
