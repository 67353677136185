import config from 'config';
import axios from 'axios';
import ApiEndpoints from '../constants/ApiEndpoints';


const violationService = {
    add,
    update,
    get,
    gets,
    remove,
    getParentItems,
    getViolationCategoryType
}

function add(data){
    let url = `${config.apiUrl}/${ApiEndpoints.violationCate}/PUT`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function update(data){
    let url = `${config.apiUrl}/${ApiEndpoints.violationCate}/PUT`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function get(id){
    return new Promise((resolve, reject) => {
        resolve();
    });
}

function gets(){
    // let url = `./resources/violationCate.json`;
    let url = `${config.apiUrl}/${ApiEndpoints.violationCate}/items`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function getParentItems(){
    // let url = `./resources/violationCate.json`;
    let url = `${config.apiUrl}/${ApiEndpoints.violationCate}/ParentItems/0`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function remove(item){
    let url = `${config.apiUrl}/${ApiEndpoints.violationCate}/DELETE/${item.id}`; 
    return new Promise((resolve, reject) => {
        axios.delete(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function getViolationCategoryType(){
    let url = `${config.apiUrl}/api/GeneralCategory/GetViolationCategoryType`; 
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

export default violationService;
