import React from 'react';
import imgWaiting from '../images/waiting.gif';
import imgLoading from '../images/loading.gif';
import _ from 'lodash';
const commonWrapped = (options) => (Component) => {
    class commonHOC extends React.Component{
        constructor(props) {
            super(props);
        }
        
        render(){
            return (<div>
                <Component {...this.props} />
                {this.props.isFetching&&_.isUndefined(options) !== true 
                    && _.isUndefined(options.showLoadingWholePage) !== undefined 
                    && <div className='loading-whole-page'>
                        <img alt='loaidng' src={imgWaiting} />
                    </div>}
                {_.isUndefined(options) === true && this.props.isFetching && <img src={imgLoading} />}
            </div>)
        }
    }
    return commonHOC;
}
export default commonWrapped;
