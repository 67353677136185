import config from 'config';
import axios from 'axios';
import ApiEndpoints from '../constants/ApiEndpoints';


const hazardousService = {
    add,
    update,
    get,
    gets,
    remove,
    report,
    getParentItems
}

function add(data){
    let url = `${config.apiUrl}/${ApiEndpoints.hazardousCate}/PUT`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function update(data){
    let url = `${config.apiUrl}/${ApiEndpoints.hazardousCate}/PUT`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function get(id){
    return new Promise((resolve, reject) => {
        resolve();
    });
}

function gets(){
    // let url = `./resources/violationCate.json`;
    let url = `${config.apiUrl}/${ApiEndpoints.hazardousCate}/items`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function remove(item){
    let url = `${config.apiUrl}/${ApiEndpoints.hazardousCate}/DELETE/${item.id}`;
    return new Promise((resolve, reject) => {
        axios.delete(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function report(data){
    let url = `${config.apiUrl}/home/hazardreport`;
    let formData = setDataForFormData(data);
    return new Promise((resolve,reject)=>{
        axios.post(url, formData, {
            headers: { "Access-Control-Allow-Origin": "103.48.190.9:8010" }
        }).then(res=>{
            resolve(res.data);
        },e=>{
            reject(e);
        });
    });
}

// Returns if a value is a string
function isString (value) {
    return typeof value === 'string' || value instanceof String;
}

function setDataForFormData(item){
    let formData = new FormData();
    // if(item.dateOfBirth!==null){
    //     item.dateOfBirth = moment(item.dateOfBirth).format('YYYY-MM-DD');
    // }
    for (let key in item) {
        if((item[key] !== null && item[key].constructor === Array)){//handle roles
            if(item[key].length > 0 && isString(item[key][0]))//only accept item[key][0] == 'string'
            {
                for(let i in item[key]){
                    item[key]!==null&&formData.append(key, item[key][i]);
                }
            }
        }else{
            item[key]!==null&&formData.append(key, item[key]);
        }        
    }
    return formData;
}

function getParentItems(){
    // let url = `./resources/violationCate.json`;
    let url = `${config.apiUrl}/${ApiEndpoints.hazardousCate}/ParentItems/0`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

export default hazardousService;
