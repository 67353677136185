import React, {Component} from 'react';
import Logo from '../images/logo_green.png'

class WelcomePage extends Component {
    render() {
        return (
            <div className="main-box text-center welcome-page">
                <div>
                    <img src={Logo} alt="logo" />
                </div>
            </div>
        )
    }
}

export default WelcomePage;