import React from 'react';
import _ from 'lodash';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const ConfirmModal = ({data, isOpen, mdTitle, mdBody, toggleFn, okFn}) => {
    const rs = _.get(window.ePayment,'resource');
    return(
        <div>
            <Modal isOpen={isOpen}
                
                toggle={toggleFn} 
                wrapClassName='e-safety' 
                backdrop='static'>
                <ModalHeader toggle={toggleFn}>{mdTitle}</ModalHeader>
                <ModalBody>{mdBody}</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(e)=>{
                        !!toggleFn&&toggleFn();
                        !!okFn&&okFn(data);
                    }}>{_.get(rs,'btnNames.ok')}</Button>{' '}
                    <Button color="secondary" onClick={toggleFn}>{_.get(rs,'btnNames.cancel')}</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
export default ConfirmModal;