import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {reducer as toastrReducer} from 'react-redux-toastr';
import {userReducer} from '../reducers/userReducer';
import {categoryReducer} from '../reducers/categoryReducer';
import loadingReducer from '../reducers/loadingReducer';
import dialogReducer from '../reducers/dialogReducer';
import { reducer as reduxFormReducer } from 'redux-form';

const store = createStore(
  combineReducers({
    toastr: toastrReducer,
    // authReducer,
    userReducer,
    categoryReducer,
    loadingReducer,
    dialogReducer,
    form: reduxFormReducer
  }),
  applyMiddleware(
    thunk
  )
);

export default store;