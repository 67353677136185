const apiUrl= {
    hubConnection: 'http://localhost:8087',
    login: 'token',
    changePass: 'api/Account/ChangePassword',
    register: 'api/Account/Register',
    resetPassword: 'api/Account/ResetPassword',
    status: '/status',
    user: '/user',
    importUsers : 'api/Account/ImportUserTemplate',
    resetUserPassword : 'api/Account/ResetDefaultPassword?userName='
}
export default apiUrl;
