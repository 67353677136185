import { actionTypes } from '../constants/actionTypes';
import permissionService  from  '../services/permissionService';
import { commonActions } from './commonAction';
import {toastr} from 'react-redux-toastr';
export const permissionAction = {
    getGroups,
    getUsersInGroup,
    getFeatureForGroup,
    getFeatureForUser,
    setFeatureForGroup,
    setFeatureForUser,
    searchUser,
    addUser,
    removeUser,
    getUserInfo
};

function searchUser(txt) {
    return dispatch => {
        dispatch(commonActions.request(actionTypes.SEARCH_USER_REQUEST));
        permissionService.searchUser(txt)
            .then(
                obj => {
                    dispatch(commonActions.success(actionTypes.SEARCH_USER_SUCCESS, obj));
                },
                error => {
                    dispatch(commonActions.failure(actionTypes.SEARCH_USER_FAILURE, error));
                }
            );
    };
}
function addUserSuccess(action, group, user, fullName, response) {
    return {
        type: action,
        data:{
            group, user, fullName ,response,
        }
    }
}

function addUser(group, user, fullName) {
    const data = {
      securityGroupId: group,
      userId: user.id
    }
    return dispatch => {
        dispatch(commonActions.request(actionTypes.ADD_USER_REQUEST));
        permissionService.addUser(data)
            .then(
                obj => {
                    dispatch(addUserSuccess(actionTypes.ADD_USER_SUCCESS, group, user, fullName, obj));
                },
                error => {
                    handleErr(error.response.data.content.items.result);
                    dispatch(commonActions.failure(actionTypes.ADD_USER_FAILURE, error));
                }
            );
    };
}

function getGroups(resolve,reject) {
    return dispatch =>{
        permissionService.getGroups().then(items=>{
            dispatch({type:actionTypes.GET_GROUP_SUCCESS, data: items});
            resolve(items);
        },err=>{
            reject(err);
        })
    }
}

function getUsersInGroup(groupId,resolve,reject) {
    return dispatch =>{
        permissionService.getUsersInGroup(groupId).then(items=>{
            dispatch({type:actionTypes.GET_USERINGROUP_SUCCESS, data: items});
            resolve(items);
        },err=>{
            reject(err);
        })
    }
}

function setFeatureForGroup(data) {
    return dispatch => {
        dispatch(commonActions.request(actionTypes.SET_FEATUREGROUP_REQUEST));
        permissionService.setFeatureForGroup(data)
            .then(
                obj => {
                    dispatch(commonActions.success(actionTypes.SET_FEATUREGROUP_SUCCESS, obj));
                },
                error => {
                    dispatch(commonActions.failure(actionTypes.SET_FEATUREGROUP_FAILURE, error));
                }
            );
    };
}

function getFeatureForGroup(groupId,resolve,reject) {
    return dispatch =>{
        permissionService.getFeatureForGroup(groupId).then(items=>{
            dispatch({type:actionTypes.GET_FEATUREGROUP_SUCCESS, data: items});
            resolve(items);
        },err=>{
            reject(err);
        })
    }
}

function setFeatureForUser(data) {
    return dispatch => {
        dispatch(commonActions.request(actionTypes.SET_FEATUREUSER_REQUEST));
        permissionService.setFeatureForUser(data)
            .then(
                obj => {
                    dispatch(commonActions.success(actionTypes.SET_FEATUREUSER_SUCCESS, obj));
                },
                error => {
                    dispatch(commonActions.failure(actionTypes.SET_FEATUREUSER_FAILURE, error));
                }
            );
    };
}

function getFeatureForUser(user, resolve,reject) {
    return dispatch =>{
        permissionService.getFeatureForUser(user).then(items=>{
            dispatch({type:actionTypes.GET_FEATUREUSER_SUCCESS, data: items});
            resolve(items);
        },err=>{
            reject(err);
        })
    }
}

function removeUser(data) {
    return dispatch =>{
        permissionService.removeUser(data).then(items=>{
            dispatch({type:actionTypes.DELETE_USER_SUCCESS, data: data});
            // resolve(items);
        },err=>{
            // reject(err);
        })
    }
}

function getUserInfo() {
    return (dispatch,store) =>{
        const userInfo = store().userReducer.userInfo;
        if(userInfo === null){
            permissionService.getUserInfo().then(items=>{
                dispatch({type:actionTypes.USER_INFO_SUCCESS, data: items});
            },err=>{
                
            });
        }
        
    }
}


function handleErr(error){
  switch(error) {
      case "ItemNotFound":
          toastr.error('Error', 'Không thể tìm thấy mục này. Có vẻ như bạn không có đủ quyền hoặc mục này đã bị xóa.');
          break;
      case "NotAllowed":
          toastr.error('Error', 'Bạn không có đủ quyền.');
          break;
      case "RowVersionChanged":
          toastr.error('Error', 'Phiên bản hàng đã được thay đổi.');
          break;
      case "ItemExists":
          toastr.error('Error', 'Mục này tồn tại.');
          break;
      case "ImageFolderNotExist":
          toastr.error('Error', 'Hình ảnh thư mục không tồn tại.');
          break;
      case "ItemInUse":
          toastr.error('Error', 'Không thể xóa mục này. Nó đã được sử dụng.');
          break;
      case "ItemIsNull":
          toastr.error('Error', 'Mục này là null.');
          break;
      case "StatusWrong":
          toastr.error('Error', 'Trường "trạng thái" không được định cấu hình chính xác.');
          break;
      default:
          break;
  }
}
