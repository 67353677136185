import React, {Component} from 'react';
import _ from 'lodash';
import ReportListing from '../components/ReportListing';
import {history} from '../utility/history';


class ReportPage extends Component {
    constructor (props, context) {
        super(props,context);
        this.state={
            reportName: null,
            rs: _.get(window.ePayment,'resource'),
            actionType: null
        };
        this.getReportName = this.getReportName.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        const currentname = _.camelCase(this.props.match.params.name);
        let name = this.getReportName(currentname);
        let featureName = this.getFeatureName(currentname);
        if(this.state.reportName !== name){
            this.setState({'reportName': name, 'featureName': featureName});
        }
    }
    componentDidMount(){
        const reportArr = ['moiNguy','viPham'];
        const hasRoute = reportArr.includes(_.camelCase(this.props.match.params.name));
        if(!hasRoute){
            history.push('/');
        }
        let currentname = _.camelCase(this.props.match.params.name);
        let name = this.getReportName(currentname);
        let featureName = this.getFeatureName(currentname);
        this.setState({'reportName': name, 'featureName': featureName});
    }
    getReportName(currentname){
        let name;
        switch(currentname) {
            case _.camelCase('moi-nguy'):
                name = _.camelCase('hazardous')
                return name;
            case _.camelCase('vi-pham'):
                name = _.camelCase('violation')
                return name;
            default:
                name = _.camelCase('hazardous')
                return name;
        }
    }

    getFeatureName (currentname){
        let name;
        switch(currentname) {
            case _.camelCase('vi-pham'):
                name = 'Report09'
                return name;
            case _.camelCase('moi-nguy'):
                name = 'Report10'
                return name;
            default:
                name = 'Report09'
                return name;
        }
    }

    _handleFormCategory=(type_action)=>{
        this.setState({actionType: type_action});
    }

    _handleChangeTypeAction = () => {
      this.setState({actionType: null});
    }

    render() {
        const {reportName, featureName} = this.state;
        return (
          <div className="main-box">
              <h5 className="page-header">
                  {reportName==='hazardous' && <span>Báo cáo mối nguy và công việc hằng ngày</span>}
                  {reportName==='violation' && <span>Báo cáo vi phạm</span>}
              </h5>

              <ReportListing
                reportName={reportName}
                featureName={featureName}
                actionType={this.state.actionType}
                handleFormCategory={this._handleFormCategory}
                handleChangeTypeAction={this._handleChangeTypeAction}/>
          </div>
        )
    }
}

export default ReportPage;
