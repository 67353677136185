import React, { Component } from 'react';
import {common} from '../utility/common';
import _ from 'lodash';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppFooter from './AppFooter';
import $ from 'jquery';

class Layout extends Component {
    constructor(props){
        super(props);
        this.state={
            menuOpen: false
        }
    }

    componentDidMount(){
        setTimeout(function(){
            $('#e-safety').show();
        },100);        
    }

    handleMenuClick(e){
        this.setState({menuOpen: !this.state.menuOpen});     
    }

    render() {
        let token = common.getToken();
        const isLogin = _.isNull(token);
        return (
            <div className={`app sidebar-fixed ${this.state.menuOpen ? 'sidebar-lg-hide' : ''}`}>
                {!isLogin&&[
                    <AppHeader key="1" open={this.state.menuOpen} onClick={()=>this.handleMenuClick()} />,
                    <div className="app-body" key="app-body">
                        <AppSidebar open={this.state.menuOpen} />
                        <div className="main">
                        { this.props.children }
                        </div>
                        <AppFooter />  
                    </div>
                ]}
                {isLogin&&<div className='login-form'>{this.props.children}</div>}
            </div>
        );
    }
}

export default Layout;