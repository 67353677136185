import React, {Component} from 'react';
import _ from 'lodash';
import {compose} from 'redux';
import { connect } from 'react-redux';
import { Row,Col,Button,Container } from 'reactstrap';
import { reduxForm, reset, formValueSelector, Field } from 'redux-form';
import {withRouter} from 'react-router-dom';
import { history } from '../utility/history';
import { loadingUtility } from '../utility/loading';
import {dialogSelector} from '../utility/dialogSelector';
import {actionTypes} from '../constants/actionTypes';
import {categoryAction} from '../actions/categoryAction';
import { commonActions } from '../actions/commonAction';
import Pagination from '../controls/Pagination';
import ConfirmModal from '../controls/confirmModal';
import renderInput from '../controls/renderInput';
import CategoryFormModal from '../controls/categoryFormModal';
import CategoryTable from '../presentations/CategoryTable';
import withPermission from '../hocs/withPermission';
import commonWrapped from '../hocs/commonWrapped';
import validate from '../validate/validate';
import {permissionAction} from '../actions/permissionAction';

const renderActionFilterForm = (rs, openFilter, props, fns) => {
  const {catalogName, featureName} = props;
  const {toggleFilter, onClickAddEditItem, checkPermissionFeature, handleChangeTextSearch}=fns;
  return(
    <div className="filter-group">
        <div className="action-group">
            {openFilter && (
            // <Field name="textSearch" component={renderInput} autoFocus />
            <input type="text" name="textSearch" className="input-config" autoFocus onChange={e => handleChangeTextSearch(e)} />
            )}
            <Button color="info" type='button' onClick={e => {toggleFilter()}}><i className="fa fa-search" aria-hidden="true"></i></Button>
            {checkPermissionFeature(featureName, 'add') && (<>{catalogName !== 'accident' ? <Button color="primary" type="button" onClick={(e)=>{onClickAddEditItem({})}}>{_.get(rs,'btnNames.addNew')}</Button> : null}</>)}
        </div>
    </div>
  );
}

class CategoryListing extends Component {
    constructor (props, context){
        super(props, context);
        this.state={
            rs: _.get(window.ePayment,'resource'),
            selectedItem: null,
            isOpen: false,
            title: null,
            message: null,
            typeForm: null,
            pageOfItems: [],
            initialPage: 1,
            pageSize: 25,
            search: "",
            afterFilter: [],
            catalogName: null,
            originalList: null,
            openFilter: false,
            actionType: null,
            textSearch: null,
            types: [
                {
                    type: "AT",
                    name: 'An toàn'
                },
                {
                    type: "CL",
                    name: 'Chất lượng'
                }
            ]
        };
        this.onChangePage = this.onChangePage.bind(this);
        this.debouncedOnChange = _.debounce(this.debouncedOnChange.bind(this), 200);
    }
    componentWillMount(){
        this.props.onLoadCategory();
    }
    componentDidUpdate(prevProps, prevState) {
        const catalogName = this.props.catalogName;        
        if(prevProps.catalogName !== catalogName){
            this.setState({openFilter: false});
            this.props.dispatch(reset(this.props.form));
            this.setState({catalogName:catalogName, initialPage: 1, pageOfItems:[], originalList: []},()=>{
                this.props.onLoadCategory();
            });
        }else if(this.props.categories !== prevProps.categories){
            const category = this.props.categories[catalogName +'s'];
            const oldData = prevProps.categories[catalogName +'s'];
            const originalList = this.state.originalList;
            if(originalList == null && category.length > 0){//handle back from edit project page
                this.setState({catalogName:catalogName, initialPage: 1, pageOfItems:[], originalList: category},()=>{
                    this._dataAfterFilter();
                });
            }else if(oldData === undefined){//oldData does have not data
                this.setState({catalogName:catalogName, initialPage: 1, pageOfItems:[], originalList: category},()=>{
                    this._dataAfterFilter();
                });
            }else if(!validate.compare2Jsons(category, oldData)){// category.length !== oldData.length && this.props.categories.selectedItem !== prevProps.categories.selectedItem
                //Category change or Update data.
                this.setState({catalogName:catalogName, initialPage: 1, pageOfItems:[], originalList: category},()=>{
                    this._dataAfterFilter();
                });
            }
        }else{
            this._trackingFilter(prevState);
        }
    }
    

    _handleChangeTypeAction = () => {
      this.props.handleChangeTypeAction();
    }

    _handleChangeTextSearch = (event) => {
        this.setState({textSearch: event.target.value});
    }

    // _trackingFilter = (prevProps) =>{
    _trackingFilter = (prevState) =>{
        if(prevState.textSearch !== this.state.textSearch){
          this._handleChangeTypeAction();
          this._dataAfterFilter();
        }
    }

    isMatch(txtField, txtSearch){
        if (txtField !== null && txtField !== undefined && (_.isMatch(txtField.toLowerCase(),txtSearch) || txtField.toLowerCase().indexOf(txtSearch) >= 0))
            return true;
        return false;
    }

    existInListUser(arr, textSearch){
        if(arr !== undefined && arr !==null ){
            if(textSearch !== ''){
                for(var i=0; i<arr.length; i++){
                    const item = arr[i];
                    if(item.userName !== null && item.userName !== undefined && (_.isMatch(item.userName.toLowerCase(),textSearch) || item.userName.toLowerCase().indexOf(textSearch) >= 0))
                        return true;
                }
            }
        }
        return false;
    }

    _dataAfterFilter = () =>{
        let dataAfterFilter = this.state.originalList;
        const {catalogName, openFilter} = this.state;
        // let textSearch = this.props.textSearch;
        let textSearch = this.state.textSearch;
        textSearch = textSearch?_.escapeRegExp(textSearch).trim().toLowerCase():'';
        if(catalogName && openFilter&& textSearch !== ''){
            dataAfterFilter = this.state.originalList && this.state.originalList.filter((item) =>{
                if(catalogName === 'project'){
                  if (this.isMatch(item.projectCode,textSearch) 
                    || this.isMatch(item.projectName,textSearch) 
                    || this.isMatch(item.statusName,textSearch) 
                    || this.existInListUser(item.users, textSearch) 
                    || this.existInListUser(item.siteCommanders, textSearch) 
                    || this.existInListUser(item.groupsNotifications, textSearch) 
                    ||this.existInListUser(item.engineers, textSearch))
                      return item;
                }else if(catalogName === 'contractor'){
                  if (this.isMatch(item.contractorCode,textSearch) || this.isMatch(item.contractorName,textSearch) || this.isMatch(item.note,textSearch))
                      return item;
                }else if(catalogName === 'accident'){
                  if (this.isMatch(item.accidentName,textSearch) || this.isMatch(item.note,textSearch) || _.isMatch((_.isNull(item.sortOrder) ? '' : item.sortOrder).toString(), textSearch))
                      return item;
                }else if(catalogName === 'training'){
                  if (this.isMatch(item.trainingCode,textSearch) || this.isMatch(item.trainingName,textSearch) || _.isMatch((_.isNull(item.sortOrder) ? '' : item.sortOrder).toString(), textSearch))
                      return item;
                }else if(catalogName === 'violation'){
                  if (this.isMatch(item.violationCode,textSearch) || this.isMatch(item.violationName,textSearch) || this.isMatch(item.riskControl,textSearch) || _.isMatch((_.isNull(item.sortOrder) ? '' : item.sortOrder).toString(), textSearch) || this.isMatch(item.typeName,textSearch))
                      return item;
                }else if(catalogName === 'punishment'){
                  if (this.isMatch(item.punishmentCode,textSearch) || this.isMatch(item.punishmentName,textSearch) || _.isMatch(item.penaltyFee.toString(), textSearch) || this.isMatch(item.note,textSearch) || _.isMatch((_.isNull(item.sortOrder) ? '' : item.sortOrder).toString(), textSearch))
                      return item;
                }else if(catalogName === 'image'){
                  if (this.isMatch(item.categoryCode,textSearch) || this.isMatch(item.categoryName,textSearch) || _.isMatch((_.isNull(item.sortOrder) ? '' : item.sortOrder).toString(), textSearch))
                      return item;
                }else if(catalogName === 'hazardous'){
                  if (this.isMatch(item.hazardCode,textSearch) || this.isMatch(item.hazardName,textSearch) || _.isMatch((_.isNull(item.sortOrder) ? '' : item.sortOrder).toString(), textSearch))
                      return item;
                }else if(catalogName === 'team'){
                  if (this.isMatch(item.teamCode,textSearch) || this.isMatch(item.teamName,textSearch) || this.isMatch(item.note,textSearch) || _.isMatch((_.isNull(item.sortOrder) ? '' : item.sortOrder).toString(), textSearch))
                      return item;
                }
            });
        }
        this.debouncedOnChange(dataAfterFilter);
    }
    debouncedOnChange(dataAfterFilter) {
      if(this.props.actionType === 'add' ){
        this.setState({dataAfterFilter});
      }else if(this.props.actionType === 'update' || this.props.actionType === 'delete'){
        this.setState({dataAfterFilter});
      }else{
        this.setState({dataAfterFilter, initialPage:1});
      }
    }

    _toggleFilter=()=>{
        this.setState({
            openFilter: !this.state.openFilter
        });
    }

    toggleFn = () =>{
        this.setState({isOpen: !this.state.isOpen});
    }
    okFn = (data)=>{
        this.props.handleFormCategory('delete');
        this.props.dispatch(categoryAction.submitCategory({type_action : 'delete', catalogName: this.props.catalogName}, data));
    }

    toggleCategoryFormFn = () =>{
        if(this.props.isOpenCategoryForm){
            this.props.dispatch(reset('catelogForm'));
            this.props.dispatch(commonActions.closeDialog());
        }else{
            this.props.dispatch(commonActions.showDialog());
        }
    }

    _toggleFilter=()=>{
        this.setState({
            openFilter: !this.state.openFilter
        });
    }

    onClickViewItem = (item) => {
      if(this.props.catalogName === 'project'){
          if(!_.isUndefined(item.id) && item.id > 0){
              this.props.history.push(`/danh-muc/du-an/view/${item.id}`);
          }
      }
    }

    onClickAddEditItem = (item) => {
        if(this.props.catalogName === 'project'){
            if(!_.isUndefined(item.id) && item.id > 0){
                this.props.history.push(`/danh-muc/du-an/update/${item.id}`);
            }else{
                this.props.history.push(`/danh-muc/du-an/add`);
            }
        }else{
            if(this.props.catalogName === 'violation') {
                this.props.dispatch(categoryAction.getParentItems(this.props.catalogName, 'getParentItems', item))
                this.props.dispatch(categoryAction.getViolationCategoryType())
            }else if(this.props.catalogName === 'punishment') 
                this.props.dispatch(categoryAction.getParentItems(this.props.catalogName, 'getParentItems', item));
            else if(this.props.catalogName === 'hazardous') 
                this.props.dispatch(categoryAction.getParentItems(this.props.catalogName, 'getParentItems', item));
            if(!_.isUndefined(item.id) && item.id > 0){
                this.props.handleFormCategory('update');
                this.setState({typeForm: 'update'});
                this.props.dispatch({
                    type: actionTypes.CATELOGORY_ITEM_SELECTED,
                    data: item
                });
            }else{
                this.props.dispatch({
                    type: actionTypes.CATELOGORY_ITEM_SELECTED,
                    data: null
                });
                this.props.handleFormCategory('add');
                this.setState({typeForm: 'add'});
                this.props.dispatch(reset('catelogForm'));
            }
            this.toggleCategoryFormFn();
        }
    }

    onClickRemoveItem = (item) => {
        this.setState({'selectedItem': item, 'title': <>{_.get(this.state.rs,'appModal.deleteTitle')}</>, 'message': <>{_.get(this.state.rs,'messages.confirmDelete')}</>});
        this.toggleFn();
    }

    onChangePage(pageOfItems,page) {
        this.setState({ pageOfItems: pageOfItems, initialPage : page});
    }

    pageNotFound(){
      history.push('/not-found');
    }

    render(){
        const { openFilter } = this.state;
        const {catalogName, featureName, checkPermissionFeature} = this.props;
        const rs = _.get(window.ePayment,'resource');
        return(
            checkPermissionFeature(featureName, 'view') ? <Container fluid>
                {renderActionFilterForm(rs, openFilter, {...this.props}, {
                  toggleFilter: this._toggleFilter,
                  onClickAddEditItem: this.onClickAddEditItem,
                  checkPermissionFeature: checkPermissionFeature,
                  handleChangeTextSearch: this._handleChangeTextSearch
                })}
                <Row>
                    <Col md='12'>
                        <ConfirmModal data={this.state.selectedItem}
                            mdTitle={this.state.title}
                            mdBody={this.state.message}
                            isOpen={this.state.isOpen}
                            okFn={this.okFn}
                            toggleFn={this.toggleFn}/>

                        {this.props.isOpenCategoryForm && (
                            <CategoryFormModal
                                props={this.props}
                                types={this.props.violationCategoryType}
                                catalogName={catalogName}
                                violationParents={this.props.violationParents}
                                punishmentParents={this.props.punishmentParents}
                                hazardousParents={this.props.hazardousParents}
                                isOpen={this.props.isOpenCategoryForm}
                                toggleFn={this.toggleCategoryFormFn}
                                typeForm={this.state.typeForm}/>
                        )}

                        <CategoryTable 
                            catalogName={catalogName} 
                            categoryList={this.state.pageOfItems} 
                            viewItemFn={this.onClickViewItem} editItemFn={this.onClickAddEditItem} 
                            deleteItemFn={this.onClickRemoveItem} 
                            checkPermissionFeature={checkPermissionFeature} 
                            featureName={featureName}/>
                        {(this.state.dataAfterFilter !== null && this.state.dataAfterFilter !== undefined ) &&
                        <Pagination initialPage={this.state.initialPage} pageSize={this.state.pageSize} items={this.state.dataAfterFilter} onChangePage={this.onChangePage} />}

                    </Col>
                </Row>
            </Container> : <Container><div><h5 className="text-center">Bạn không có quyền truy cập trang này, vui lòng liên hệ admin!</h5></div></Container>
        );
    }
}

const loadingSelector = loadingUtility(['CATEGORY_DATA']);
const selector = formValueSelector('catelogForm');
const dlgSelector = dialogSelector(['DIALOG'])
const mapStateToProps = state => {
    return {
        isFetching: loadingSelector(state),
        categories: state.categoryReducer.categories,
        initialValues: state.categoryReducer.categories.selectedItem,
        isOpenCategoryForm: dlgSelector(state),
        violationParents: state.categoryReducer.violationParents,
        punishmentParents: state.categoryReducer.punishmentParents,
        hazardousParents: state.categoryReducer.hazardousParents,
        // textSearch: selector(state, 'textSearch'),
        violationCategoryType: state.categoryReducer.violationCategoryType
    }
}

const mapDispatchToProps = (dispatch,props) => ({
    onSubmit: (values) => {
        let alter_props = props;
        let alterValues = values;
        if(values.parentID != null){
            if(!_.isUndefined(values.parentID) && _.isObject(values.parentID)) values.parentID = values.parentID.id;
        }
        if(values.type !== null){
            if(!_.isUndefined(values.type) && _.isObject(values.type)) values.type = values.type.generalCategoryCode;
        }
        if(!_.isUndefined(values.id) && values.id > 0){
            alter_props = {...props, type_action : 'update'};
        }else{
            alter_props  = {...props, type_action : 'add'};
        }
        dispatch(categoryAction.submitCategory(alter_props, alterValues));
    },
    onLoadCategory: ()=>{
        const catalogName = props.catalogName;
        dispatch(commonActions.request(actionTypes.CATEGORY_DATA_REQUEST));
        new Promise((resolve, reject) => {
            dispatch(categoryAction.getCategoryList(catalogName, 'gets', resolve,reject));
        }).then(results=>{           
            let categories = {};
            categories[catalogName +'s'] = results;
            dispatch({type: actionTypes.UPDATE_CATEGORIES_DATA, data: categories});
            dispatch(commonActions.request(actionTypes.CATEGORY_DATA_SUCCESS, {results}));
        },err=>{
            dispatch(commonActions.request(actionTypes.CATEGORY_DATA_FAIL, err));
        });
    }
});


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'catelogForm',
        enableReinitialize : true, // this is needed to refresh initial-values!!,
    }),
    commonWrapped({showLoadingWholePage: true}),
    withRouter,
    withPermission()
)(CategoryListing);
