import React from 'react';
import _ from 'lodash';
import { FormGroup, Label, Container,Row, Col } from 'reactstrap';
import { Field} from 'redux-form';
import renderInput from '../controls/renderInput';
import renderCheckbox from '../controls/renderCheckbox';
import validate from '../validate/validate';

const AccidentForm = () => {
    const rs = _.get(window.ePayment,'resource');
    return(
        <Container fluid>
            <Row>
                <Col md='12'>
                    <FormGroup>
                        <Label>{_.get(rs,'accidentCategory.name')}</Label>
                        <Field name="accidentName" type='text' validate={validate.required} component={renderInput}/>
                    </FormGroup>
                </Col>
                <Col md='12'>
                    <FormGroup>
                        <Label>{_.get(rs,'categories.note')}</Label>
                        <Field name="note" type='text' component={renderInput}/>
                    </FormGroup>
                </Col>
                <Col md='6'>
                    <FormGroup>
                        <Label>{_.get(rs,'categories.sortorder')}</Label>
                        <Field name="sortOrder" type='text' validate={[validate.number, validate.integer]} component={renderInput}/>
                    </FormGroup>
                </Col>
                <Col md='6'>
                    <FormGroup>
                        <Label>{_.get(rs,'categories.active')}</Label>
                        <Field name="isActive" type='checkbox' component={renderCheckbox}/>
                    </FormGroup>
                </Col>
            </Row> 
        </Container>
    )
}

export default AccidentForm;