import React, { Component} from 'react';
import { compose } from 'redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Nav, NavItem,Collapse,NavLink as RSNavLink  } from 'reactstrap';
import _ from 'lodash';
import withPermission from '../hocs/withPermission';

class AppSidebar extends Component {

    constructor(props) {
        super(props);

        this.state={
            open: this.props.open? this.props.open:false,
            collapseCategory: false,
            collapseReport: false,
            rs: _.get(window.ePayment,'resource')
        }

        this.toggleCategory = this.toggleCategory.bind(this);
        this.toggleReport = this.toggleReport.bind(this);
    }


    componentWillReceiveProps(nextProps){
        if(nextProps.open !== this.state.open){
          this.setState({open:nextProps.open});
        }
    }

    toggleCategory() {
        this.setState(state => ({ collapseCategory: !state.collapseCategory }));
    }

    toggleReport() {
        this.setState(state => ({ collapseReport: !state.collapseReport }));
    }

    render(){
        const {checkPermissionFeature} = this.props;
        return (
            <div className={"sidebar " + (this.props.open ? 'sidebar-minimized' : '')}>
                <div className="scrollbar-container sidebar-nav">
                    <Nav vertical>

                        {/* <NavItem className="nav-title">{_.get(this.state.rs,'appSidebar.headerName')}</NavItem> */}

                        <NavItem className={this.state.collapseCategory ? 'open' : ''}>
                            <RSNavLink className="nav-link" onClick={this.toggleCategory}>
                            <i className='nav-icon fa fa-fw fa-list-alt' aria-hidden='true'></i>
                            <span className="title dropdown_title">{_.get(this.state.rs,'appSidebar.categoryName')}</span>
                            <span className="arrow open"></span>
                            </RSNavLink>
                            <Collapse isOpen={this.state.collapseCategory} className={"sub-menu " + (this.state.collapseCategory ? 'show' : '')}>
                                {checkPermissionFeature('Project_Category', 'view') && <NavLink className="nav-link" to={'/danh-muc/du-an'} activeClassName="active">
                                <span className="title">{_.get(this.state.rs,'categories.projects')}</span>
                                </NavLink>}
                                {checkPermissionFeature('Contractor_Category', 'view') && <NavLink className="nav-link" to={'/danh-muc/nha-thau'} activeClassName="active">
                                <span className="title">{_.get(this.state.rs,'categories.contractors')}</span>
                                </NavLink>}
                                {checkPermissionFeature('Accident_Category', 'view') && <NavLink className="nav-link" to={'/danh-muc/su-co'} activeClassName="active">
                                <span className="title">{_.get(this.state.rs,'categories.accidents')}</span>
                                </NavLink>}
                                {checkPermissionFeature('Training_Category', 'view') && <NavLink className="nav-link" to={'/danh-muc/huan-luyen-an-toan'} activeClassName="active">
                                <span className="title">Danh mục huấn luyện <br></br>an toàn</span>
                                </NavLink>}
                                {checkPermissionFeature('Violation_Category', 'view') && <NavLink className="nav-link" to={'/danh-muc/vi-pham'} activeClassName="active">
                                <span className="title">{_.get(this.state.rs,'categories.violations')}</span>
                                </NavLink>}
                                {checkPermissionFeature('Punishment_Category', 'view') && <NavLink className="nav-link" to={'/danh-muc/loi-vi-pham'} activeClassName="active">
                                <span className="title">{_.get(this.state.rs,'categories.punishments')}</span>
                                </NavLink>}
                                {checkPermissionFeature('Image_Category', 'view') && <NavLink className="nav-link" to={'/danh-muc/hinh-anh-cong-truong'} activeClassName="active">
                                <span className="title">Danh mục hình ảnh <br></br>công trường</span>
                                </NavLink>}
                                {checkPermissionFeature('Hazard_Category', 'view') && <NavLink className="nav-link" to={'/danh-muc/moi-nguy'} activeClassName="active">
                                <span className="title">{_.get(this.state.rs,'categories.hazardous')}</span>
                                </NavLink>}
                                {checkPermissionFeature('Team_Category', 'view') && <NavLink className="nav-link" to={'/danh-muc/to-doi'} activeClassName="active">
                                <span className="title">{_.get(this.state.rs,'categories.team')}</span>
                                </NavLink>}
                            </Collapse>
                        </NavItem>
                        {checkPermissionFeature('Permission', 'view') && <NavItem>
                          <NavLink className="nav-link" to={'/phan-quyen'} activeClassName="active">
                            <i className='nav-icon fa fa-fw fa-user-secret' aria-hidden='true'></i>
                            <span className="title">Phân quyền </span>
                          </NavLink>
                        </NavItem>}
                        {checkPermissionFeature('User', 'view') && <NavItem>
                            <NavLink className="nav-link" to={'/nhan-vien'} activeClassName="active">
                            <i className='nav-icon fa fa-fw fa-users' aria-hidden='true'></i>
                            <span className="title">Nhân viên</span>
                            </NavLink>
                        </NavItem>}
                        <NavItem className={this.state.collapseReport? 'open' : ''}>
                              <RSNavLink className="nav-link" onClick={this.toggleReport}>
                              <i className='nav-icon fa fa-fw fa-file' aria-hidden='true'></i>
                              <span className="title dropdown_title">Báo cáo</span>
                              <span className="arrow open"></span>
                              </RSNavLink>
                              <Collapse isOpen={this.state.collapseReport} className={"sub-menu " + (this.state.collapseReport ? 'show' : '')}>
                                  {checkPermissionFeature('Report09', 'view') && <NavLink className="nav-link" to={'/bao-cao/moi-nguy'} activeClassName="active">
                                  <span className="title">Mối nguy và công việc <br></br>hằng ngày</span>
                                  </NavLink>}
                                  {checkPermissionFeature('Report10', 'view') && <NavLink className="nav-link" to={'/bao-cao/vi-pham'} activeClassName="active">
                                  <span className="title">Vi phạm</span>
                                  </NavLink>}
                              </Collapse>
                          </NavItem>
                    </Nav>
                </div>
            </div>
        )
    }
}

export default compose(
    withRouter,
    withPermission()
)(AppSidebar);
