import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector, change, getFormInitialValues } from 'redux-form';
import { categoryAction } from '../actions/categoryAction';
import { actionTypes } from '../constants/actionTypes';
import ProjectDetailForm from '../presentations/ProjectDetailForm';
import {withRouter} from 'react-router-dom';
import {toastr} from 'react-redux-toastr';
import ConfirmModal from '../controls/confirmModal';

class ProjectDetail extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          rs: _.get(window.ePayment,'resource'),
          isOpen: false,
          title: null,
          message: null,
          selectedItem: null,
          numTower: null,
          numZone: null,
          numLevel: null
        }

        this.createTowerFacZone = this.createTowerFacZone.bind(this);
        this.collapseTowerFacZone = this.collapseTowerFacZone.bind(this);
        this.editingTowerFacZone = this.editingTowerFacZone.bind(this);
        this.cancelEditTowerFacZone = this.cancelEditTowerFacZone.bind(this);
        this.deleteTowerFacZone = this.deleteTowerFacZone.bind(this);
        this.submitTowerFacZone = this.submitTowerFacZone.bind(this);

        this.createContractorTeamUser = this.createContractorTeamUser.bind(this);
        this.collapseContractorTeamUser = this.collapseContractorTeamUser.bind(this);
        this.editingContractorTeamUser = this.editingContractorTeamUser.bind(this);
        this.cancelEditContractorTeamUser = this.cancelEditContractorTeamUser.bind(this);
        this.deleteContractorTeamUser = this.deleteContractorTeamUser.bind(this);
        this.submitContractorTeamUser = this.submitContractorTeamUser.bind(this);

        this.handleRemoveItem = this.handleRemoveItem.bind(this);
        this.onChangeTowerFacZone = this.onChangeTowerFacZone.bind(this);
        this.onChangeContractorTeamUser = this.onChangeContractorTeamUser.bind(this);
    }

    createTowerFacZone(idxItem, type, obj){
        let values = _.get(this.props.projectItem, idxItem); 
        let data = {
            type: type,
            projectID: this.props.projectItem.id,
        };
        let number;
        if(type === 1){
            data.parentID = null;
            number = Number(this.props.numTower);
        }else if(type === 2 || type === 3){
            data.parentID = values.id;
            number = Number(obj.number);
        }
        let checkIsEditing = this.checkIsEditingTowerFacZone();
        if(checkIsEditing){
            toastr.error('Error', 'Vui lòng lưu thông tin bạn chỉnh sửa trước khi tạo mới!');
        }else{
            if(number !== undefined && number !== null && number > 0){
                this.props.dispatch(categoryAction.towerFacZonePutMulti({idxItem: idxItem, type_action : 'new'}, number, data));
            }else{
                toastr.error('Error', 'Vui lòng nhập số dòng phải lớn hơn 0 trước khi tạo mới!');
            }
        }
    }

    checkIsEditingTowerFacZone = () => {
        let towerFacs = _.cloneDeep(this.props.towerFacs);
        let checkIsEditing = _.findIndex(towerFacs, ['isEditing', true]);
        if(checkIsEditing != -1){
            return true;
        }else{
            for(var i=0; i<towerFacs.length; i++){
                const tower = towerFacs[i];
                if(tower.Zones !== undefined && tower.Zones !== null ){
                    const idxZone = _.findIndex(tower.Zones, ['isEditing', true]);
                    if(idxZone != -1){
                        return true;
                    }else{
                        for(var j=0; j< tower.Zones.length; j++){
                            const zone = tower.Zones[j];
                            if(zone.Levels !== undefined && zone.Levels !== null ){
                                const idxLevel = _.findIndex(zone.Levels, ['isEditing', true]);
                                if(idxLevel != -1){
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    // CREATE THAP > ZONE > TANG
    // createTowerFacZone(idxItem, type){
    //     let values = _.get(this.props.projectItem, idxItem);
    //     if(type === 1){
    //         const data = {
    //             type: type,
    //             projectID: this.props.projectItem.id,
    //             parentID: null,
    //             isEditing: true
    //         }
    //         this.props.dispatch({
    //             type: actionTypes.GET_PROJECT_TOWER_FAC_ZONE_SUCCESS,
    //             data: {idxItem: idxItem, type_action : 'newThap', request: data}
    //         });
    //     }else if(type === 2){
    //         const data = {
    //             type: type,
    //             projectID: this.props.projectItem.id,
    //             parentID: values.id,
    //             isEditing: true
    //         }
    //         if(!_.isUndefined(values.id) && values.id > 0){
    //             if(!_.isUndefined(values.Zones) && values.Zones.length > 0){
    //                 this.props.dispatch({
    //                     type: actionTypes.GET_PROJECT_TOWER_FAC_ZONE_SUCCESS,
    //                     data: {
    //                         idxItem: idxItem, type_action : 'newZone', request: data
    //                     }
    //                 })
    //             }else{
    //                 this.props.dispatch(categoryAction.getProjectTowerFacZone({idxItem: idxItem, type_action : 'newZone'}, data));
    //             }
    //         }else{
    //             toastr.error('Error', 'Vui lòng lưu Tháp trước khi tạo mới Zone!');
    //         }
    //     }else if(type === 3){
    //         const data = {
    //             type: type,
    //             projectID: this.props.projectItem.id,
    //             parentID: values.id,
    //             isEditing: true
    //         }
    //         if(!_.isUndefined(values.id) && values.id > 0){
    //             if(!_.isUndefined(values.Levels) && values.Levels.length > 0){
    //                 this.props.dispatch({
    //                     type: actionTypes.GET_PROJECT_TOWER_FAC_ZONE_SUCCESS,
    //                     data: {
    //                         idxItem: idxItem, type_action : 'newTang', request: data
    //                     }
    //                 })
    //             }else{
    //                 this.props.dispatch(categoryAction.getProjectTowerFacZone({idxItem: idxItem, type_action : 'newTang'}, data));
    //             }
    //         }else{
    //             toastr.error('Error', 'Vui lòng lưu Zone trước khi tạo mới Tầng!');
    //         }
    //     }
    // }

    // COLLAPSE THAP > ZONE > TANG
    collapseTowerFacZone(idxItem){
        let values = _.get(this.props.projectItem, idxItem);
        let alter_props = {idxItem: idxItem, type_action: 'collapse'};
        if(!_.isUndefined(values.id) && values.id > 0){
            this.props.dispatch(categoryAction.getProjectTowerFacZone(alter_props, values));
        }
    }

    // EDIT THAP > ZONE > TANG
    editingTowerFacZone(idxItem){
        let values = _.cloneDeep(_.get(this.props.projectItem, idxItem));
        if(!_.isUndefined(values.id) && values.id > 0){
            values.isEditing=true;
            values.nameInit=values.name;
            values.noteInit=values.note;
            values.sortOrderInit=values.sortOrder;
            this.props.dispatch({
                type: actionTypes.PROJECT_TOWER_FAC_ZONE_SUCCESS,
                data: {idxItem: idxItem, type_action : 'editing', request: values}
            })
        }
    }

    cancelEditTowerFacZone(idxItem){
        let values = _.cloneDeep(_.get(this.props.projectItem, idxItem));
        if(!_.isUndefined(values.id) && values.id > 0){
            values.isEditing = false;
            values.name = values.nameInit;
            values.note = values.noteInit;
            values.sortOrder = values.sortOrderInit;
            this.props.dispatch({
                type: actionTypes.PROJECT_TOWER_FAC_ZONE_SUCCESS,
                data: {idxItem: idxItem, type_action : 'editing', request: values}
            })
        }
    }
    onChangeTowerFacZone(value, idxItem, inputText){
        let values = _.cloneDeep(_.get(this.props.projectItem, idxItem));
        if(inputText === 'name') values.name = value;
        if(inputText === 'note') values.note = value;
        if(inputText === 'sortOrder') values.sortOrder = value;
        if(!_.isUndefined(values.id) && values.id > 0){
            this.props.dispatch({
                type: actionTypes.PROJECT_TOWER_FAC_ZONE_SUCCESS,
                data: {idxItem: idxItem, type_action : 'editing', request: values}
            });
        }
    }
    // editingTowerFacZone(type, idxTower, idxZone, idxLevel){
    //     let towerFacs = _.cloneDeep(this.props.towerFacs);
    //     if(type === 1) towerFacs[idxTower].isEditing=true;
    //     if(type === 2) towerFacs[idxTower].Zones[idxZone].isEditing=true;
    //     if(type === 3) towerFacs[idxTower].Zones[idxZone].Levels[idxLevel].isEditing=true;
    //     this.props.dispatch(change(this.props.form, 'towerFacs', towerFacs));
    // }

    // cancelEditTowerFacZone(type, idxTower, idxZone, idxLevel, idxItem){
    //     const values = _.get(this.props.projectItem, idxItem.idxItem);
    //     if(!_.isUndefined(values.id) && values.id > 0){
    //         let towerFacs = _.cloneDeep(this.props.towerFacs);
    //         if(type === 1) towerFacs[idxTower].isEditing=false;
    //         if(type === 2) towerFacs[idxTower].Zones[idxZone].isEditing=false;
    //         if(type === 3) towerFacs[idxTower].Zones[idxZone].Levels[idxLevel].isEditing=false;
    //         this.props.dispatch(change(this.props.form, idxItem.idxItem, values));
    //     }else{
    //         this.props.dispatch({
    //             type: actionTypes.PROJECT_TOWER_FAC_ZONE_SUCCESS,
    //             data: {idxItem: idxItem.idxItem, type_action : 'delete', request: values}
    //         })
    //     }
    // }

    // onChangeTowerFacZone(value, idxItem, inputText){
    //     debugger;
    //     let values = _.cloneDeep(_.get(this.props.projectItem, idxItem));

    //     if(inputText === 'name') values.name = value;
    //     if(inputText === 'note') values.note = value;
    //     if(inputText === 'sortOrder') values.sortOrder = value;
    //     if(!_.isUndefined(values.id) && values.id > 0){
    //         // values.isEditing = true;
    //     }else{
    //         this.props.dispatch({
    //             type: actionTypes.PROJECT_TOWER_FAC_ZONE_SUCCESS,
    //             data: {idxItem: idxItem, type_action : 'editing', request: values}
    //         })
    //     }
    // }

    // delete THAP > ZONE > TANG
    deleteTowerFacZone(idxItem){
        const values = _.get(this.props.projectItem, idxItem);
        if(!_.isUndefined(values.id) && values.id > 0){
            this.props.dispatch(categoryAction.deleteProjectTowerFacZone({idxItem: idxItem, type_action : 'delete'}, values));
        }else{
            this.props.dispatch({
                type: actionTypes.PROJECT_TOWER_FAC_ZONE_SUCCESS,
                data: {idxItem: idxItem, type_action : 'delete', request: values}
            })
        }
    }

    // SUBMIT THAP > ZONE > TANG
    submitTowerFacZone(idxItem, values, type){
        // let values = _.get(this.props.projectItem, idxItem);
        let alter_props;
        let alter_values = values;
        if(!_.isUndefined(values.id) && values.id > 0){
            alter_props = {idxItem: idxItem, type_action : 'update'};
        }else{
            alter_props  = {idxItem: idxItem, type_action : 'add'};
        }
        this.props.dispatch(categoryAction.submitProjectTowerFacZone(alter_props, alter_values));
    }

    // CREATE CONTRACTOR > TEAM > USER (ENGINEER)
    createContractorTeamUser(idxItem, type){
        let values = _.get(this.props.projectItem, idxItem);
        let checkIsEditing = this.checkIsEditingContractorTeamUser();
        if(checkIsEditing){
            toastr.error('Error', 'Vui lòng lưu thông tin bạn chỉnh sửa trước khi tạo mới!');
        }else{
            
            if(type === 1){
                const data = {
                    type: type,
                    projectID: this.props.projectItem.id,
                    isEditing: true
                }
                this.props.dispatch({
                    type: actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS,
                    data: {idxItem: idxItem, type_action : 'new', request: data}
                })
            }else if(type === 2){
                const data = {
                    projectID: this.props.projectItem.id,
                    ProjectContractorID: values.rowID,
                    contractorID: values.contractorID,
                    type: type,
                    isEditing: true
                }
                if(!_.isUndefined(values.rowID) && values.rowID > 0){
                    if(!_.isUndefined(values.Teams) && values.Teams.length > 0){
                        this.props.dispatch({
                            type: actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS,
                            data: {
                                idxItem: idxItem, type_action : 'new', request: data
                            }
                        })
                    }else{
                        this.props.dispatch(categoryAction.getTeamByContractor({idxItem: idxItem, type_action : 'new'}, data));
                    }
                }else{
                    toastr.error('Error', 'Vui lòng lưu Nhà thầu trước khi tạo mới Tổ / Đội!');
                }
            }else if(type === 3){
                const data = {
                    projectID: this.props.projectItem.id,
                    teamID: values.rowID,
                    type: type,
                    isEditing: true
                }
                if(!_.isUndefined(values.rowID) && values.rowID > 0){
                    if(!_.isUndefined(values.Users) && values.Users.length > 0){
                        this.props.dispatch({
                            type: actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS,
                            data: {
                                idxItem: idxItem, type_action : 'new', request: data
                            }
                        })
                    }else{
                        this.props.dispatch(categoryAction.getUserByTeam({idxItem: idxItem, type_action : 'new'},data));
                    }
                }else{
                    toastr.error('Error', 'Vui lòng lưu Tổ/ Đội trước khi tạo mới Kỹ sư!');
                }
            }
        }
    }

    checkIsEditingContractorTeamUser = () => {
        let contractors = _.cloneDeep(this.props.contractors);
        let checkIsEditing = _.findIndex(contractors, ['isEditing', true]);
        if(checkIsEditing != -1){
            return true;
        }else{
            for(var i=0; i<contractors.length; i++){
                const contractor = contractors[i];
                if(contractor.Teams !== undefined && contractor.Teams !== null ){
                    const idxTeam = _.findIndex(contractor.Teams, ['isEditing', true]);
                    if(idxTeam != -1){
                        return true;
                    }else{
                        for(var j=0; j< contractor.Teams.length; j++){
                            const team = contractor.Teams[j];
                            if(team.Users !== undefined && team.Users !== null ){
                                const idxUser = _.findIndex(team.Users, ['isEditing', true]);
                                if(idxUser != -1){
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    // COLLAPSE CONTRACTOR > TEAM > USER (ENGINEER)
    collapseContractorTeamUser(idxItem){
        let values = _.get(this.props.projectItem, idxItem);
        let alter_props = {idxItem: idxItem, type_action: 'collapse'};
        if(!_.isUndefined(values.rowID) && values.rowID > 0){
            if(values.teamID > 0){
                this.props.dispatch(categoryAction.getUserByTeam(alter_props, values));
            }else{
                this.props.dispatch(categoryAction.getTeamByContractor(alter_props, values));
            }
        }
    }

    editingContractorTeamUser(type, idxContractor, idxTeam, idxUser){
        let contractors = _.cloneDeep(this.props.contractors);
        if(type === 1) contractors[idxContractor].isEditing=true;
        if(type === 2) contractors[idxContractor].Teams[idxTeam].isEditing=true;
        if(type === 3) contractors[idxContractor].Teams[idxTeam].Users[idxUser].isEditing=true;
        this.props.dispatch(change(this.props.form, `contractors`, contractors));
    }

    cancelEditContractorTeamUser(type, idxContractor, idxTeam, idxUser, idxItem){
        const values = _.get(this.props.projectItem, idxItem.idxItem);
        if(!_.isUndefined(values.rowID) && values.rowID > 0){
            let contractors = _.cloneDeep(this.props.contractors);
            if(type === 1) contractors[idxContractor].isEditing=true;
            if(type === 2) contractors[idxContractor].Teams[idxTeam].isEditing=true;
            if(type === 3) contractors[idxContractor].Teams[idxTeam].Users[idxUser].isEditing=true;
            this.props.dispatch(change(this.props.form, idxItem.idxItem, values));
        }else{
            this.props.dispatch({
                type: actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS,
                data: {idxItem: idxItem.idxItem, type_action : 'delete', request: values}
            })
        }
    }

    onChangeContractorTeamUser(type, value, idxItem){
        let values = _.cloneDeep(_.get(this.props.projectItem, idxItem));
        if(type === 1){
            values.contractor = value;
            values.contractorCode = value.contractorCode;
            values.contractorID = value.id;
            values.contractorName = value.contractorName;
        }else if(type === 2){
            values.team = value;
            values.teamID = value.id;
            values.teamName = value.teamName;
        }else if(type === 3){
            values.user = value;
            values.userName = value.userName;
        }
        this.props.dispatch({
            type: actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS,
            data: {idxItem: idxItem, type_action : 'editing', request: values}
        })
    }

    // DELETE CONTRACTOR > TEAM > USER (ENGINEER)
    deleteContractorTeamUser(idxItem, type){
        let values = _.cloneDeep(_.get(this.props.projectItem, idxItem));
        values.level = type;
        if(!_.isUndefined(values.rowID) && values.rowID > 0) values.rowID = -values.rowID;
        let alter_props = {idxItem: idxItem, type_action : 'delete'};
        if(!_.isUndefined(values.rowID) && values.rowID < 0){
            if(!_.isUndefined(type) && type === 1){
                this.props.dispatch(categoryAction.updateContractorToProject(alter_props, [values]));
            }else if(!_.isUndefined(type) && type === 2){
                this.props.dispatch(categoryAction.updateTeamToContractor(alter_props, [values]));
            }else if(!_.isUndefined(type) && type === 3){
                this.props.dispatch(categoryAction.updateUserToTeam(alter_props, [values]));
            }
        }else{
            this.props.dispatch({
                type: actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS,
                data: {idxItem: idxItem, type_action : 'delete', request: values}
            })
        }
    }

    // SUBMIT CONTRACTOR > TEAM > USER (ENGINEER)
    submitContractorTeamUser(idxItem, values, type){
        // let values = _.get(this.props.projectItem, idxItem);
        let alter_props;
        let rowID;
        if(!_.isUndefined(values.rowID) && values.rowID > 0){
            rowID = values.rowID;
            alter_props = {idxItem: idxItem, type_action: 'update'};
        }else{
            rowID = null;
            alter_props  = {idxItem: idxItem, type_action: 'add'};
        }
        
        if(type === 1){
            // let data = {
            //     rowID: rowID,
            //     contractorCode: values.contractor.contractorCode,
            //     contractorID: values.contractor.id,
            //     contractorName: values.contractor.contractorName,
            //     projectID: this.props.projectItem.id
            // }
            let data = [];
            const projectID = this.props.projectItem.id;
            if(values.contractor !== null && values.contractor !== undefined){
                if(values.contractor.length > 0){
                    for (let i = 0; i < values.contractor.length; i++) {
                        const item = values.contractor[i];
                        data.push({contractorID: item.id, rowID: rowID, projectID: projectID, type: type});
                    }
                }
            }
            this.props.dispatch(categoryAction.updateContractorToProject(alter_props, data));
        }else if(type === 2){
            let data = [];
            if(values.team !== null && values.team !== undefined){
                if(values.team.length > 0){
                    for (let i = 0; i < values.team.length; i++) {
                        const item = values.team[i];
                        data.push({
                            projectContractorID: values.ProjectContractorID, 
                            teamID: item.id, 
                            projectID: values.projectID,
                            contractorID: values.contractorID,
                            type: values.type
                        });
                    }
                }
            }
            // values.teamID = values.team.id;
            // values.teamName = values.team.teamName;
            this.props.dispatch(categoryAction.updateTeamToContractor(alter_props, data));
        }else if(type === 3){
            console.log(values);
            let data = [];
            if(values.user !== null && values.user !== undefined){
                if(values.user.length > 0){
                    for (let i = 0; i < values.user.length; i++) {
                        const item = values.user[i];
                        data.push({
                            userName: item.userName, 
                            objectID: values.teamID,
                            projectID: values.projectID,
                            teamID: values.teamID,
                            type: type
                        });
                    }
                }
            }
            // values.userName = values.user.userName;
            // values.objectID = values.teamID;
            this.props.dispatch(categoryAction.updateUserToTeam(alter_props, data));
        }
    }

    handleRemoveItem(item){
        this.setState(
          {'selectedItem': item,
           'title': <>{_.get(this.state.rs,'appModal.deleteTitle')}</>,
           'message': <>{_.get(this.state.rs,'messages.confirmDelete')}</>
          }
        );
        this.toggleFn();
    }

    toggleFn = () =>{
        this.setState({isOpen: !this.state.isOpen});
    }
    okFn = (data)=>{
      if(data.kindOf === 'tower'){
        this.deleteTowerFacZone(data.idxItem);
      }else {
        this.deleteContractorTeamUser(data.idxItem, data.type);
      }
    }

    render() {
        const rs = _.get(window.ePayment, 'resource');
        const { handleSubmit, pristine, submitting, valid, projectStatus, categories, usersActiveList, initialValues, projectItem } = this.props;
        return (
            <div className="main-box">
              <ConfirmModal data={this.state.selectedItem}
                  mdTitle={this.state.title}
                  mdBody={this.state.message}
                  isOpen={this.state.isOpen}
                  okFn={this.okFn}
                  toggleFn={this.toggleFn}/>
                <form onSubmit={handleSubmit} className="filter-group form-request">
                    <ProjectDetailForm
                        projectItem={projectItem}
                        initialValues={initialValues}
                        projectStatus={projectStatus}
                        categories={categories}
                        usersActiveList={usersActiveList}
                        handleRemoveItem={this.handleRemoveItem}
                        action={this.props.action}
                        onChangeTowerFacZone={this.onChangeTowerFacZone}
                        onChangeContractorTeamUser={this.onChangeContractorTeamUser}

                        collapseTowerFacZone={this.collapseTowerFacZone}
                        editingTowerFacZone={this.editingTowerFacZone}
                        cancelEditTowerFacZone={this.cancelEditTowerFacZone}
                        createTowerFacZone={this.createTowerFacZone}
                        submitTowerFacZone={this.submitTowerFacZone}


                        editingContractorTeamUser={this.editingContractorTeamUser}
                        deleteContractorTeamUser={this.deleteContractorTeamUser}
                        collapseContractorTeamUser={this.collapseContractorTeamUser}
                        createContractorTeamUser={this.createContractorTeamUser}
                        submitContractorTeamUser={this.submitContractorTeamUser}
                        />
                </form>
            </div>
        )
    }
}

// const loadingSelector = loadingUtility(['CATELOGORY_GETLISTS_SUCCESS','PROJECT_STATUS_SUCCESS', 'LIST_ACTIVEUSERS_SUCCESS','PROJECT_INFO_SUCCESS']);
const selector = formValueSelector('projectCatelogDetailForm');

const mapStateToProps = state => {
    return {
        // isFetching: loadingSelector(state),
      initialValues: state.categoryReducer.projectItem,
        towerFacs: selector(state, 'towerFacs'),
        contractors: selector(state, 'contractors'),
        numTower: selector(state, 'numTower'),
        test: getFormInitialValues('projectCatelogDetailForm')(state),
    }
}

const mapDispatchToProps = (dispatch, props) => ({

});


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'projectCatelogDetailForm',
        enableReinitialize: true,// this is needed to refresh initial-values!!,
    }),
    // commonWrapped({ showLoadingWholePage: true }),
    withRouter
)(ProjectDetail);
