import React, {Component} from 'react';
import './App.css';
import {Route,Router, Switch, Redirect} from 'react-router-dom';
import { Provider } from 'react-redux';

import {PrivateRoute} from './PrivateRoute';
import WelcomePage from './pages/welcome.page';
import NotFoundPage from './pages/notfound.page';
import Layout from './layouts/Layout';
import UserPage from './pages/user.page';
import LoginPage from './pages/login.page';
import ReportPage from './pages/report.page';
import CategoryPage from './pages/category.page';
import ProjectPage from './pages/project.page';
import ChangePassPage from './pages/changepass.page.js';
import PermissionPage from './pages/permission.page';
import ReduxToastr from 'react-redux-toastr';


class App extends Component {
  render() {
    return (
      <Provider store={this.props.store}>
        {/* <BrowserRouter> */}
        <Router history={this.props.history}>
          <Layout>
            <Switch>
              {/* <Redirect exact from='/' to="/danh-muc/du-an"/> */}
              <Redirect exact from='/' to="/home"/>
              <PrivateRoute exact path="/home" component={WelcomePage} />
              <PrivateRoute exact path="/danh-muc/:name?" component={CategoryPage} />
              <PrivateRoute exact path="/danh-muc/:name?/:action?/:id?" component={ProjectPage} />
              <PrivateRoute exact path="/doi-mat-khau" component={ChangePassPage} />
              <PrivateRoute exact path="/nhan-vien" component={UserPage} />
              <PrivateRoute exact path="/phan-quyen" component={PermissionPage} />
              <PrivateRoute exact path="/bao-cao/:name?" component={ReportPage} />
              <Route path="/sign-in" component={LoginPage} />
              <Route path="/*" component={NotFoundPage} />
            </Switch>
          </Layout>
          </Router>
        {/* </BrowserRouter> */}
        <ReduxToastr
          timeOut={2000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick/>
      </Provider>
    )
  }
}

export default App
