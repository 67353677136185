import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { categoryAction } from '../actions/categoryAction';
import { userAction } from '../actions/userAction';
import { actionTypes } from '../constants/actionTypes';
import {withRouter} from 'react-router-dom';
import commonWrapped from '../hocs/commonWrapped';
import { commonActions } from '../actions/commonAction';
import ProjectMaster from '../presentations/ProjectMaster';
import ProjectDetail from '../presentations/ProjectDetail';
import withPermission from '../hocs/withPermission';
import { Container } from 'reactstrap';
import {history} from '../utility/history';
import { loadingUtility } from '../utility/loading';
import _ from 'lodash';

class ProjectPage extends Component {
    constructor(props, context) {
        super(props, context);
    }
    componentDidMount(){
        const action = this.props.match.params.action;
        const reportArr = ['duAn'];
        const hasRoute = reportArr.includes(_.camelCase(this.props.match.params.name));
        if(!hasRoute){
            history.push('/');
        }
        if(action !== 'view' && action !== 'update' && action !== 'add'){
          history.push('/');
        }else{
            if(action === 'add' && this.props.match.params.id !== undefined){
                history.push('/danh-muc/du-an/add');
            }
        }
        this.props.dispatch(commonActions.request(actionTypes.PROJECT_INFO_REQUEST));
        Promise.all([this._loadProjectStatus(), this._loadAllUsers(), this._loadCategories()]).then(result=>{
            this._loadRequest().then(rq=>{
                this.props.dispatch(commonActions.success(actionTypes.PROJECT_INFO_SUCCESS, rq));
            },err=>{
                if(err.response.status === 400){
                    history.push('/');
                }
                if(err.response.status === 500){
                    history.push('/not-found');
                }
                this.props.dispatch(commonActions.failure(actionTypes.PROJECT_INFO_FAILURE, err));
            });
        },err=>{
            this.props.dispatch(commonActions.failure(actionTypes.PROJECT_INFO_FAILURE, err));
        });
    }
    componentDidUpdate(prevProps, prevState){
      if(prevProps.match.params.action !== this.props.match.params.action){
        this._loadRequest().then(rq=>{
            this.props.dispatch(commonActions.success(actionTypes.PROJECT_INFO_SUCCESS, rq));
        },err=>{
            this.props.dispatch(commonActions.failure(actionTypes.PROJECT_INFO_FAILURE, err));
        });
      }
    }

    _loadCategories = ()=>{
        return new Promise((resolve, reject) => {
            this.props.dispatch(categoryAction.getAllCategory(resolve,reject));
        });
    }

    _loadRequest = () =>{
        return new Promise((resolve, reject) => {
            if(this.props.match.params.id !== null && this.props.match.params.id !== undefined){
                this.props.dispatch(categoryAction.getInfoProjectByID(this.props.match.params.id, resolve, reject));
            }else{
                resolve(null);
            }
        });
    }

    _loadProjectStatus = () => {
        return new Promise((resolve, reject)=>{
            this.props.dispatch(categoryAction.getProjectStatus(resolve, reject));
        });
    }

    _loadAllUsers = () => {
        return new Promise((resolve, reject)=>{
            this.props.dispatch(userAction.getActiveUsers(resolve, reject));
        });
    }
    render() {
     const { initialValues, projectItem, projectStatus, categories, usersActiveList, checkPermissionFeature } = this.props;
      const action = this.props.match.params.action;
      let canUpdateDetail;
      if(projectItem !== undefined && projectItem !== null){
          if(projectItem.canUpdateDetail !== undefined && projectItem.canUpdateDetail !== null){
              if(projectItem.canUpdateDetail){
                canUpdateDetail = projectItem.canUpdateDetail;
              }else{
                canUpdateDetail = false;
              }
          }
      }
      const view = checkPermissionFeature('Project_Category', 'view') && action === 'view';
      const update = (checkPermissionFeature('Project_Category', 'edit') || canUpdateDetail) && action === 'update';
      const add = checkPermissionFeature('Project_Category', 'add') && action === 'add';
        return (
            (view || update || add) ? <div>
                <ProjectMaster action={action} initialValues={initialValues} projectItem={projectItem} projectStatus={projectStatus} categories={categories} usersActiveList={usersActiveList} checkPermissionFeature={checkPermissionFeature} canUpdateDetail={canUpdateDetail}/>
                <ProjectDetail action={action} initialValues={initialValues} projectItem={projectItem} projectStatus={projectStatus} categories={categories} usersActiveList={usersActiveList} />
            </div>: <Container><div><h5 className="text-center">Bạn không có quyền truy cập trang này, vui lòng liên hệ admin!</h5></div></Container>
        )
    }
}

const loadingSelector = loadingUtility(['CATELOGORY_GETLISTS','PROJECT_STATUS', 'LIST_ACTIVEUSERS','PROJECT_INFO','PROJECT_TOWER_FAC_ZONE_MULTI','GET_PROJECT_TOWER_FAC_ZONE','PROJECT_TOWER_FAC_ZONE','GET_PROJECT_CONTRACTOR_TEAM_ENGINEER','PROJECT_CONTRACTOR_TEAM_ENGINEER','CATELOGORY_CRUD']);

const mapStateToProps = state => {
    return {
        isFetching: loadingSelector(state),
        initialValues: state.categoryReducer.projectItem,
        projectItem: state.categoryReducer.projectItem,
        projectStatus: state.categoryReducer.projectStatus,
        categories: state.categoryReducer.categories,
        usersActiveList: state.userReducer.usersActiveList
    }
}

export default compose(
    connect(mapStateToProps),
    commonWrapped({ showLoadingWholePage: true }),
    withRouter,
    withPermission()
)(ProjectPage);
