import React, {Component} from 'react';
import {compose} from 'redux';
import { connect } from 'react-redux';
import { Row,Col,Button,Container } from 'reactstrap';
import { reduxForm, reset, formValueSelector, Field } from 'redux-form';
import _ from 'lodash';
import { loadingUtility } from '../utility/loading';
import {dialogSelector} from '../utility/dialogSelector';
import {actionTypes} from '../constants/actionTypes';
import {categoryAction} from '../actions/categoryAction';
import {userAction} from '../actions/userAction';
import Pagination from '../controls/Pagination';
import {withRouter} from 'react-router-dom';
import commonWrapped from '../hocs/commonWrapped';
import UserFormModal from '../controls/userFormModal';
import ConfirmModal from '../controls/confirmModal';
import UserTable from '../presentations/UserTable';
import { commonActions } from '../actions/commonAction';
import renderInput from '../controls/renderInput';
import withPermission from '../hocs/withPermission';
import {permissionAction} from '../actions/permissionAction';
import {toastr} from 'react-redux-toastr'

const renderActionFilterForm = (rs, openFilter, props, fns) => {
  const {toggleFilter, onClickAddEditItem, checkPermissionFeature, handleChangeTextSearch,onClickImportUsers}=fns;
  return(
    <div className="filter-group">
        <div className="action-group">
            {openFilter && (
            // <Field name="textSearch" component={renderInput} autoFocus />
            <input type="text" name="textSearch" className="input-config" autoFocus onChange={e => handleChangeTextSearch(e)} />
            )}
            <Button color="info" type='button' onClick={e => {toggleFilter()}}><i className="fa fa-search" aria-hidden="true"></i></Button>
            {checkPermissionFeature('User', 'add') && <Button color="primary" type="button" onClick={(e)=>{onClickAddEditItem({})}}>{_.get(rs,'btnNames.addNew')}</Button>}
            {checkPermissionFeature('User', 'add') && <Button color="primary" type="button" onClick={(e)=>{onClickImportUsers({})}}>{_.get(rs,'btnNames.import')}</Button>}
        </div>
    </div>
  );
}

class UserListing extends Component {
    constructor (props, context){
        super(props, context);
        this.state={
            rs: _.get(window.ePayment,'resource'),
            selectedItem: null,
            isOpen: false,
            title: null,
            message: null,
            typeForm: null,
            pageOfItems: [],
            initialPage: 1,
            pageSize: 25,
            search: "",
            afterFilter: [],
            catalogName: null,
            originalList: null,
            openFilter: false,
            actionType: null,
            textSearch: null,
            isOpenImportUserToastr : false,
            run:false
        };
        this.onChangePage = this.onChangePage.bind(this);
        this.debouncedOnChange = _.debounce(this.debouncedOnChange.bind(this), 200);
    }

    componentDidUpdate(prevProps, prevState) {
        const catalogName = this.props.catalogName;
        if(prevState.openFilter !== this.state.openFilter && !this.state.openFilter){
            // this.props.dispatch({type: actionTypes.RESET_FILTER_FORM});
            this.props.dispatch(reset(this.props.form));
            
        }
        if(prevProps.catalogName !== catalogName){            
            this.setState({openFilter: false});
            this.props.dispatch(commonActions.request(actionTypes.USER_DATA_REQUEST));
            this.props.dispatch(reset(this.props.form));            
        }else{
            // this._trackingFilter(prevProps);
            this._trackingFilter(prevState);
        }
        //Kiểm tra import status - thành công thì show message + load lại list + set lại status = null để import lần 2
        if(prevProps.importStatus!== this.props.importStatus && this.props.importStatus==200){
            toastr.success('Thành Công', 'Thêm mới nhân viên thành công!');
            this.getAllUsers();
            this.props.dispatch(commonActions.request(actionTypes.UPDATE_IMPORT_STATUS));
        }
        //import lỗi

        if(prevProps.importStatus!== this.props.importStatus && this.props.importStatus != 200 && this.props.importStatus !=null){
            if(this.props.importMessages != null || this.props.importMessages != undefined)
            {
                if(this.props.importMessages.result == 'DuplicateUsers'){
                    var listUserNameErrDl=[];
                    for(let i=0;i<this.props.importMessages.list.length;i++){
                        listUserNameErrDl.push(this.props.importMessages.list[i]+" ") ;
                    }
                    toastr.error('Thất Bại', 'Tên đăng nhập: ' + listUserNameErrDl + ' đã tồn tại !');
                }
                else if(this.props.importMessages.result == 'ErrorRowInExcel'){
                    var listUserNameErrNull=[];
                    for(let i=0;i<this.props.importMessages.list.length;i++){
                        listUserNameErrNull.push(this.props.importMessages.list[i]) ;
                    }
                    toastr.error('Thất Bại', 'File Excel bị lỗi tại dòng : ' + listUserNameErrNull );
                }
                else{
                    toastr.error('Thất Bại', 'file Excel không đúng quy định !');
                }
            }else{
                toastr.error('Thất Bại', 'file Excel không đúng quy định !'); 
            } 
            
            this.props.dispatch(commonActions.request(actionTypes.UPDATE_IMPORT_STATUS));   
        }
        
    }
    componentDidMount(){
        this.getAllUsers();
    }
    componentWillReceiveProps(nextProps){
        const catalogName = this.props.catalogName;
        if (catalogName !== undefined && catalogName !== null && this.props.usersList !== null){
            let nextItems;
            if(nextProps.usersList !== null && nextProps.usersList !== undefined)
                nextItems = nextProps.usersList;
            if(!_.isEqual(nextItems, this.state.originalList)){
                //setState(updater[, callback]) is an async function:
                //You can execute a function after setState is finishing using the second param callback like:
                if(nextProps.actionType === 'add' ){
                  if(nextItems !== undefined) {this.setState({pageOfItems:[], originalList: nextItems}, () => {
                      this._dataAfterFilter();
                  });}
                }else{
                  if(nextItems !== undefined) {this.setState({initialPage: this.state.initialPage, originalList: nextItems}, () => {
                      this._dataAfterFilter();
                  });}
                }
            }
        }
    }

    loadAllUsers = (catalog)=>{
        return new Promise((resolve, reject) => {
            this.props.dispatch(userAction.getAllUsers(resolve,reject));
        });
    }

    getAllUsers = ()=>{
        const catalogName = this.props.catalogName;
        this.props.dispatch(reset(this.props.form));
            Promise.all([this.loadAllUsers()]).then(results=>{
                this.setState({catalogName: catalogName, initialPage: 1, pageOfItems:[], originalList:results[0]});
                this.props.dispatch({type: actionTypes.UPDATE_USERS_DATA, data: results});
                this.props.dispatch(commonActions.request(actionTypes.USER_DATA_SUCCESS, {results}));
                this._dataAfterFilter();
            },err=>{
                this.props.dispatch(commonActions.request(actionTypes.USER_DATA_FAIL, err));
            });
    }

    _handleChangeTypeAction = () => {
      this.props.handleChangeTypeAction();
    }

    _handleChangeTextSearch = (event) => {
        this.setState({textSearch: event.target.value});
    }

    // _trackingFilter = (prevProps) =>{
    _trackingFilter = (prevState) =>{
        // if(!_.isUndefined(prevProps.textSearch) && prevProps.textSearch !== this.props.textSearch){
        // if(prevProps.textSearch !== this.props.textSearch){
            if(prevState.textSearch !== this.state.textSearch){
          this._handleChangeTypeAction();
          this._dataAfterFilter();
        }
    }

    isMatch(txtField, txtSearch){
        txtSearch=txtSearch.toLowerCase();
        if (txtField !== null && txtField !== undefined && (_.isMatch(txtField.toLowerCase(),txtSearch) || txtField.toLowerCase().indexOf(txtSearch) >= 0))
            return true;
        return false;
    }

    _dataAfterFilter = () =>{
        let dataAfterFilter = this.state.originalList;
        const { openFilter} = this.state;
        let textSearch = this.state.textSearch;
        //textSearch = textSearch?_.escapeRegExp(textSearch).trim().toLowerCase():'';
        if(this.props.catalogName && openFilter&& textSearch !== ''){
            dataAfterFilter = this.state.originalList && this.state.originalList.filter((item) =>{
                if (this.isMatch(item.userName,textSearch) || this.isMatch(item.fullName,textSearch) || this.isMatch(item.email,textSearch))
                  return item;
            });
        }
        this.debouncedOnChange(dataAfterFilter);
    }
    debouncedOnChange(dataAfterFilter) {
      if(this.props.actionType === 'add' ){
        // this.setState({dataAfterFilter, initialPage:1});
        this.setState({dataAfterFilter});
      }else if(this.props.actionType === 'update' || this.props.actionType === 'delete'){
        this.setState({dataAfterFilter});
      }else{
        this.setState({dataAfterFilter, initialPage:1});
      }
    }

    _toggleFilter=()=>{
        this.setState({
            openFilter: !this.state.openFilter
        });
    }

    toggleFn = () =>{
        this.setState({isOpen: !this.state.isOpen});
    }
    okFn = (data)=>{
        this.props.handleFormUser('delete');
        this.props.dispatch(categoryAction.submitCategory({type_action : 'delete', catalogName: this.props.catalogName}, data));
    }

    toggleCategoryFormFn = () =>{
        if(this.props.isOpenCategoryForm){
            this.props.dispatch(commonActions.closeDialog());
        }else{
            this.props.dispatch(commonActions.showDialog());
        }
    }

    _toggleFilter=()=>{
        this.setState({
            openFilter: !this.state.openFilter
        });
    }

    onClickAddEditItem = (item) => {
        if(!_.isUndefined(item.id) && item.id.length > 0){
            this.props.handleFormUser('update');
            this.setState({typeForm: 'update'});
        }else{
            this.props.handleFormUser('add');
            this.setState({typeForm: 'add'});
            this.props.dispatch(reset('userForm'));
        }
        this.props.dispatch({
            type: actionTypes.USER_ITEM_SELECTED,
            data: item
        });
        this.toggleCategoryFormFn();
    }

    onClickResetPasswordItem= (item) =>{
        this.props.dispatch({
            type: actionTypes.USER_ITEM_SELECTED,
            data: item
        });
        this.props.handleFormUser('reset');
        this.setState({typeForm: 'reset'});
        this.props.dispatch(reset('userForm'));
        this.toggleCategoryFormFn();
        // if(this.props.isOpenResetPasswordModal){
        //     this.props.dispatch(commonActions.closeDialog());
        // }else{
        //     this.props.dispatch(commonActions.showDialog());
        // } 
    }

    onChangePage(pageOfItems,page) {
        this.setState({ pageOfItems: pageOfItems, initialPage : page});
    }
    onClickImportUsers =()=>{
        this.props.handleFormUser('import');
        this.setState({typeForm: 'import'});
        this.props.dispatch(reset('userForm'));
        this.toggleCategoryFormFn();
    }

    render(){
        const { openFilter } = this.state;
        const {catalogName, checkPermissionFeature} = this.props;
        const rs = _.get(window.ePayment,'resource');
        return(
            checkPermissionFeature('User', 'view') ? <Container fluid>
                {renderActionFilterForm(rs, openFilter, {...this.props}, {
                  toggleFilter: this._toggleFilter,
                  onClickAddEditItem: this.onClickAddEditItem,
                  onClickResetPasswordItem: this.onClickResetPasswordItem,
                  onClickImportUsers:this.onClickImportUsers,
                  checkPermissionFeature: checkPermissionFeature,
                  handleChangeTextSearch: this._handleChangeTextSearch
                })}
                <Row>
                    <Col md='12'>
                    <p className="p_download"><a href="http://103.48.190.9:8010/templates/COFICO_User_ImportTemplate.xlsx">Tải mẫu excel để tạo nhân viên hàng loạt</a></p>
                    </Col>
                </Row>
                <Row>
                    <Col md='12'>
                        {this.props.isOpenCategoryForm && (
                            <UserFormModal
                                props={this.props}
                                dispatch={this.props.dispatch}
                                catalogName={catalogName}
                                violationParents={this.props.violationParents}
                                punishmentParents={this.props.punishmentParents}
                                isOpen={this.props.isOpenCategoryForm}
                                toggleFn={this.toggleCategoryFormFn}
                                typeForm={this.state.typeForm}/>
                        )}

                        <UserTable catalogName={catalogName} categoryList={this.state.pageOfItems} editItemFn={this.onClickAddEditItem} resetPasswordItemFn={this.onClickResetPasswordItem}  checkPermissionFeature={checkPermissionFeature}/>
                        {(this.state.dataAfterFilter !== null && this.state.dataAfterFilter !== undefined ) &&
                        <Pagination initialPage={this.state.initialPage} pageSize={this.state.pageSize} items={this.state.dataAfterFilter} onChangePage={this.onChangePage} />}

                    </Col>
                </Row>
            </Container> : <Container><div><h5 className="text-center">Bạn không có quyền truy cập trang này, vui lòng liên hệ admin!</h5></div></Container>
        );
    }
}

const loadingSelector = loadingUtility(['USER_DATA']);
const selector = formValueSelector('userForm');
const dlgSelector = dialogSelector(['DIALOG'])
const mapStateToProps = state => {
    return {
        isFetching: loadingSelector(state),
        usersList: state.userReducer.usersList,
        importStatus:state.userReducer.importStatus,
        importMessages:state.userReducer.importMessages,
        initialValues: state.userReducer.selectedItem,
        isOpenCategoryForm: dlgSelector(state),
        isOpenResetPasswordModal: dlgSelector(state),
        textSearch: selector(state, 'textSearch'),
        importrun2:state.userReducer.importrun2,
    }
}

const mapDispatchToProps = (dispatch,props) => ({
    onSubmit: values => {
        let alter_props = props;
        let alterValues = values;
        if(alter_props.actionType == "import"){
            alter_props  = {...props, type_action : 'import'};
            dispatch(userAction.importUsers(alter_props, alterValues));
        }

        if(!_.isUndefined(values.id) && values.id.length > 0 && alter_props.actionType == "reset"){
            alter_props = {...props, type_action : 'reset'};
            dispatch(userAction.resetUserPassword(alter_props, alterValues.userName)); 
        }

        if(alter_props.actionType == "update" || alter_props.actionType == "add"){
            if(!_.isUndefined(values.id) && values.id.length > 0){
                alter_props = {...props, type_action : 'update'};
                dispatch(userAction.updateUser(alter_props, alterValues));
            }else{
                alter_props  = {...props, type_action : 'add'};
                if(_.isUndefined(alterValues.UseDefaultPassword) && (alterValues.password != null || _.isUndefined(alterValues.password) )){
                    alterValues.UseDefaultPassword = true;
                }
                dispatch(userAction.register(alter_props, alterValues));
            }
        }
        
        
        

    }
});


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'userForm',
        enableReinitialize : true, // this is needed to refresh initial-values!!,
    }),
    commonWrapped({showLoadingWholePage: true}),
    withRouter,
    withPermission()
)(UserListing);
