import React from 'react';
import PropTypes from 'prop-types';
import {FieldProps} from './FieldProps';
import {inputError} from './inputError';
import { DropdownList } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';


const renderDropdownList = ({input, val, data, groupBy, defaultValue, valueField, textField, disabled, dropUp, filter, meta, showError, showWarn, ...rest}) =>{
    return (
        <DropdownList {...input}
            data={data}
            valueField={valueField}
            textField={textField}
            groupBy={groupBy?groupBy:''}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={input.onChange}
            dropUp={dropUp}
            filter={filter}
            className={`${meta.error ? ' is-invalid' : ''}`}/>
    );
}


renderDropdownList.propTypes = {
    groupBy: PropTypes.string,
    ...FieldProps
};

export default inputError(renderDropdownList);
