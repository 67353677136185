import React from 'react';
import {compose} from 'redux';
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from 'redux-form';
import _ from 'lodash';
import { Row, Col, Button, Container } from 'reactstrap';
import { userAction } from '../actions/userAction';
import { loadingUtility } from '../utility/loading';
import renderInput from '../controls/renderInput';
import commonWrapped from '../hocs/commonWrapped';

class ChangePassPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            changeSuccess: null
        }
    }
    submitAction = (values) =>{
        const self = this;
        this.setState({changeSuccess: null});
        return this.props.onSubmit(values, this.props.dispatch).then(result=>{
            this.setState({changeSuccess: 'Thành công!'});
            setTimeout(function(){
                self.props.dispatch(userAction.logout())
            },1000);
        },err=>{
            throw new SubmissionError({
                _error: _.get(window.ePayment,'resource.changePassForm.errMessage')
            });
        })
    }
    render(){
        const { handleSubmit, submitting, error } = this.props;
        const rs = _.get(window.ePayment,'resource.changePassForm');
        return(
            <div className="main-box">
              <h5 className="page-header text-center">
                {_.get(rs,'title')}
              </h5>
              <div className="change-pass">
                  <div className="center">
                    <Container fluid>
                      <form onSubmit={handleSubmit(this.submitAction)}>
                          <Row>
                              <Col xs={4} sm={{ size: 4, offset: 4 }}>
                                  <Field
                                      placeholder={_.get(rs,'oldPass')}
                                      name="oldPass"
                                      component={renderInput}
                                      type="password"/>
                              </Col>
                          </Row><br />
                          <Row>
                              <Col xs={4} sm={{ size: 4, offset: 4 }}>
                                  <Field
                                      name="newPass"
                                      component={renderInput}
                                      placeholder={_.get(rs,'newPass')}
                                      type="password"/>
                              </Col>
                          </Row><br />
                          <Row>
                              <Col xs={4} sm={{ size: 4, offset: 4 }}>
                                  <Field
                                      name="confirmPassword"
                                      component={renderInput}
                                      placeholder={_.get(rs,'confirmPassword')}
                                      type="password"/>
                              </Col>
                          </Row><br />
                          <Row>
                              <Col xs={12} sm={12} className="text-center login-action">
                                  {error && <div className="text-danger"><strong>{error}</strong></div> }
                                  {this.state.changeSuccess && <div className="text-success"><strong>{this.state.changeSuccess}</strong></div> }<br/>
                                  <Button color="primary" type="submit" className='btn btn-outline-default btn-sm btn-submit' disabled={submitting}>{_.get(rs,'submitBtn')}</Button>
                              </Col>
                          </Row>
                      </form>
                      </Container>
                  </div>
              </div>
            </div>
        )
    }
}

const loadingSelector = loadingUtility(['USER_LOGIN']);
const mapStateToProps = (state) => ({ isFetching: loadingSelector(state) });
const mapDispatchToProps = dispatch => ({
    onSubmit: values => new Promise((resolve, reject)=>{
        dispatch(userAction.changePassword(values.oldPass, values.newPass, values.confirmPassword, resolve, reject));
    })
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'ChangePassForm' // a unique identifier for this form
    }),
    commonWrapped({showLoadingWholePage:true})
)(ChangePassPage);
