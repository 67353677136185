import React, {PropTypes} from 'react';
import {FieldProps} from './FieldProps';
import {inputError} from './inputError';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
const renderDatePicker = ({input, meta, showError, showWarn, ...rest}) =>{
	return (
            <DatePicker  {...input}
            //placeholder={placeholder}
            dateForm="DD-MM-YYYY"
            //selected={input.value ? moment(input.value) : null}
            onChange={(date)=>{
                input.onChange(moment(date).format('DD-MM-YYYY'));
            }}
            className={`form-control input-config ${showError && meta.touched && meta.error ? ' is-invalid' : ''}`}
            />
		);
}

renderDatePicker.propTypes = {
	...FieldProps
};

export default inputError(renderDatePicker);
