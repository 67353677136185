import React from 'react';
import _ from 'lodash';
import { FormGroup, Label, Container,Row, Col } from 'reactstrap';
import { Field} from 'redux-form';
import renderInput from '../controls/renderInput';
import renderCheckbox from '../controls/renderCheckbox';
import renderDropdownList from '../controls/renderDropdownList';
import renderTextarea from '../controls/renderTextarea';
import validate from '../validate/validate';

const HazardousForm = props => {
    const rs = _.get(window.ePayment,'resource');
    return(
        <Container fluid>
            <Row>
                {/* <Col md='12'>
                    <FormGroup>
                        <Label>{_.get(rs,'hazardCategory.code')}</Label>
                        <Field name="hazardCode" component={renderInput}/>
                    </FormGroup>
                </Col> */}
                <Col md='12'>
                    <FormGroup>
                        <Label>{_.get(rs,'hazardCategory.name')}</Label>
                        <Field name="hazardName" validate={validate.required} component={renderInput}/>
                    </FormGroup>
                </Col>
                <Col md='12'>
                        <FormGroup>
                            <Label>{_.get(rs,'categories.parrent')}</Label>
                            <Field name="parentID"
                                    component={renderDropdownList}
                                    valueField ='id'
                                    textField ='hazardName'
                                    defaultValue={{}}
                                    data={props.hazardousParents !== null ? props.hazardousParents : []}/>
                        </FormGroup>
                    </Col>
                    <Col md='12'>
                        <FormGroup>
                            <Label>Biện pháp kiểm soát</Label>
                            <Field name="riskControl" type='text' component={renderTextarea}/>
                        </FormGroup>
                    </Col>
                <Col md='6'>
                    <FormGroup>
                        <Label>{_.get(rs,'categories.sortorder')}</Label>
                        <Field name="sortOrder" validate={[validate.number, validate.integer]} component={renderInput}/>
                    </FormGroup>
                </Col>
                <Col md='6'>
                    <FormGroup>
                        <Label>{_.get(rs,'categories.active')}</Label>
                        <Field name="isActive" type='checkbox' component={renderCheckbox}/>
                    </FormGroup>
                </Col>
            </Row>
        </Container>
    )
}

export default HazardousForm;
