import {toastr} from 'react-redux-toastr';
import { actionTypes } from '../constants/actionTypes';
import { commonActions } from './commonAction';
import { history } from '../utility/history';

import projectService from '../services/projectService';
import accidentService from '../services/accidentService';
import contractorService from '../services/contractorService';
import imageService from '../services/imageService';
import trainingService from '../services/trainingService';
import violationService from '../services/violationService';
import punishmentService from '../services/punishmentService';
import hazardousService from '../services/hazardousService';
import teamService from '../services/teamService';
import { debug } from 'util';

const fnApi={
    project:{
        get: projectService.get,
        gets: projectService.gets,
        add: projectService.add,
        update: projectService.update,
        delete: projectService.remove
    },
    accident:{
        get: accidentService.get,
        gets: accidentService.gets,
        add: accidentService.add,
        update: accidentService.update,
        delete: accidentService.remove
    },
    contractor:{
        get: contractorService.get,
        gets: contractorService.gets,
        getActiveItems: contractorService.getActiveItems,
        add: contractorService.add,
        update: contractorService.update,
        delete: contractorService.remove
    },
    image:{
        get: imageService.get,
        gets: imageService.gets,
        add: imageService.add,
        update: imageService.update,
        delete: imageService.remove
    },
    training:{
        get: trainingService.get,
        gets: trainingService.gets,
        add: trainingService.add,
        update: trainingService.update,
        delete: trainingService.remove
    },
    violation:{
        get: violationService.get,
        gets: violationService.gets,
        add: violationService.add,
        update: violationService.update,
        delete: violationService.remove,
        getParentItems: violationService.getParentItems
    },
    punishment:{
        get: punishmentService.get,
        gets: punishmentService.gets,
        add: punishmentService.add,
        update: punishmentService.update,
        delete: punishmentService.remove,
        getParentItems: punishmentService.getParentItems
    },
    hazardous:{
        get: hazardousService.get,
        gets: hazardousService.gets,
        add: hazardousService.add,
        update: hazardousService.update,
        delete: hazardousService.remove,
        getParentItems: hazardousService.getParentItems
    },
    team:{
        get: teamService.get,
        gets: teamService.gets,
        add: teamService.add,
        update: teamService.update,
        delete: teamService.remove
    }
};

const lstCategory = ['contractor', 'team'];

export const categoryAction = {
    submitCategory,
    getCategoryList,
    getParentItems,
    getInfoProjectByID,
    getProjectStatus,
    getAllCategory,
    getProjectTowerFacZone,
    submitProjectTowerFacZone,
    deleteProjectTowerFacZone,
    getContractorByProject,
    updateContractorToProject,
    getTeamByContractor,
    updateTeamToContractor,
    getUserByTeam,
    updateUserToTeam,
    getProjectByEngineer,
    //getContractorByProjectReport,
    getAllContractor,
    getAllTeam,
    //getTeamByContractorReport,
    getTowerFacZoneByProjectReport,
    //getNullTower,
    getEngineers,
    hazardousReport,
    towerFacZonePutMulti,
    getViolationCategoryType
};

//===========================
//===== GLOBAL CATEGORY =====
//===========================

function getKindCategoryList(action, type_action, catalog_name, obj) {
    return {
        type: action,
        data:{
            catalog_name, type_action, obj
        }
    }
}

function getKindCategoryListParents(action, type_action, catalog_name, obj, itemSelected) {
    return {
        type: action,
        data:{
            catalog_name, type_action, obj, itemSelected
        }
    }
}

function getParentItems(catalogName, type_action, itemSelected) {
    return dispatch => {
        dispatch(commonActions.request(actionTypes.CATELOGORY_GETPARENTLIST_REQUEST));
        fnApi[catalogName][type_action]().then(
            obj => {
                dispatch(getKindCategoryListParents(actionTypes.CATELOGORY_GETPARENTLIST_SUCCESS, type_action, catalogName, obj, itemSelected));
                //dispatch(commonActions.success(actionTypes.CATELOGORY_GETPARENTLIST_SUCCESS, obj));
                // toastr.success('The title', 'get parent list');
            },
            error => {
                dispatch(commonActions.failure(actionTypes.CATELOGORY_GETPARENTLIST_FAILURE, error));
            }
        );
    };
}

function getCategoryList(catalogName, type_action, resolve,reject) {
    return dispatch => {
        fnApi[catalogName][type_action]().then(
            obj => {
                if(resolve)
                    resolve(obj);
                else{
                    dispatch(getKindCategoryList(actionTypes.CATELOGORY_GETLIST_SUCCESS, type_action, catalogName, obj));
                }
            },
            error => {
                reject&&reject(error);
            }
        );
    };
}

function submitCatagorySuccess(action, type_action, catalog_name, request, response) {
    return {
        type: action,
        data:{
            catalog_name, type_action, request, response,
        }
    }
}

function submitCategory(props, data) {
    return dispatch =>  {
        dispatch(commonActions.request(actionTypes.CATELOGORY_CRUD_REQUEST, data));
        let type_action = props.type_action;
        fnApi[props.catalogName.toLocaleLowerCase()][type_action](data).then(obj => {
            if(props.catalogName === 'project' && (type_action === 'add' || type_action === 'update')){
                history.push(`/danh-muc/du-an/update/${obj.id}`);
                dispatch({
                    type: actionTypes.PROJECT_INFO_SUCCESS,
                    data: data
                })
            }
            // Load list whhen add new item
            else if (props.catalogName !== 'project' && type_action === 'add'){
                dispatch(getCategoryList(props.catalogName, 'gets', null , null));
            }else{
                dispatch(submitCatagorySuccess(actionTypes.CATELOGORY_CRUD_SUCCESS, type_action, props.catalogName, data, obj));
            }
            toastr.success('Successful!', 'Thành công!');
            dispatch(commonActions.failure(actionTypes.CATELOGORY_CRUD_SUCCESS_CLOSE_WAITING, obj));
            dispatch(commonActions.closeDialog());
        },
        error => {
            handleErr(error.response.data.content.items.result);
            dispatch(commonActions.failure(actionTypes.CATELOGORY_CRUD_FAILURE, error));
        });
    };
}

//===========================
//===== PROJECT CATEGORY ====
//===========================

function towerFacZonePutMulti(props, number, data){
    return dispatch => {
        dispatch(commonActions.request(actionTypes.PROJECT_TOWER_FAC_ZONE_MULTI_REQUEST, data));
        projectService.towerFacZonePutMulti(number, data).then(
            obj => {
                dispatch(getProjectTowerFacZone(props, data));
                dispatch(commonActions.success(actionTypes.PROJECT_TOWER_FAC_ZONE_MULTI_SUCCESS, obj));
            },
            error => {
                // handleErr(error.response.data.content.items.result);
                dispatch(commonActions.failure(actionTypes.PROJECT_TOWER_FAC_ZONE_MULTI_FAILURE, error));
            }
        );
    }
}

function submitProjectSuccess(action, type_action, idxItem, request, response) {
    return {
        type: action,
        data:{
            idxItem, type_action, request, response,
        }
    }
}

function getInfoProjectByID(id, resolve, reject){
    return dispatch => {
        projectService.getInfoProjectByID(id).then(
            obj => {
                dispatch({type: actionTypes.PROJECT_INFO_SUCCESS, data: obj})
                resolve(obj);
            },
            error => {
                reject(error);
            }
        );
    };
}

function getProjectStatus(resolve, reject){
    return dispatch => {
        projectService.getProjectStatus().then(
            obj => {
                dispatch(commonActions.success(actionTypes.PROJECT_STATUS_SUCCESS, obj));
                resolve(resolve);
            },
            error => {
                dispatch(commonActions.failure(actionTypes.PROJECT_STATUS_FAILURE, error));
                reject(error);
            }
        );
    };
}

function getAllCategory(resolve, reject){
    let arrFn = [];
    lstCategory.forEach(category => {
        const tmp = category === 'contractor' ? 'getActiveItems' : 'gets';
        arrFn.push(fnApi[category][tmp]());
    });
    return dispatch =>{
        Promise.all(arrFn).then(results=>{
            let objResults=[];
            lstCategory.forEach(function(category,idx) {
                let altResult = Object.assign({}, {catalogName: category, data: results[idx]});
                objResults.push(altResult);
            });
            dispatch({type: actionTypes.CATELOGORY_GETLISTS_SUCCESS, data: objResults});
            resolve(objResults);
        },err=>{
            reject(err);
        })
    };
}

function getProjectTowerFacZone(props, data){
    let type_action = props.type_action;
    let idxItem = props.idxItem;
    return dispatch => {
        dispatch(commonActions.request(actionTypes.GET_PROJECT_TOWER_FAC_ZONE_REQUEST));
        projectService.getProjectTowerFacZone(data).then(
            obj => {
                dispatch(submitProjectSuccess(actionTypes.GET_PROJECT_TOWER_FAC_ZONE_SUCCESS, type_action, idxItem, data, obj));
                // dispatch(commonActions.success(actionTypes.GET_PROJECT_TOWER_FAC_ZONE_SUCCESS, obj));
            },
            error => {
                dispatch(commonActions.failure(actionTypes.GET_PROJECT_TOWER_FAC_ZONE_FAILURE, error));
            }
        );
    };
}

function submitProjectTowerFacZone(props, data){
    let type_action = props.type_action;
    let idxItem = props.idxItem;
    return dispatch => {
        dispatch(commonActions.request(actionTypes.PROJECT_TOWER_FAC_ZONE_REQUEST, data));
        projectService.submitProjectTowerFacZone(data).then(
            obj => {
              dispatch(submitProjectSuccess(actionTypes.PROJECT_TOWER_FAC_ZONE_SUCCESS, type_action, idxItem, data, obj));
              // dispatch(commonActions.success(actionTypes.PROJECT_TOWER_FAC_ZONE_SUCCESS, obj));
              toastr.success('Successful!', 'Thành công!');
            },
            error => {
                handleErr(error.response.data.content.items.result);
                dispatch(commonActions.failure(actionTypes.PROJECT_TOWER_FAC_ZONE_FAILURE, error));
            }
        );
    }
}

function deleteProjectTowerFacZone(props, data){
    let type_action = props.type_action;
    let idxItem = props.idxItem;
    return dispatch => {
        dispatch(commonActions.request(actionTypes.PROJECT_TOWER_FAC_ZONE_REQUEST, data));
        projectService.deleteProjectTowerFacZone(data).then(
            obj => {
              dispatch(submitProjectSuccess(actionTypes.PROJECT_TOWER_FAC_ZONE_SUCCESS, type_action, idxItem, data, obj));
              // dispatch(commonActions.success(actionTypes.PROJECT_TOWER_FAC_ZONE_SUCCESS, obj));
              toastr.success('Successful!', 'Thành công!');
            },
            error => {
                handleErr(error.response.data.content.items.result);
                dispatch(commonActions.failure(actionTypes.PROJECT_TOWER_FAC_ZONE_FAILURE, error));
            }
        );
    }
}

function getContractorByProject(props, data){
    let type_action = props.type_action;
    let idxItem = props.idxItem;
    return dispatch => {
        dispatch(commonActions.request(actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_REQUEST));
        projectService.getContractorByProject(data).then(
            obj => {
                dispatch(submitProjectSuccess(actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, type_action, idxItem, data, obj));
                // dispatch(commonActions.success(actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, obj));
            },
            error => {
                dispatch(commonActions.failure(actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_FAILURE, error));
            }
        );
    };
}

function updateContractorToProject(props, data){
    let type_action = props.type_action;
    let idxItem = props.idxItem;
    return dispatch => {
        dispatch(commonActions.request(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_REQUEST, data));
        projectService.updateContractorToProject(data).then(
            obj => {
                
                if(type_action === 'delete') {
                    // dispatch(submitProjectSuccess(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, type_action, idxItem, data, obj));
                    dispatch(submitProjectSuccess(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, type_action, idxItem, data[0], obj));
                }else{
                    if(data.length > 0) dispatch(getContractorByProject(props, data[0]));
                    dispatch(commonActions.success(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, obj));
                }
                toastr.success('Successful!', 'Thành công!');
            },
            error => {
              handleErr(error.response.data.content.items.result);
                dispatch(commonActions.failure(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_FAILURE, error));
            }
        );
    }
}

function getTeamByContractor(props, data){
    let type_action = props.type_action;
    let idxItem = props.idxItem;
    return dispatch => {
        dispatch(commonActions.request(actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_REQUEST));
        projectService.getTeamByContractor(data).then(
            obj => {
                dispatch(submitProjectSuccess(actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, type_action, idxItem, data, obj));
                // dispatch(commonActions.success(actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, obj));
            },
            error => {
                dispatch(commonActions.failure(actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_FAILURE, error));
            }
        );
    };
}

function updateTeamToContractor(props, data){
    let type_action = props.type_action;
    let idxItem = props.idxItem;
    return dispatch => {
        dispatch(commonActions.request(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_REQUEST, data));
        projectService.updateTeamToContractor(data).then(
            obj => {
                if(type_action === 'delete') {
                    // dispatch(submitProjectSuccess(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, type_action, idxItem, data, obj));
                    dispatch(submitProjectSuccess(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, type_action, idxItem, data[0], obj));
                }else{
                    if(data.length > 0) dispatch(getTeamByContractor(props, data[0]));
                    dispatch(commonActions.success(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, obj));
                }
              toastr.success('Successful!', 'Thành công!');
            },
            error => {
              handleErr(error.response.data.content.items.result);
                dispatch(commonActions.failure(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_FAILURE, error));
            }
        );
    }
}

function getUserByTeam(props, data){
    let type_action = props.type_action;
    let idxItem = props.idxItem;
    return dispatch => {
        dispatch(commonActions.request(actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_REQUEST));
        projectService.getUserByTeam(data).then(
            obj => {
                dispatch(submitProjectSuccess(actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, type_action, idxItem, data, obj));
                // dispatch(commonActions.success(actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, obj));
            },
            error => {
                dispatch(commonActions.failure(actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_FAILURE, error));
            }
        );
    };
}

function updateUserToTeam(props, data){
    let type_action = props.type_action;
    let idxItem = props.idxItem;
    return dispatch => {
        dispatch(commonActions.request(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_REQUEST, data));
        projectService.updateUserToTeam(data).then(
            obj => {
                if(type_action === 'delete') {
                    // dispatch(submitProjectSuccess(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, type_action, idxItem, data, obj));
                    dispatch(submitProjectSuccess(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, type_action, idxItem, data[0], obj));
                }else{
                    if(data.length > 0) dispatch(getUserByTeam(props, data[0]));
                    dispatch(commonActions.success(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS, obj));
                }
              toastr.success('Successful!', 'Thành công!');
            },
            error => {
                handleErr(error.response.data.content.items.result);
                dispatch(commonActions.failure(actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_FAILURE, error));
            }
        );
    }
}

function handleErr(error){
  switch(error) {
      case "ItemNotFound":
          toastr.error('Error', 'Không thể tìm thấy mục này. Có vẻ như bạn không có đủ quyền hoặc mục này đã bị xóa.');
          break;
      case "NotAllowed":
          toastr.error('Error', 'Bạn không có đủ quyền.');
          break;
      case "RowVersionChanged":
          toastr.error('Error', 'Phiên bản hàng đã được thay đổi.');
          break;
      case "ItemExists":
          toastr.error('Error', 'Mục này tồn tại.');
          break;
      case "ImageFolderNotExist":
          toastr.error('Error', 'Hình ảnh thư mục không tồn tại.');
          break;
      case "ItemInUse":
          toastr.error('Error', 'Không thể xóa mục này. Nó đã được sử dụng.');
          break;
      case "ItemIsNull":
          toastr.error('Error', 'Mục này là null.');
          break;
      case "StatusWrong":
          toastr.error('Error', 'Trường "trạng thái" không được định cấu hình chính xác.');
          break;
      default:
          break;
  }
}

//===========================
//===== FILTER REPORT =======
//===========================

function getEngineers(resolve, reject) {
    return dispatch => {
        projectService.getEngineers()
            .then(
                user => {
                    dispatch(commonActions.success(actionTypes.GET_ENGINEERS_SUCCESS, user));
                    resolve(resolve);
                },
                error => {
                    dispatch(commonActions.failure(actionTypes.GET_ENGINEERS_FAILURE, error));
                    reject(error);
                }
            );
    };
}

function getProjectByEngineer(data, resolve, reject){
  return dispatch => {
    projectService.getProjectByEngineer(data).then(
        obj => {
            dispatch({type: actionTypes.GET_PROJECT_BY_USER_SUCCESS, data: obj})
            resolve(obj)
        },
        error => {
            dispatch(commonActions.failure(actionTypes.GET_PROJECT_BY_USER_FAILURE, error));
            reject(error)
        }
    );
  }
}

// function getContractorByProjectReport(data, resolve, reject){
//     return dispatch => {
//         dispatch(commonActions.request(actionTypes.GET_CONTRACTOR_BY_PROJECT_REQUEST));
//         projectService.getContractorByProjectReport(data, resolve, reject).then(
//             obj => {
//                 dispatch({type: actionTypes.GET_CONTRACTOR_BY_PROJECT_SUCCESS, data: obj});
//                 resolve(obj)
//             },
//             error => {
//                 dispatch(commonActions.failure(actionTypes.GET_CONTRACTOR_BY_PROJECT_FAILURE, error));
//                 reject(error)
//             }
//         );
//     };
// }

function getAllContractor(resolve, reject){
    return dispatch => {
        dispatch(commonActions.request(actionTypes.GET_ALL_CONTRACTOR_REQUEST));
        projectService.getAllContractor(resolve, reject).then(
            obj => {
                dispatch({type: actionTypes.GET_ALL_CONTRACTOR_SUCCESS, data: obj});
                resolve(obj)
            },
            error => {
                dispatch(commonActions.failure(actionTypes.GET_ALL_CONTRACTOR_FAILURE, error));
                reject(error)
            }
        );
    };
}

function getAllTeam(resolve, reject){
    return dispatch => {
        dispatch(commonActions.request(actionTypes.GET_ALL_TEAM_REQUEST));
        teamService.getAllTeam(resolve, reject).then(
            obj => {
                dispatch({type: actionTypes.GET_ALL_TEAM_SUCCESS, data: obj});
                resolve(obj)
            },
            error => {
                dispatch(commonActions.failure(actionTypes.GET_ALL_TEAM_FAILURE, error));
                reject(error)
            }
        );
    };
}
// function getTeamByContractorReport(data){
//     return dispatch => {
//         dispatch(commonActions.request(actionTypes.GET_TEAM_BY_CONTRACTOR_REQUEST));
//         projectService.getTeamByContractorReport(data).then(
//             obj => {
//                 dispatch({type: actionTypes.GET_TEAM_BY_CONTRACTOR_SUCCESS, data: obj});
//             },
//             error => {
//                 dispatch(commonActions.failure(actionTypes.GET_TEAM_BY_CONTRACTOR_FAILURE, error));
//             }
//         );
//     };
// }

// function getNullTower(data, type){
//     return dispatch => dispatch({type: actionTypes.GET_NULL_TOWER_SUCCESS});
// }

function getTowerFacZoneByProjectReport(data, type){
    return dispatch => {
        if(type === 'tower') dispatch(commonActions.request(actionTypes.GET_TOWER_BY_PROJECT_REQUEST));
        if(type === 'level') dispatch(commonActions.request(actionTypes.GET_LEVEL_BY_PROJECT_TOWER_REQUEST));
        if(type === 'zone') dispatch(commonActions.request(actionTypes.GET_ZONE_BY_PROJECT_LEVEL_REQUEST));
        projectService.getProjectTowerFacZone(data).then(
            obj => {
                if(type === 'tower') dispatch({type: actionTypes.GET_TOWER_BY_PROJECT_SUCCESS, data: obj});
                if(type === 'level') dispatch({type: actionTypes.GET_LEVEL_BY_PROJECT_TOWER_SUCCESS, data: obj});
                if(type === 'zone') dispatch({type: actionTypes.GET_ZONE_BY_PROJECT_LEVEL_SUCCESS, data: obj});
            },
            error => {
                if(type === 'tower') dispatch(commonActions.failure(actionTypes.GET_TOWER_BY_PROJECT_FAILURE, error));
                if(type === 'level') dispatch(commonActions.failure(actionTypes.GET_LEVEL_BY_PROJECT_TOWER_FAILURE, error));
                if(type === 'zone') dispatch(commonActions.failure(actionTypes.GET_ZONE_BY_PROJECT_LEVEL_FAILURE, error));
            }
        );
    };
}

function hazardousReport(data, resolve, reject) {
  return dispatch => {
      dispatch(commonActions.request(actionTypes.REPORT_HAZAROUS_REQUEST, data));
      hazardousService.report(data).then(
          obj => {
            dispatch({type: actionTypes.REPORT_HAZAROUS_SUCCESS, data: obj});
            resolve(obj);
          },
          error => {
              dispatch(commonActions.failure(actionTypes.REPORT_HAZAROUS_FAILURE, error));
              reject(error);
          }
      );
  }
}

function getViolationCategoryType(){
    return dispatch => {
        dispatch(commonActions.request(actionTypes.GET_VIOLATION_CATEGORY_TYPE_REQUEST));
        violationService.getViolationCategoryType()
            .then(
                res => {
                    dispatch(commonActions.success(actionTypes.GET_VIOLATION_CATEGORY_TYPE_SUCCESS, res));
                },
                error => {
                    dispatch(commonActions.failure(actionTypes.GET_VIOLATION_CATEGORY_TYPE_FAILURE, error));
                }
            );
    };
}
