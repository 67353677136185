import React, {Component} from 'react';
import { Row, Col, Label, FormGroup, TabContent, TabPane, Nav, NavItem, NavLink, Button, Container} from 'reactstrap';
import { Field, FieldArray, reduxForm, formValueSelector, reset} from 'redux-form';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {permissionAction} from '../actions/permissionAction';
import {userAction} from '../actions/userAction';
import { loadingUtility } from '../utility/loading';
import {compose} from 'redux';
import commonWrapped from '../hocs/commonWrapped';
import renderPermission from '../controls/renderPermission';
import AddUserInGroupPerModal from '../controls/addUserInGroupPerModal';
import _ from 'lodash';
import { commonActions } from '../actions/commonAction';
import { actionTypes } from '../constants/actionTypes';
import renderInput from '../controls/renderInput';
import withPermission from '../hocs/withPermission';


class PermissionPage extends Component {
    constructor (props, context) {
        super(props,context);
        this.state={
            activeTab: '0',
            isOpen: false,
            title: null,
            selectGroup: null,
            groupAdminId:null,
            groupUserId:null,
            selectUser: null,
            openFilter: false,
            groupSelected: null
        }
        this.toggleTab = this.toggleTab.bind(this);
        this.toggleGroup = this.toggleGroup.bind(this);
        this.onClickSelectUser = this.onClickSelectUser.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);

    }

    componentDidMount(){
        this.props.dispatch(permissionAction.getUserInfo());
        this.props.dispatch(commonActions.request(actionTypes.PERMISSION_DATA_REQUEST));
        this.loadGroup().then(result=>{
            let groupAdminId = _.find(result, (s) => {return s.code === 'COFICO_ADMIN';}).rowId;
            let groupUserId = _.find(result, (s) => {return s.code === 'COFICO_VP';}).rowId;
            this.setState({selectGroup: groupUserId, groupUserId});
            this.setState({selectGroup: groupAdminId, groupAdminId});

            Promise.all([this.loadUsersInGroup(groupAdminId), this.loadFeatureForGroup(groupAdminId)]).then(rq=>{

                if (rq[0].length > 0){
                    let selectUser = _.head(rq[0]).rowId;
                    this.setState({selectUser});
                    this.loadFeatureForUser(selectUser).then(res=>{
                        this.props.dispatch(commonActions.success(actionTypes.PERMISSION_DATA_SUCCESS, {res}));
                    },err=>{
                        this.props.dispatch(commonActions.failure(actionTypes.PERMISSION_DATA_FAILURE, err));
                    });
                }
                else{
                    this.props.dispatch(commonActions.success(actionTypes.PERMISSION_DATA_SUCCESS, rq));
                }
            },err=>{
                this.props.dispatch(commonActions.failure(actionTypes.PERMISSION_DATA_FAILURE, err));
            });
        },err=>{
            this.props.dispatch(commonActions.failure(actionTypes.PERMISSION_DATA_FAILURE, err));
        });
    }

    loadGroup = ()=>{
        return new Promise((resolve, reject) => {
            this.props.dispatch(permissionAction.getGroups(resolve,reject));
        });
    }
    loadUsersInGroup = (id)=>{
        return new Promise((resolve, reject) => {
            this.props.dispatch(permissionAction.getUsersInGroup(id,resolve,reject));
        });
    }
    loadFeatureForGroup = (id)=>{
        return new Promise((resolve, reject) => {
            this.props.dispatch(permissionAction.getFeatureForGroup(id,resolve,reject));
        });
    }
    loadFeatureForUser = (id)=>{
        return new Promise((resolve, reject) => {
            this.props.dispatch(permissionAction.getFeatureForUser(id,resolve,reject));
        });
    }
    _loadAllUsers = () => {
        return new Promise((resolve, reject)=>{
            this.props.dispatch(userAction.getActiveUsers(resolve, reject));
        });
    }
    toggleAddUserFn = () =>{
        this.setState({isOpen: !this.state.isOpen});
        this.props.dispatch({type: actionTypes.RESET_SEARCH_USER_MODAL});
    }
    okAddUserFn = (data)=>{
        //call action delete
    }
    // onClickShowAddUser = () => {
    //     this.setState({'title': `Add user to group Admin`});
    //     // let groupName = _.find(groups, (s) => {return s.rowId === id;}) !== undefined ? _.find(groups, (s) => {return s.rowId === id;}).name : '';
    //     // this.setState({'title': `Add user to group: ${groupName}`});

    //     this.setState({selectGroup: this.state.groupAdminId, selectUser: 0});

    //     this.props.dispatch(commonActions.request(actionTypes.PERMISSION_DATA_REQUEST));
    //     Promise.all([this.loadUsersInGroup(this.state.groupAdminId), this.loadFeatureForGroup(this.state.groupAdminId)]).then(rq=>{
    //         this.props.dispatch(commonActions.success(actionTypes.PERMISSION_DATA_SUCCESS, {rq}));
    //     },err=>{
    //         this.props.dispatch(commonActions.failure(actionTypes.PERMISSION_DATA_FAILURE, err));
    //     });

    //     this.toggleAddUserFn();
    // }
    onClickShowAddUser = (selectGroup,groups) => {
        this.props.dispatch(reset('addUserInGroupPerForm'));
        //this.setState({'title': `Add user to group Admin`});
         let groupName = _.find(groups, (s) => {return s.rowId === selectGroup;}) !== undefined ? _.find(groups, (s) => {return s.rowId === selectGroup;}).name : '';
         this.setState({'title': `Nhân viên trong nhóm: ${groupName}`});

        //this.setState({selectGroup: selectGroup, selectUser: 0});

        this.props.dispatch(commonActions.request(actionTypes.PERMISSION_DATA_REQUEST));
        Promise.all([this.loadUsersInGroup(selectGroup), this.loadFeatureForGroup(selectGroup), this._loadAllUsers()]).then(rq=>{
            this.props.dispatch(commonActions.success(actionTypes.PERMISSION_DATA_SUCCESS, {rq}));
        },err=>{
            this.props.dispatch(commonActions.failure(actionTypes.PERMISSION_DATA_FAILURE, err));
        });

        this.toggleAddUserFn();
    }

    onClickSelectUser(group, item){
        this.props.dispatch(permissionAction.addUser(group, item, item.fullName));
    }

    toggleGroup(id){
        this.props.dispatch(commonActions.request(actionTypes.PERMISSION_DATA_REQUEST));
        this.setState({selectGroup: id, selectUser: 0});
        this.setState({selectUser: 0});
        Promise.all([this.loadUsersInGroup(id), this.loadFeatureForGroup(id)]).then(rq=>{
            this.props.dispatch(commonActions.success(actionTypes.PERMISSION_DATA_SUCCESS, {rq}));
        },err=>{
            this.props.dispatch(commonActions.failure(actionTypes.PERMISSION_DATA_FAILURE, err));
        });
        this.toggleTab('0');
    }

    toggleUser(user, i){
        this.setState({selectUser: i});
        this.loadFeatureForUser(user);
        this.toggleTab('1');
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                selectUser: 0
            });
        }
    }
    toggleFilter() {
        this.setState({
            openFilter: !this.state.openFilter
        });
    }
    renderList(){
        var filterList = this.props.usersInGroup;
        if(this.state.openFilter ){
            if(this.props.filterUser !== undefined){
                let txtFilter = this.props.filterUser;
                txtFilter = txtFilter?_.escapeRegExp(txtFilter).trim().toLowerCase():'';
                if( txtFilter !== ''){
                    filterList = filterList.filter((item) =>{
                        if (item.fullName.toLowerCase().match(txtFilter))
                            return item;
                    });
                }
            }

        } else {
            filterList = this.props.usersInGroup;
        }
        const {checkPermissionFeature} = this.props;
        return(
            checkPermissionFeature('Permission', 'view') ? <Container fluid>
            <Row>
                <Col md='2' className='list-permission'>
                    <FormGroup>
                        <Label>Nhóm</Label>
                        {this.props.groups !== null && this.props.groups !== undefined && this.props.groups.length> 0 && <ul className='permission'>
                            {this.props.groups.map((group, i) => {
                                let classGroup = group.rowId === this.state.selectGroup ? 'link active' : 'link';
                                return(
                                    <li key={i} onClick={() => this.toggleGroup(group.rowId)} className={`${classGroup}`}>{group.name}</li>
                                )
                            })}
                        </ul>}
                    </FormGroup>
                    <div className='text-right'>
                        {this.state.openFilter && <FormGroup>
                            <Field name="filterUser" component={renderInput} autoFocus />
                        </FormGroup>}
                        <Button color="info" type='button' onClick={e => {this.toggleFilter()}}><i className="fa fa-search" aria-hidden="true"></i></Button>{'  '}
                        {/* (this.state.groupAdminId === this.state.selectGroup || this.state.groupUserId === this.state.selectGroup) && <Button type="button" color="info" onClick={() => this.onClickShowAddUser()}>Add User</Button>*/}
                        {(checkPermissionFeature('Permission', 'add') || checkPermissionFeature('Permission', 'delete')) && <Button type="button" color="info" onClick={() => this.onClickShowAddUser(this.state.selectGroup, this.props.groups)}>Cập nhật</Button>}
                    </div>

                    <FormGroup>
                        <Label>Nhân viên</Label>
                        {filterList !== null && filterList !== undefined && filterList.length> 0 && <ul className='permission'>
                            {filterList.map((user, i) => {
                                let classUser = i === this.state.selectUser ? 'link active' : 'link';
                                return(
                                    // <li key={i} onClick={() => this.toggleUser(user.rowId, i)} className={`${classUser}`} >{user.fullName}</li>
                                    <li key={i} className={`${classUser}`} >{user.fullName}</li>
                                )
                            })}
                        </ul>}
                    </FormGroup>
                </Col>
                <Col md='10'>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                            className={classnames({ active: this.state.activeTab === '0' })}
                            onClick={() => { this.toggleTab('0'); }}>
                            PHÂN QUYỀN NHÓM
                            </NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggleTab('1'); }}>
                            PHÂN QUYỀN NHÂN VIÊN
                            </NavLink>
                        </NavItem> */}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="0">
                            <Row style={{margin: '0'}}>
                                <Col md='12' className='pl-0 pr-0'>
                                    <FieldArray name='featureGroup'
                                        component={renderPermission}/>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="1">
                            <Row style={{margin: '0'}}>
                                <Col md='12' className='pl-0 pr-0'>
                                    <FieldArray name='featureUser'
                                        component={renderPermission}/>
                                </Col>
                            </Row>
                        </TabPane>

                    </TabContent>
                </Col>
            </Row>
            </Container> : <Container><div><h5 className="text-center">Bạn không có quyền truy cập trang này, vui lòng liên hệ admin!</h5></div></Container>
        )
    }
    render() {
        const { handleSubmit, pristine, submitting, valid, checkPermissionFeature } = this.props;
        return (
            <div className="main-box">
                <h5 className="page-header">Phân quyền</h5>
                <form onSubmit={handleSubmit} className="filter-group">
                    <div className="action-group">
                        {checkPermissionFeature('Permission', 'edit') && <Button color="info" type='button' onClick={handleSubmit(values => this.props.onSubmit({...values, type: this.state.activeTab}))} disabled={!valid || pristine || submitting}>Lưu</Button>}
                    </div>
                    {this.renderList()}
                </form>
                <AddUserInGroupPerModal
                    usersActiveList={this.props.usersActiveList}
                    usersInGroup={this.props.usersInGroup}
                    searchUser={this.props.searchUser}
                    groupId={this.state.selectGroup}
                    onClickSelectUser={this.onClickSelectUser}
                    mdTitle={this.state.title}
                    mdBody={this.state.message}
                    isOpen={this.state.isOpen}
                    okFn={this.okAddUserFn}
                    toggleFn={this.toggleAddUserFn}
                    checkPermissionFeature={checkPermissionFeature}/>
            </div>
        )
    }
}

const selector = formValueSelector('permissionForm');

const loadingSelector = loadingUtility(['PERMISSION_DATA', 'GET_USERINGROUP', 'GET_FEATUREGROUP', 'GET_FEATUREUSER', 'SET_FEATUREGROUP', 'SET_FEATUREUSER', 'SEARCH_USER', 'ADD_USER', 'GET_GROUP']);

const mapStateToProps = state => {

    return {
        isFetching: loadingSelector(state),
        groups: state.userReducer.groups,
        usersInGroup: state.userReducer.usersInGroup,
        searchUser: state.userReducer.searchUser,
        featureGroup: selector(state, 'featureGroup'),
        featureUser: selector(state, 'featureUser'),
        filterUser: selector(state, 'filterUser'),
        initialValues: {
            featureGroup: state.userReducer.featureGroup,
            featureUser: state.userReducer.featureUser,
        },
        usersActiveList: state.userReducer.usersActiveList
    }
}

const mapDispatchToProps = (dispatch,props) => ({
    onSubmit: values => {
        if(values.type === '0')
            dispatch(permissionAction.setFeatureForGroup(values.featureGroup));
        else
            dispatch(permissionAction.setFeatureForUser(values.featureUser));
    }
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'permissionForm',
        enableReinitialize: true
    }),
    commonWrapped({showLoadingWholePage:true}),
    withPermission()
)(PermissionPage);
