import {actionTypes} from '../constants/actionTypes';
import _ from 'lodash';

var initialState = {
    categories:{
        projects: [],
        accidents: [],
        contractors: [],
        images: [],
        trainings: [],
        violations: [],
        punishments: [],
        hazardouss: [],
        teams: [],
        selectedItem: null,
        catalogName: null
    },
    projectStatus: null,
    projectItem: null,
    violationParents: [],
    punishmentParents: [],
    hazardousParents: [],
    projectsByUser: [],
    contractorByProject: [],
    allContractor:[],
    allTeam:[],
    teamByContractor: [],
    towersByProject: [],
    levelsByProjectTower: [],
    zonesByProjectLevel: [],
    engineers: [],
    hazarousFilter: [],
    violationCategoryType: []
}

export function categoryReducer(state = initialState, action) {
    switch (action.type) {
        // ==
        // == GLOBAL CATEGORIES
        case actionTypes.UPDATE_CATEGORIES_DATA:
            const data1 = {...state, categories: action.data};
            return data1;
        case actionTypes.CATELOGORY_CRUD_SUCCESS:
            let stateClone = _.cloneDeep(state);
            const type_action = action.data.type_action;
            let catalog_name = action.data.catalog_name;
            let response_data = action.data.response;
            let request_data = action.data.request;
            if(type_action==='add' || type_action === 'update')response_data = Object.assign(request_data, {id: response_data.id});
            let ctg = stateClone.categories[catalog_name.toLowerCase()+'s'];
            if(type_action==='add'){
                // ctg.push(response_data);
                ctg.unshift(response_data);
            }else if(type_action === 'update'){
                const index = ctg.findIndex(obj => obj.id === action.data.request.id);
                ctg[index] = response_data;
            }else if(type_action === 'delete'){
                const index = ctg.findIndex(obj => obj.id === action.data.request.id);
                ctg = ctg.splice(index, 1);
            }
            // ctg = ctg.sort((a, b) => Number(a.sortOrder) > Number(b.sortOrder));
            stateClone.categories.selectedItem = null;
            return stateClone;
        case actionTypes.CATELOGORY_ITEM_SELECTED:
            stateClone = _.cloneDeep(state);
            let rqData = action.data;
            if(_.isEqual(action.data,{})){
                rqData.isActive = true;
            }
            stateClone.categories.selectedItem = rqData;
            return stateClone;
        case actionTypes.CATELOGORY_GETLIST_SUCCESS:
            catalog_name = action.data.catalog_name;
            stateClone = _.cloneDeep(state);
            stateClone.categories[catalog_name +'s'] = action.data.obj;
            stateClone.categories.catalogName = catalog_name;
            return stateClone;
        case actionTypes.CATELOGORY_GETPARENTLIST_SUCCESS:
            catalog_name = action.data.catalog_name;
            stateClone = _.cloneDeep(state); 
            let itemSelected, parentList, idx;
            itemSelected = action.data.itemSelected;
            parentList = _.cloneDeep(action.data.obj);
            const textNone = '-- Rỗng --';
            if(catalog_name == 'punishment') {
                parentList.unshift({punishmentName: textNone});
            }
            if(catalog_name == 'violation') {
                parentList.unshift({violationName: textNone});
            }
            if(catalog_name == 'hazardous') {
                parentList.unshift({hazardName: textNone});
            }
            if(itemSelected !== undefined && itemSelected.id > 0){
                if(itemSelected.parentID === null){
                    idx = parentList.findIndex(obj => obj.id === itemSelected.id); 
                    parentList.splice(idx, 1);
                }
            }
            stateClone[catalog_name +'Parents'] = parentList;
            stateClone.categories.catalogName = catalog_name;
            return stateClone;
        case actionTypes.CATELOGORY_GETLISTS_SUCCESS:
            const results = action.data;
            stateClone = _.cloneDeep(state);
            _.forEach(results, function(list, idx){
                stateClone.categories[list.catalogName +'s'] = list.data;
            });
            return stateClone;

        // ==
        // == Danh muc du an
        case actionTypes.PROJECT_INFO_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.projectItem = action.data;
            return stateClone;
        case actionTypes.PROJECT_STATUS_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.projectStatus = action.data;
            return stateClone;

        // ==
        // == Danh muc du an ADD, EDIT, DELETE THAP, TANG, ZONE
        case actionTypes.PROJECT_TOWER_FAC_ZONE_SUCCESS:
            stateClone = _.cloneDeep(state);
            const type_action_project = action.data.type_action;
            let projectItem = stateClone.projectItem;
            request_data = action.data.request;
            response_data = action.data.response;
            if(type_action_project==='add' || type_action_project==='update'){
                if(response_data !== null && response_data !== undefined && response_data.id !== null && response_data.id !== undefined) {
                    response_data = Object.assign(request_data, {id: response_data.id, isEditing: false});
                }
                let objTFZ = _.get(projectItem, action.data.idxItem);
                objTFZ = _.merge(objTFZ, response_data);
            }else if(type_action_project==='delete'){
                let strIdx;
                let str = action.data.idxItem;
                if(request_data.type === 1){
                    strIdx = str;
                }else if(request_data.type === 2){
                    str = str.split('.');
                    strIdx = str[1];
                }else if(request_data.type === 3){
                    str = str.split('.');
                    strIdx = str[2];
                }
                
                let arrSplit = strIdx.split('[');
                let arrStr = arrSplit[0];
                let idxSplit = arrSplit[1].split(']');
                const idxItem = idxSplit[0];
                let arrTFZ;
                if(request_data.type === 1){
                    arrTFZ = _.get(projectItem, arrStr);
                }else if(request_data.type === 2){
                    arrTFZ = _.get(projectItem, str[0] + '.' + arrStr);
                }else if(request_data.type === 3){
                    arrTFZ = _.get(projectItem, str[0] + '.' + str[1] + '.' + arrStr);
                }
                 
                arrTFZ = arrTFZ.splice(idxItem, 1);
                // _.unset(projectItem, action.data.idxItem);
            }else if(type_action_project==='editing'){
                let objTFZ = _.get(projectItem, action.data.idxItem);
                objTFZ = _.merge(objTFZ, action.data.request);
            }
            return stateClone;

        // ==
        // == Danh muc du an GET THAP, TANG, ZONE

        case actionTypes.GET_PROJECT_TOWER_FAC_ZONE_SUCCESS:
            stateClone = _.cloneDeep(state);
            let type_action_get_tfz = action.data.type_action;
            projectItem = stateClone.projectItem;
            request_data = action.data.request;
            response_data = action.data.response;
            // GET Thap, Tang, Zone
            if(request_data !== null && request_data !== undefined){
                if(type_action_get_tfz === 'collapse'){
                    let values = _.get(projectItem, action.data.idxItem);
                    if(!values.isCollapse){
                        values = _.set(values, "isCollapse", true);
                    }else{
                        values = _.set(values, "isCollapse", false);
                    }
                    if(_.isNull(values.parentID) || _.isUndefined(values.parentID)){
                        values.Zones = response_data;
                    }else{
                        values.Levels = response_data;
                    }
                }else{
                    let objTFZ = _.get(projectItem, action.data.idxItem);
                    if(!_.isUndefined(request_data.type) && request_data.type === 1){
                        if(!_.isUndefined(response_data)) projectItem.towerFacs = response_data;
                        // objTFZ.push(request_data);
                    }else if(!_.isUndefined(request_data.type) && request_data.type === 2){
                        objTFZ = _.set(objTFZ, "isCollapse", true);
                        if(!_.isUndefined(response_data)) objTFZ.Zones = response_data;
                        // objTFZ.Zones.push(request_data);
                    }else if(!_.isUndefined(request_data.type) && request_data.type === 3){
                        objTFZ = _.set(objTFZ, "isCollapse", true);
                        if(!_.isUndefined(response_data)) objTFZ.Levels = response_data;
                        // objTFZ.Levels.push(request_data);
                    }
                }

            }
            return stateClone;

        // ==
        // == Danh muc du an ADD, EDIT, DELETE NHA THAU, TO DOI, KY SU

        case actionTypes.PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS:
            stateClone = _.cloneDeep(state);
            const type_action_pro_contract = action.data.type_action;
            projectItem = stateClone.projectItem;
            request_data = action.data.request;
            response_data = action.data.response;
            if(type_action_pro_contract==='add' || type_action_pro_contract==='update'){
                if(response_data !== null && response_data !== undefined && response_data.id !== null && response_data.id !== undefined) {
                    response_data = Object.assign(request_data, {rowID: response_data.id, isEditing: false});
                }
                let objCTU = _.get(projectItem, action.data.idxItem);
                objCTU = _.merge(objCTU, response_data);
            }else if(type_action_pro_contract==='delete'){

                let strIdxCTU;
                let strCTU = action.data.idxItem;
                if(request_data.level === 1){
                    strIdxCTU = strCTU;
                }else if(request_data.level === 2){
                    strCTU = strCTU.split('.');
                    strIdxCTU = strCTU[1];
                }else if(request_data.level === 3){
                    strCTU = strCTU.split('.');
                    strIdxCTU = strCTU[2];
                }
                
                let arrSplit = strIdxCTU.split('[');
                let arrStrCTU = arrSplit[0];
                let idxSplit = arrSplit[1].split(']');
                const idxItemCTU = idxSplit[0];
                let arrCTU;
                if(request_data.level === 1){
                    arrCTU = _.get(projectItem, arrStrCTU);
                }else if(request_data.level === 2){
                    arrCTU = _.get(projectItem, strCTU[0] + '.' + arrStrCTU);
                }else if(request_data.level === 3){
                    arrCTU = _.get(projectItem, strCTU[0] + '.' + strCTU[1] + '.' + arrStrCTU);
                }
                 
                arrCTU = arrCTU.splice(idxItemCTU, 1);
                // _.unset(projectItem, action.data.idxItem);
            }else if(type_action_pro_contract==='editing'){
                // debugger;
                let objCTU = _.get(projectItem, action.data.idxItem);
                objCTU = _.merge(objCTU, action.data.request);
            }
            return stateClone;

        // ==
        // == Danh muc du an GET NHA THAU, TO DOI, KY SU

        case actionTypes.GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS:
            stateClone = _.cloneDeep(state);
            let type_action_get_ctu = action.data.type_action;
            projectItem = stateClone.projectItem;
            request_data = action.data.request;
            response_data = action.data.response;
            // ADD Thap, Tang, Zone
            if(request_data !== null && request_data !== undefined){
                if(type_action_get_ctu === 'collapse'){
                    let valCTU = _.get(projectItem, action.data.idxItem);
                    if(!valCTU.isCollapse){
                        valCTU = _.set(valCTU, "isCollapse", true);
                    }else{
                        valCTU = _.set(valCTU, "isCollapse", false);
                    }
                    if(_.isNull(valCTU.teamID) || _.isUndefined(valCTU.teamID)){
                        valCTU.Teams = response_data;
                    }else{
                        valCTU.Users = response_data;
                    }
                }else{
                    let objCTU = _.get(projectItem, action.data.idxItem);
                    if(!_.isUndefined(request_data.type) && request_data.type === 1){
                        if(!_.isUndefined(response_data)) projectItem.contractors = response_data;
                        if(!_.isUndefined(type_action_get_ctu) && type_action_get_ctu === 'new') objCTU.push(request_data);
                    }else if(!_.isUndefined(request_data.type) && request_data.type === 2){
                        objCTU = _.set(objCTU, "isCollapse", true); 
                        if(!_.isUndefined(response_data)) objCTU.Teams = response_data;
                        if(!_.isUndefined(type_action_get_ctu) && type_action_get_ctu === 'new') objCTU.Teams.push(request_data);
                    }else if(!_.isUndefined(request_data.type) && request_data.type === 3){
                        objCTU = _.set(objCTU, "isCollapse", true);
                        if(!_.isUndefined(response_data)) objCTU.Users = response_data;
                        if(!_.isUndefined(type_action_get_ctu) && type_action_get_ctu === 'new') objCTU.Users.push(request_data);
                    }
                }
            }
            return stateClone;

        case 'DATA_PAGINATION':
            stateClone = _.cloneDeep(state);
            return stateClone;

          // ==
          // == Filter Report
        case actionTypes.GET_ENGINEERS_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.engineers = action.data;
            return stateClone;

        case actionTypes.GET_PROJECT_BY_USER_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.projectsByUser = action.data;
            return stateClone;

        case actionTypes.GET_CONTRACTOR_BY_PROJECT_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.contractorByProject = action.data;
            return stateClone;

        case actionTypes.GET_ALL_CONTRACTOR_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.allContractor = action.data;
            return stateClone;

        case actionTypes.GET_ALL_TEAM_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.allTeam = action.data;
            return stateClone;    

        case actionTypes.GET_TEAM_BY_CONTRACTOR_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.teamByContractor = action.data;
            return stateClone;
            
        case actionTypes.GET_TOWER_BY_PROJECT_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.towersByProject = action.data;
            return stateClone;

        case actionTypes.GET_NULL_TOWER_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.towersByProject = [];
            return stateClone;    
        
        case actionTypes.GET_LEVEL_BY_PROJECT_TOWER_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.levelsByProjectTower = action.data;
            return stateClone;

        case actionTypes.GET_NULL_LEVEL_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.levelsByProjectTower = [];
            return stateClone;  
        
        case actionTypes.GET_ZONE_BY_PROJECT_LEVEL_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.zonesByProjectLevel = action.data;
            return stateClone;

        case actionTypes.GET_NULL_ZONE_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.zonesByProjectLevel = [];
            return stateClone;
        case actionTypes.REPORT_HAZAROUS_SUCCESS:
            stateClone = _.cloneDeep(state);
            stateClone.hazarousFilter = action.data;
            return stateClone;
        case actionTypes.GET_VIOLATION_CATEGORY_TYPE_SUCCESS: 
            stateClone = _.cloneDeep(state);
            stateClone.violationCategoryType = action.data;
            return stateClone;
        default:
            return state;
    }
}
