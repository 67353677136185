
const validate = {
    required,
    email,
    maxLength,
    number,
    integer,
    maxLengthUserName,
    minLengthPassword,
    compare2Jsons
};

function required(value) {
    return (value || typeof value === 'number' ? undefined : 'Trường này là bắt buộc!')
}
function email(value) {
    return (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Địa chỉ email không hợp lệ'
        : undefined)
}
function maxLength(value, max) {
    return (value && value.length > max ? `Must be ${max} characters or less` : undefined)
}
function maxLengthUserName(value) {
    return (value && value.length > 100 ? `Phải từ 100 ký tự trở xuống` : undefined)
}
function minLengthPassword(value){
  return (value && value.length < 6 ? `Phải có 6 ký tự trở lên` : undefined)
}
function number(value) {
    return (value && isNaN(Number(value)) ? 'Phải là một số' : undefined)
}
function integer(value) {
    // const regex = /^[-+]?\d*$/;
    const regex = !/^[1-9]+[0-9]*$/i;
    return (value && !/^[1-9]+[0-9]*$/i.test(value)
        ? 'Phải là số nguyên'
        : undefined)
}
//return true if 2 objects equal
function compare2Jsons(obj1, obj2){//Works when you have simple JSON-style objects without methods and DOM nodes inside
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}
export default validate;
