import config from 'config';
import axios from 'axios';
import ApiEndpoints from '../constants/ApiEndpoints';


const trainingService = {
    add,
    update,
    get,
    gets,
    remove
}

function add(data){
    let url = `${config.apiUrl}/${ApiEndpoints.trainingCate}/PUT`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function update(data){
    let url = `${config.apiUrl}/${ApiEndpoints.trainingCate}/PUT`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function get(id){
    return new Promise((resolve, reject) => {
        resolve();
    });
}

function gets(){
    // let url = `./resources/trainingCate.json`;
    let url = `${config.apiUrl}/${ApiEndpoints.trainingCate}/items`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function remove(item){
    let url = `${config.apiUrl}/${ApiEndpoints.trainingCate}/DELETE/${item.id}`; 
    return new Promise((resolve, reject) => {
        axios.delete(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

export default trainingService;
