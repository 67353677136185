import config from 'config';
import axios from 'axios';
import ApiEndpoints from '../constants/ApiEndpoints';


const projectService = {
    add,
    update,
    get,
    gets,
    remove,
    getInfoProjectByID,
    getProjectStatus,
    getProjectTowerFacZone,
    submitProjectTowerFacZone,
    deleteProjectTowerFacZone,
    getContractorByProject,
    updateContractorToProject,
    getTeamByContractor,
    updateTeamToContractor,
    getUserByTeam,
    updateUserToTeam,
    getProjectByUser,
    getEngineers,
    getProjectByEngineer,
    towerFacZonePutMulti,

    // getContractorByProjectReport,
    // getTeamByContractorReport,
    getAllContractor,

}

function add(data){
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/PUT`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function update(data){
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/PUT`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function get(id){
    return new Promise((resolve, reject) => {
        resolve();
    });
}

function gets(){
    // let url = `./resources/projectCate.json`;
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/items`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function remove(item){
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/DELETE/${item.id}`;
    return new Promise((resolve, reject) => {
        axios.delete(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function getProjectByUser(){
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/GetProjectByUser`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

//===========================
//===== PROJECT DETAIL ======
//===========================

function getInfoProjectByID(id){
    // let url = `./resources/projectCate.json`;
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/Get/${id}`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function getProjectStatus(){
    // let url = `./resources/projectCate.json`;
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/ProjectStatus`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

//== Tower/ FAC/ ZONE

function towerFacZonePutMulti(number, data){
    let url = `${config.apiUrl}/api/ProjectTowerFacZone/PutMulti/${number}`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function getProjectTowerFacZone(data){
    let parentID;
    if(data.id !== undefined){
        parentID = data.id;
    } else{
        parentID = data.parentID;
    }
    let url = `${config.apiUrl}/${ApiEndpoints.projectTowerFacZone}/items/${data.projectID}/${parentID}`;
    return new Promise((resolve,reject)=>{
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function submitProjectTowerFacZone(data){
    let url = `${config.apiUrl}/${ApiEndpoints.projectTowerFacZone}/PUT`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function deleteProjectTowerFacZone(data){
    let url = `${config.apiUrl}/${ApiEndpoints.projectTowerFacZone}/DELETE/${data.projectID}/${data.id}`;
    return new Promise((resolve,reject)=>{
        axios.delete(url).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

//== Contractor/ Team/ Engineer

function getContractorByProject(data){
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/GetContractorByProject/${data.projectID}`;
    return new Promise((resolve,reject)=>{
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function updateContractorToProject(data){
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/UpdateContractorToProject`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function getTeamByContractor(data){
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/GetTeamByContractor/${data.projectID}/${data.contractorID}`;
    return new Promise((resolve,reject)=>{
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function updateTeamToContractor(data){
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/UpdateTeamToContractor`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function getUserByTeam(data){
    let rowID;
    if(data.rowID === undefined){
        rowID = data.teamID;
    }else{
        rowID = data.rowID;
    }
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/GetUserByTeam/${rowID}`;
    return new Promise((resolve,reject)=>{
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

function updateUserToTeam(data){
    let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/UpdateUserToTeam`;
    return new Promise((resolve,reject)=>{
        axios.put(url, data).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

//===========================
//=== END PROJECT DETAIL ====
//===========================

function getEngineers(){
  let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/GetEngineers`;
  return new Promise((resolve, reject) => {
      axios.get(url).then(res=>{
          resolve(res.data.content.items);
      },function(e){
          reject(e);
      });
  });
}

function getProjectByEngineer(data){
  let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/GetProjectByEngineer/${data.userName}`;
  return new Promise((resolve, reject) => {
      axios.get(url).then(res=>{
          resolve(res.data.content.items);
      },function(e){
          reject(e);
      });
  });
}

function getAllContractor(){
    let url = `${config.apiUrl}/${ApiEndpoints.contractorCate}/items`;
    return new Promise((resolve,reject)=>{
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },e=>{
            reject(e);
        });
    });
}

// function getContractorByProjectReport(data){
//     let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/GetContractorByProjectReport`;
//     return new Promise((resolve,reject)=>{
//         axios.post(url, data).then(res=>{
//             resolve(res.data.content.items);
//         },e=>{
//             reject(e);
//         });
//     });
// }

// function getTeamByContractorReport(data){
//     let url = `${config.apiUrl}/${ApiEndpoints.projectCate}/GetTeamByContractorReport`;
//     return new Promise((resolve,reject)=>{
//         axios.post(url, data).then(res=>{
//             resolve(res.data.content.items);
//         },e=>{
//             reject(e);
//         });
//     });
// }

export default projectService;
