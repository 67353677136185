import React, {Fragment} from 'react';
import { FormGroup, Container,Row, Col, Table, Button } from 'reactstrap';
import { Field, FieldArray} from 'redux-form';
import renderInput from '../controls/renderInput';
import validate from '../validate/validate';
import _ from 'lodash';
import renderDropdownList from '../controls/renderDropdownList';
import renderMultiselect from '../controls/renderMultiselect';
import renderTextarea from '../controls/renderTextarea';

const ProjectDetailForm = (props) => {
        const rs = _.get(window.ePayment,'resource');
        const {dispatch, action} = props;
        return (
            <Container fluid>
                <Row>
                    {(props.projectItem !== null && props.projectItem !== undefined && props.projectItem.id !== null && props.projectItem.id !== undefined && props.projectItem.id > 0) ? <><Col md='12'>
                        <FormGroup>
                            <h5>{_.get(rs,'projectCategory.location')}</h5>
                            <FieldArray
                                name="towerFacs"
                                dispatch={dispatch}
                                action={action}
                                projectItem={props.projectItem}
                                collapseTowerFacZone={props.collapseTowerFacZone}
                                createTowerFacZone={props.createTowerFacZone}
                                handleRemoveItem={props.handleRemoveItem}
                                submitTowerFacZone={props.submitTowerFacZone}
                                editingTowerFacZone={props.editingTowerFacZone}
                                cancelEditTowerFacZone={props.cancelEditTowerFacZone}
                                onChangeTowerFacZone={props.onChangeTowerFacZone}
                                component={renderTowers}/>
                        </FormGroup>
                    </Col>
                    <Col md='12'>
                        <FormGroup>
                            <h5>{_.get(rs,'projectCategory.constractor')}</h5>
                            <FieldArray
                                name="contractors"
                                dispatch={dispatch}
                                action={action}
                                projectItem={props.projectItem}
                                contractors={props.categories.contractors}
                                teams={props.categories.teams}
                                usersActiveList={props.usersActiveList}
                                collapseContractorTeamUser={props.collapseContractorTeamUser}
                                editingContractorTeamUser={props.editingContractorTeamUser}
                                deleteContractorTeamUser={props.deleteContractorTeamUser}
                                handleRemoveItem={props.handleRemoveItem}
                                createContractorTeamUser={props.createContractorTeamUser}
                                submitContractorTeamUser={props.submitContractorTeamUser}
                                onChangeContractorTeamUser={props.onChangeContractorTeamUser}
                                component={renderContractors}/>
                        </FormGroup>
                    </Col></> : null}
                </Row>
            </Container>
        )
}

// Tầng đổi thành Zone
const renderLevels = ({ fields, indexTower, indexZone, editingTowerFacZone, cancelEditTowerFacZone, handleRemoveItem, submitTowerFacZone, onChangeTowerFacZone, meta: { error }, action }) => (
    <>
    {fields !== null && fields !== undefined &&
        <Table hover className="tbl-project">
            <thead>
            <tr>
                <th className="action"></th>
                <th className="col-name">Tên Zone</th>
                <th className="col-note">Ghi chú</th>
                <th className="w-px-150">Thứ tự xuất hiện</th>
                {action !== 'view' && <th className="action-btn"></th>}
            </tr>
            </thead>
            <tbody>
                {fields !== null && fields !== undefined && fields.length === 0 && <tr>
                    <td colSpan="5">Không có dữ liệu</td>
                </tr>}
                {fields.map((level, index) => {
                    const values = fields.get(index);
                    let isValid = values.name !== undefined && values.name !== null && values.name.length > 0;
                    const isNumber = validate.number(values.sortOrder) === undefined;
                    const isInteger = validate.integer(values.sortOrder) === undefined;
                    const idxItem = `towerFacs[${indexTower}].Zones[${indexZone}].Levels[${index}]`;
                    return(
                        <tr key={`${level}`}>
                            <td>
                                {action !== 'view' && <>{!values.isEditing ? (
                                    <Button
                                        className="i-btn cta cta-save"
                                        onClick={() => {editingTowerFacZone(idxItem);}}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </Button>): null}</>}
                                
                                {values.isEditing && isValid && isNumber && isInteger ? (
                                <Button className="i-btn cta cta-save" 
                                onClick={() => {
                                    let values = fields.get(index);
                                    submitTowerFacZone(idxItem, values, 3);
                                }}><i className="fa fa-save" aria-hidden="true"></i></Button>): null}

                                {values.isEditing && <Button
                                    className="i-btn cta cta-save"
                                    onClick={() => {cancelEditTowerFacZone(idxItem);}}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>}

                                {action !== 'view' && <>{!values.isEditing ? (<><Button className="i-btn cta cta-delete" onClick={() => {handleRemoveItem({idxItem:idxItem, kindOf:'tower'})}}>
                                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                                </Button>&nbsp;&nbsp;</>) : null}</>}
                            </td>
                            <td>
                            {!values.isEditing ? (<span>{values.name}</span>): null}
                            {values.isEditing ? (<Field
                                    name={`${level}.name`}
                                    type='text'
                                    validate={validate.required}
                                    disabled={!values.isEditing}
                                    onChange={(e, newValue, previousValue, name) => {
                                        onChangeTowerFacZone(newValue, idxItem, 'name')
                                    }}
                                    component={renderInput}/>): null}
                            </td>
                            <td>
                            {!values.isEditing ? (<span>{values.note}</span>): null}
                            {values.isEditing ? (<Field
                                    name={`${level}.note`}
                                    type='text'
                                    disabled={!values.isEditing}
                                    onChange={(e, newValue, previousValue, name) => {
                                        onChangeTowerFacZone(newValue, idxItem, 'note')
                                    }}
                                    component={renderTextarea}/>): null}
                            </td>
                            <td>
                            {!values.isEditing ? (<span>{values.sortOrder}</span>): null}
                            {values.isEditing ? (<Field
                                    name={`${level}.sortOrder`}
                                    type='text'
                                    validate={[validate.number, validate.integer]}
                                    disabled={!values.isEditing}
                                    
                                    component={renderInput}/>): null}
                            </td>
                            {/* onChange={(e, newValue, previousValue, name) => {
                                        onChangeTowerFacZone(newValue, `towerFacs[${indexTower}].Zones[${index}]`, 'sortOrder')
                                    }} */}
                            {action !== 'view' && <td>

                            </td>}
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    }
    </>
)

// Zone đổi thành tầng
const renderZones = ({ fields, projectItem, indexTower, collapseTowerFacZone, editingTowerFacZone, cancelEditTowerFacZone, createTowerFacZone, handleRemoveItem, submitTowerFacZone, onChangeTowerFacZone, meta: { error }, action }) => (
    <>
    {fields !== null && fields !== undefined &&
        <Table hover className="tbl-project">
            <thead>
            <tr>
                <th className="action-btn"></th>
                <th className="col-name">Tên tầng</th>
                <th className="col-note">Ghi chú</th>
                <th className="w-px-150">Thứ tự xuất hiện</th>
                {action !== 'view' && <th className="action-btn"></th>}
            </tr>
            </thead>
            <tbody>
                {fields !== null && fields !== undefined && fields.length === 0 && <tr>
                    <td colSpan="5">Không có dữ liệu</td>
                </tr>}
                {fields.map((zone, index) => {
                    const values = fields.get(index);
                    let isValid = values.name !== undefined && values.name !== null && values.name.length > 0;
                    const isNumber = validate.number(values.sortOrder) === undefined;
                    const isInteger = validate.integer(values.sortOrder) === undefined;
                    const idxItem = `towerFacs[${indexTower}].Zones[${index}]`;
                    return(
                        <Fragment key={`zone-${index}`}><tr key={`${zone}`}>
                            <td>
                                <Button className="i-btn cta cta-delete" onClick={() => {collapseTowerFacZone(idxItem)}}>
                                    <i className={'fa fa-angle-' + ((values.isCollapse !== null && values.isCollapse !== undefined && values.isCollapse) ? 'down' : 'right')} aria-hidden="true"></i>
                                </Button>&nbsp;

                                {action !== 'view' && <>{!values.isEditing ? (
                                    <Button
                                        className="i-btn cta cta-save"
                                        onClick={() => {editingTowerFacZone(idxItem)}}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </Button>): null}</>}
                                
                                {values.isEditing && isValid && isNumber && isInteger ? (<Button className="i-btn cta cta-save" onClick={() => {
                                    let values = fields.get(index);
                                    submitTowerFacZone(idxItem,values, 2);
                                }}><i className="fa fa-save" aria-hidden="true"></i></Button>): null}

                                {values.isEditing && <Button
                                    className="i-btn cta cta-save"
                                    onClick={() => {cancelEditTowerFacZone(idxItem);}}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>}

                                {action !== 'view' && <>{!values.isEditing ? (<><Button className="i-btn cta cta-delete" onClick={() => {
                                    handleRemoveItem({idxItem: idxItem, kindOf:'tower'});
                                }}><i className="fa fa-trash-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>) : null}</>}
                            </td>
                            <td>
                            {!values.isEditing ? (<span>{values.name}</span>): null}
                            {values.isEditing ? (<Field
                                    name={`${zone}.name`}
                                    type='text'
                                    validate={validate.required}
                                    disabled={!values.isEditing}
                                    onChange={(e, newValue, previousValue, name) => {
                                        onChangeTowerFacZone(newValue, idxItem, 'name')
                                    }}
                                    component={renderInput}/>): null}
                            </td>
                            <td>
                            {!values.isEditing ? (<span>{values.note}</span>): null}
                            {values.isEditing ? (<Field
                                    name={`${zone}.note`}
                                    type='text'
                                    disabled={!values.isEditing}
                                    onChange={(e, newValue, previousValue, name) => {
                                        onChangeTowerFacZone(newValue, idxItem, 'note')
                                    }}
                                    component={renderTextarea}/>): null}
                            </td>
                            <td>
                            {!values.isEditing ? (<span>{values.sortOrder}</span>): null}
                            {values.isEditing ? (<Field
                                    name={`${zone}.sortOrder`}
                                    type='text'
                                    validate={[validate.number, validate.integer]}
                                    disabled={!values.isEditing}
                                    onChange={(e, newValue, previousValue, name) => {
                                        onChangeTowerFacZone(newValue, idxItem, 'sortOrder')
                                    }}
                                    component={renderInput}/>): null}
                            </td>
                            {action !== 'view' && <td>
                                    <Button color="link" size="sm" onClick={() => {createTowerFacZone(idxItem, 3, values);}}>
                                    <i className="fa fa-plus-circle" aria-hidden="true"></i></Button>&nbsp;
                                    <div style={{display: 'inline-block'}}><Field
                                    style={{width: '65px'}}
                                    name={`${zone}.number`}
                                    type='text'
                                    placeholder='S.lượng'
                                    validate={[validate.number, validate.integer]}
                                    component={renderInput}/></div>&nbsp;
                                    <span style={{color: '#007bff'}}>
                                    Zone</span>
                            </td>}
                        </tr>
                        {(values.isCollapse !== null && values.isCollapse !== undefined && values.isCollapse) ? (<tr className="level_3">
                            <td colSpan="5">
                                <FieldArray
                                    name={`${zone}.Levels`}
                                    projectItem={projectItem}
                                    indexTower={indexTower}
                                    indexZone={index}
                                    editingTowerFacZone={editingTowerFacZone}
                                    cancelEditTowerFacZone={cancelEditTowerFacZone}
                                    handleRemoveItem={handleRemoveItem}
                                    submitTowerFacZone={submitTowerFacZone}
                                    component={renderLevels}
                                    onChangeTowerFacZone={onChangeTowerFacZone}
                                    action={action}/>
                            </td>
                        </tr>): null}</Fragment>
                    );
                })}
            </tbody>
        </Table>
    }
    </>
)

// tháp - zone - tầng đổi lại thành tháp - tầng - zone  
const renderTowers = ({ fields, projectItem, collapseTowerFacZone, createTowerFacZone, handleRemoveItem, submitTowerFacZone, editingTowerFacZone, cancelEditTowerFacZone, onChangeTowerFacZone, meta:{error}, action}) => (
    <Table hover className={"tbl-project " + (action === 'view' ? 'view' : null)}>
        <thead>
          <tr>
            <th className="action-btn"></th>
            <th className="col-name">Tên tháp</th>
            <th className="col-note">Ghi chú</th>
            <th className="w-px-150">Thứ tự xuất hiện</th>
            {action !== 'view' && <th className="action-btn">
                <Button style={{color:'white'}} color="link" size="sm" onClick={() => {createTowerFacZone(`towerFacs`, 1, null);}}>
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                </Button>&nbsp;
                <div style={{display: 'inline-block'}}>
                    <Field
                        style={{width: '65px'}}
                        name='numTower'
                        type='text'
                        placeholder='S.lượng'
                        validate={[validate.number, validate.integer]}
                        component={renderInput}/>
                </div>&nbsp;
                <span style={{color:'white'}}>
                Tháp
                </span>
            </th>}
          </tr>
        </thead>
        <tbody>
            {fields !== null && fields !== undefined && fields.length === 0 && <tr>
                <td colSpan="5">Không có dữ liệu</td>
            </tr>}
            {error && <span className="error">{error}</span>}
            {fields.map((tower, index) => {
                const values = fields.get(index);
                let isValid = values.name !== undefined && values.name !== null && values.name.length > 0;
                const isNumber = validate.number(values.sortOrder) === undefined;
                const isInteger = validate.integer(values.sortOrder) === undefined;
                const idxItem = tower;
                return(
                    <Fragment key={`tower-${index}`}><tr key={`${tower}`}>
                        <td>
                            <Button className="i-btn cta cta-delete" onClick={() => {collapseTowerFacZone(idxItem)}}>
                                <i className={'fa fa-angle-' + (values.isCollapse ? 'down' : 'right')} aria-hidden="true"></i>
                            </Button>&nbsp;

                            {action !== 'view' && <>{!values.isEditing ? (
                                <Button
                                    className="i-btn cta cta-save"
                                    onClick={() => {editingTowerFacZone(idxItem);}}>
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                </Button>): null}</>}
                            
                            {values.isEditing && isValid && isNumber && isInteger ? (
                                <Button className="i-btn cta cta-save" onClick={() => {
                                    let values = fields.get(index);
                                    submitTowerFacZone(idxItem, values, 1);}}>
                                    <i className="fa fa-save" aria-hidden="true"></i>
                                </Button>): null}

                            {action !== 'view' && <>{!values.isEditing ? (<><Button
                            className="i-btn cta cta-delete"
                            onClick={() => {handleRemoveItem({idxItem:idxItem, kindOf:'tower'});}}>
                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                            </Button>&nbsp;&nbsp;</>) : null}</>}

                            {values.isEditing && <Button
                                    className="i-btn cta cta-save"
                                    onClick={() => {cancelEditTowerFacZone(idxItem);}}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>}
                        </td>
                        <td>
                            {!values.isEditing ? (<span>{values.name}</span>): null}
                            {values.isEditing ? (<Field
                                name={`${tower}.name`}
                                type='text'
                                validate={validate.required}
                                disabled={!values.isEditing}
                                onChange={(e, newValue, previousValue, name) => {
                                        onChangeTowerFacZone(newValue, tower, 'name')
                                    }}
                                component={renderInput}/>): null}
                        </td>
                        <td>
                            {!values.isEditing ? (<span>{values.note}</span>): null}
                            {values.isEditing ? (<Field
                                name={`${tower}.note`}
                                type='text'
                                disabled={!values.isEditing}
                                onChange={(e, newValue, previousValue, name) => {
                                    onChangeTowerFacZone(newValue, tower, 'note')
                                }}
                                component={renderTextarea}/>): null}
                        </td>
                        <td>
                            {!values.isEditing ? (<span>{values.sortOrder}</span>): null}
                            {values.isEditing ? (<Field
                                name={`${tower}.sortOrder`}
                                type='text'
                                disabled={!values.isEditing}
                                onChange={(e, newValue, previousValue, name) => {
                                    onChangeTowerFacZone(newValue, tower, 'sortOrder')
                                }}
                                validate={[validate.number, validate.integer]}
                                component={renderInput}/>): null}
                        </td>
                        {action !== 'view' && <td>
                            <Button color="link" size="sm" onClick={() => {createTowerFacZone(idxItem, 2, values);}}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i></Button>&nbsp;
                            <div style={{display: 'inline-block'}}>
                                <Field
                                    style={{width: '65px'}}
                                    name={`${tower}.number`}
                                    type='text'
                                    placeholder='S.lượng'
                                    validate={[validate.number, validate.integer]}
                                    component={renderInput}/>
                            </div>&nbsp;
                            <span style={{color: '#007bff'}}>
                            Tầng</span>
                        </td>}
                    </tr>
                    {(values.isCollapse !== null && values.isCollapse !== undefined && values.isCollapse) ? (<tr key={`${tower}.${index}`} className="level_2">
                        <td colSpan="5">
                            <FieldArray
                            name={`${tower}.Zones`}
                            projectItem={projectItem}
                            indexTower={index}
                            collapseTowerFacZone={collapseTowerFacZone}
                            editingTowerFacZone={editingTowerFacZone}
                            cancelEditTowerFacZone={cancelEditTowerFacZone}
                            createTowerFacZone={createTowerFacZone}
                            handleRemoveItem={handleRemoveItem}
                            submitTowerFacZone={submitTowerFacZone}
                            component={renderZones}
                            onChangeTowerFacZone={onChangeTowerFacZone}
                            action={action}/>
                        </td>
                    </tr>): null}
                    </Fragment>
                );
            })}
        </tbody>
    </Table>
)

// Ky su
const renderNOWs = ({ fields, usersActiveList, indexContractor, indexTeam, handleRemoveItem, editingContractorTeamUser, deleteContractorTeamUser, submitContractorTeamUser, onChangeContractorTeamUser, meta: { error }, action }) => (
    <>
    {fields !== null && fields !== undefined &&
        <Table hover className="tbl-project">
            <thead>
            <tr>
                <th className="action"></th>
                <th>Tên kỹ sư</th>
            </tr>
            </thead>
            <tbody>
                {fields !== null && fields !== undefined && fields.length === 0 && <tr>
                    <td colSpan="3">Không có dữ liệu</td>
                </tr>}
                {fields.map((user, index) => {
                    const values = fields.get(index);
                    if(_.isObject(values.user)){
                      values.userName = values.user.userName
                    }else {
                      values.user = values.userName;
                    }
                    const isValid = values.user !== undefined && values.user !== null && _.isObject(values.user);
                    return(
                        <tr key={`${user}`}>
                            <td>
                                {action !== 'view' && <>{!values.isEditing ? (<><Button className="i-btn cta cta-delete" onClick={() => {handleRemoveItem({idxItem: `contractors[${indexContractor}].Teams[${indexTeam}].Users[${index}]`, type: 3, kindOf:'contractor'});}}>
                                <i className="fa fa-trash-o" aria-hidden="true"></i></Button>&nbsp;&nbsp;</>) : null}</>}
                                {/*{!values.isEditing ? (
                                    <Button
                                        className="i-btn cta cta-save"
                                        onClick={() => {editingContractorTeamUser(3, indexContractor, indexTeam, index)}}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </Button>): null}*/}
                                
                                {values.isEditing && isValid ? (<Button className="i-btn cta cta-save" onClick={() => {
                                    let values = fields.get(index);
                                        submitContractorTeamUser(`contractors[${indexContractor}].Teams[${indexTeam}]`, values, 3);
                                }}><i className="fa fa-save" aria-hidden="true"></i></Button>): null}

                                {values.isEditing && <Button
                                    className="i-btn cta cta-save"
                                    onClick={() => {deleteContractorTeamUser(`contractors[${indexContractor}].Teams[${indexTeam}].Users[${index}]`, 3)}}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>}
                            </td>
                            <td>
                            {!values.isEditing ? (<span>{values.userName}</span>): null}
                            {/* {values.isEditing ? (<Field name={`${user}.user`} validate={validate.required}
                                    component={renderDropdownList}
                                    valueField ='userName'
                                    textField ='userName'
                                    dropUp={true}
                                    filter={true}
                                    disabled={!fields.get(index).isEditing}
                                    onChange={(e, newValue, previousValue, name) => {
                                        onChangeContractorTeamUser(3, newValue, `contractors[${indexContractor}].Teams[${indexTeam}].Users[${index}]`)
                                    }}
                                    data={usersActiveList !== null ? usersActiveList : []}/>): null} */}
                            {values.isEditing ? (<Field name={`${user}.user`} validate={validate.required}
                                    component={renderMultiselect}
                                    valueField ='userName'
                                    textField ='userName' 
                                    dropUp={true}
                                    filter='contains'
                                    disabled={!values.isEditing}
                                    // onChange={(e, newValue, previousValue, name) => {
                                    //     onChangeContractorTeamUser(3, newValue, `contractors[${indexContractor}].Teams[${indexTeam}].Users[${index}]`)
                                    // }}
                                    data={usersActiveList !== null ? usersActiveList : []}/>): null}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    }
    </>
)

// To doi
const renderTeams = ({ fields, projectItem, indexContractor, usersActiveList, teams, collapseContractorTeamUser, handleRemoveItem, editingContractorTeamUser, deleteContractorTeamUser, createContractorTeamUser, submitContractorTeamUser, onChangeContractorTeamUser, meta: { error }, action }) => (
    <>
    {fields !== null && fields !== undefined &&
        <Table hover className="tbl-project">
            <thead>
            <tr>
                <th className="action-btn"></th>
                <th>Tên tổ / đội</th>
                {action !== 'view' && <th className="action-btn"></th>}
            </tr>
            </thead>
            <tbody>
                {fields !== null && fields !== undefined && fields.length === 0 && <tr>
                    <td colSpan="3">Không có dữ liệu</td>
                </tr>}
                {fields.map((team, index) => {
                    const values = fields.get(index);
                    if(_.isObject(values.team)){
                      values.teamName = values.team.teamName
                    }else {
                      values.team = values.teamName;
                    }
                    const isValid = values.team !== undefined && values.team !== null && _.isObject(values.team);
                    return(
                        <Fragment key={`team-${index}`}><tr key={`${team}`}>
                            <td>
                                <Button className="i-btn cta cta-delete" onClick={() => {collapseContractorTeamUser(`contractors[${indexContractor}].Teams[${index}]`);}}>
                                    <i className={'fa fa-angle-' + ((values.isCollapse !== null && values.isCollapse !== undefined && values.isCollapse) ? 'down' : 'right')} aria-hidden="true"></i>
                                </Button>&nbsp;
                                {action !== 'view' && <>{!values.isEditing ? (<><Button className="i-btn cta cta-delete" onClick={() => {handleRemoveItem({idxItem :`contractors[${indexContractor}].Teams[${index}]`, type: 2, kindOf:'contractor'});}}>
                                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                                </Button>&nbsp;&nbsp;</>) : null}</>}
                                {/*{!values.isEditing ? (
                                    <Button
                                        className="i-btn cta cta-save"
                                        onClick={() => {editingContractorTeamUser(2,indexContractor, index, null)}}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </Button>): null}*/}
                                
                                {values.isEditing && isValid ? (<Button className="i-btn cta cta-save" onClick={() => {
                                    let values = fields.get(index);
                                    submitContractorTeamUser(`contractors[${indexContractor}]`,values, 2);
                                }}><i className="fa fa-save" aria-hidden="true"></i></Button>): null}

                                {values.isEditing && <Button
                                    className="i-btn cta cta-save"
                                    onClick={() => {deleteContractorTeamUser(`contractors[${indexContractor}].Teams[${index}]`, 2)}}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>}
                            </td>
                            <td>
                                {!values.isEditing ? (<span>{values.teamName}</span>): null}
                                {/* {values.isEditing ? (<Field name={`${team}.team`} validate={validate.required}
                                    component={renderDropdownList}
                                    valueField ='teamName'
                                    textField ='teamName'
                                    dropUp={true}
                                    filter={true}
                                    disabled={!values.isEditing}
                                    onChange={(e, newValue, previousValue, name) => {
                                        onChangeContractorTeamUser(2, newValue, `contractors[${indexContractor}].Teams[${index}]`)
                                    }}
                                    data={teams !== null ? teams : []}/>): null} */}
                                {values.isEditing ? (<Field name={`${team}.team`} validate={validate.required}
                                    component={renderMultiselect}
                                    valueField ='teamName'
                                    textField ='teamName' 
                                    dropUp={true}
                                    filter='contains'
                                    disabled={!values.isEditing}
                                    // onChange={(e, newValue, previousValue, name) => {
                                    //     onChangeContractorTeamUser(2, newValue, `contractors[${indexContractor}].Teams[${index}]`)
                                    // }}
                                    data={teams !== null ? teams : []}/>): null}
                            </td>
                            {action !== 'view' && <td>
                                <Button color="link" size="sm" onClick={() => {createContractorTeamUser(`contractors[${indexContractor}].Teams[${index}]`, 3);}}>
                                    <i className="fa fa-plus-circle" aria-hidden="true"></i> Kỹ sư</Button>
                            </td>}
                        </tr>
                        {(values.isCollapse !== null && values.isCollapse !== undefined && values.isCollapse) ? (<tr className="level_3">
                            <td colSpan="5">
                                <FieldArray
                                    name={`${team}.Users`}
                                    usersActiveList={usersActiveList}
                                    indexContractor={indexContractor}
                                    indexTeam={index}
                                    handleRemoveItem={handleRemoveItem}
                                    editingContractorTeamUser={editingContractorTeamUser}
                                    deleteContractorTeamUser={deleteContractorTeamUser}
                                    submitContractorTeamUser={submitContractorTeamUser}
                                    component={renderNOWs}
                                    onChangeContractorTeamUser={onChangeContractorTeamUser}
                                    action={action} />
                            </td>
                        </tr>): null}</Fragment>
                    )
                })}
            </tbody>
        </Table>
    }
    </>
)

// Nha Thau
const renderContractors = ({ fields, projectItem, contractors, usersActiveList, teams, collapseContractorTeamUser, editingContractorTeamUser, deleteContractorTeamUser, handleRemoveItem, createContractorTeamUser, submitContractorTeamUser, onChangeContractorTeamUser, meta: { error }, action }) => (
    <Table hover className={"tbl-project " + (action === 'view' ? 'view contractor' : null)}>
        <thead>
          <tr>
            <th className="action-btn"></th>
            <th>Tên nhà thầu</th>
            {action !== 'view' && <th className="action-btn">
                <Button style={{color:'white'}} color="link" size="sm" onClick={() => {createContractorTeamUser(`contractors`, 1);}}>
                    <i className="fa fa-plus-circle" aria-hidden="true"></i> Nhà thầu
                </Button>
            </th>}
          </tr>
        </thead>
        <tbody>
            {fields !== null && fields !== undefined && fields.length === 0 && <tr>
                <td colSpan="5">Không có dữ liệu</td>
            </tr>}
            {fields.map((contractor, index) => {
                const values = fields.get(index);
                if(_.isObject(values.contractor)){
                  values.contractorName = values.contractor.contractorName
                }else {
                  values.contractor = values.contractorName;
                }
                const isValid = values.contractor !== undefined && values.contractor !== null && _.isObject(values.contractor);
                return(
                    <Fragment key={`contractor-${index}`}><tr key={`${contractor}`}>
                        <td>
                            <Button className="i-btn cta cta-delete" onClick={() => {collapseContractorTeamUser(`contractors[${index}]`);}}>
                                <i className={'fa fa-angle-' + ((values.isCollapse !== null && values.isCollapse !== undefined && values.isCollapse) ? 'down' : 'right')} aria-hidden="true"></i>
                            </Button>&nbsp;
                            {action !== 'view' && <>{!values.isEditing ? (<><Button className="i-btn cta cta-delete" onClick={() => {handleRemoveItem({idxItem: `contractors[${index}]`, type: 1, kindOf:'contractor'});}}>
                                <i className="fa fa-trash-o" aria-hidden="true"></i>
                            </Button>&nbsp;&nbsp;</>) : null}</>}
                            {/*{!values.isEditing ? (
                                <Button
                                    className="i-btn cta cta-save"
                                    onClick={() => {editingContractorTeamUser(1, index, null, null);}}>
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                </Button>): null}*/}

                            {values.isEditing && isValid ? (
                                <Button className="i-btn cta cta-save" onClick={() => {
                                    let values = fields.get(index);
                                    submitContractorTeamUser(`contractors`, values, 1);}}>
                                    <i className="fa fa-save" aria-hidden="true"></i>
                                </Button>): null}
                            {values.isEditing && <Button
                                className="i-btn cta cta-save"
                                onClick={() => {deleteContractorTeamUser(`contractors[${index}]`, 1);}}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </Button>}
                        </td>
                        <td>
                        {!values.isEditing ? (<span>{values.contractorName}</span>): null}
                        {/* {values.isEditing ? (<Field name={`${contractor}.contractor`} validate={validate.required}
                                component={renderDropdownList}
                                valueField ='contractorName'
                                textField ='contractorName'
                                dropUp={true}
                                filter={true}
                                disabled={!values.isEditing}
                                onChange={(e, newValue, previousValue, name) => {
                                    onChangeContractorTeamUser(1, newValue, contractor)
                                }}
                                data={contractors !== null ? contractors : []}/>): null} */}
                        
                        {values.isEditing ? (<Field name={`${contractor}.contractor`} validate={validate.required}
                            component={renderMultiselect}
                            valueField ='contractorName'
                            textField ='contractorName' 
                            dropUp={true}
                            filter='contains'
                            disabled={!values.isEditing}
                            // onChange={(e, newValue, previousValue, name) => {
                            //     onChangeContractorTeamUser(1, newValue, contractor)
                            // }}
                            data={contractors !== null ? contractors : []}/>): null}
                        </td>
                        {action !== 'view' && <td>
                            <Button color="link" size="sm" onClick={() => {createContractorTeamUser(`contractors[${index}]`, 2);}}>
                                <i className="fa fa-plus-circle" aria-hidden="true"></i> Tổ / Đội</Button>
                        </td>}
                    </tr>
                    {(values.isCollapse !== null && values.isCollapse !== undefined && values.isCollapse) ? (<tr className="level_2">
                        <td colSpan="5">
                            <FieldArray
                                name={`${contractor}.Teams`}
                                projectItem={projectItem}
                                indexContractor={index}
                                usersActiveList={usersActiveList}
                                teams={teams}
                                collapseContractorTeamUser={collapseContractorTeamUser}
                                handleRemoveItem={handleRemoveItem}
                                editingContractorTeamUser={editingContractorTeamUser}
                                deleteContractorTeamUser={deleteContractorTeamUser}
                                createContractorTeamUser={createContractorTeamUser}
                                submitContractorTeamUser={submitContractorTeamUser}
                                component={renderTeams}
                                onChangeContractorTeamUser={onChangeContractorTeamUser}
                                action={action} />
                        </td>
                    </tr>): null}
                    </Fragment>
                )
            })}
        </tbody>
    </Table>
)

export default ProjectDetailForm;
