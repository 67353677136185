import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector, reset, initialize} from 'redux-form';
import { categoryAction } from '../actions/categoryAction';
import ProjectMasterForm from '../presentations/ProjectMasterForm';
import { Button } from 'reactstrap';
import {withRouter} from 'react-router-dom';
import commonWrapped from '../hocs/commonWrapped';
import { loadingUtility } from '../utility/loading';

class ProjectMaster extends Component {
    constructor(props, context) {
        super(props, context);
    }

    backtoProjectCategory = () => {
        this.props.history.push(`/danh-muc/du-an`);
    }
    render() {
        const rs = _.get(window.ePayment, 'resource');
        const { handleSubmit, pristine, submitting, valid, projectStatus, categories, usersActiveList, initialValues, projectItem, action, canUpdateDetail, checkPermissionFeature } = this.props;
        return (
            <div className="main-box">
                <h5 className="page-header">
                    Dự án
                </h5>
                <form onSubmit={handleSubmit} className="filter-group form-request">

                    <div className="action-group">
                        {action !== 'view' && <Button color="info" type="submit" disabled={!valid || pristine || submitting}>{_.get(rs, 'btnNames.save')}</Button>}
                        <Button color="secondary" type="button" onClick={this.backtoProjectCategory}>{_.get(rs, 'btnNames.back')}</Button>
                    </div>
                    <ProjectMasterForm
                        projectItem={projectItem}
                        initialValues={initialValues}
                        projectStatus={projectStatus}
                        categories={categories}
                        usersActiveList={usersActiveList}
                        canUpdateDetail={canUpdateDetail}
                        checkPermissionFeature={checkPermissionFeature}
                        action={action}
                        />
                </form>
            </div>
        )
    }
}

const selector = formValueSelector('projectCatelogForm');
// const loadingSelector = loadingUtility(['CATELOGORY_GETLISTS_SUCCESS','PROJECT_STATUS_SUCCESS', 'LIST_ACTIVEUSERS_SUCCESS','PROJECT_INFO_SUCCESS']);

const mapStateToProps = state => {
    return {
        // isFetching: loadingSelector(state),
        initialValues: state.categoryReducer.projectItem
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    // SUBMIT MASTER INFO PROJECT
    onSubmit: values => {
        let alter_props = props;
        let alterValues = values;

        if (!_.isUndefined(values.statusName.rowID)) alterValues.statusID = values.statusName.rowID;
        if (!_.isUndefined(values.statusName.name)) alterValues.statusName = values.statusName.name;

        if (!_.isUndefined(values.parentID)) values.parentID = values.parentID.id;
        if (!_.isUndefined(values.sortOrder)) values.sortOrder = Number(values.sortNumber);
        if (!_.isUndefined(values.id) && values.id > 0) {
            alter_props = { ...props, type_action: 'update', catalogName: 'project' };
        } else {
            alter_props = { ...props, type_action: 'add', catalogName: 'project' };
        }
        dispatch(categoryAction.submitCategory(alter_props, alterValues));
        // dispatch(initialize('projectCatelogForm', alterValues, false, { pristine: false }));
        dispatch(reset('projectCatelogForm'));
    }
});


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'projectCatelogForm',
        enableReinitialize: true,// this is needed to refresh initial-values!!,
    }),
    // commonWrapped({ showLoadingWholePage: true }),
    withRouter
)(ProjectMaster);
