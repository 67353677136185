import {commonActionTypes} from '../constants/commonActionTypes';
export const commonActions = {
    request,
    success,
    failure,
    showDialog,
    closeDialog,
};
function request(type_action, data) { return { type: type_action, data } }
function success(type_action, data) { return { type: type_action, data } }
function failure(type_action, error) { return { type: type_action, error } }
function showDialog(){
    return{
        type:commonActionTypes.DIALOG_DL_OPEN
    }
}
function closeDialog(){
    return{
        type:commonActionTypes.DIALOG_DL_CLOSE
    }
}