import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import _ from 'lodash';
import * as serviceWorker from './serviceWorker';
import { history } from './utility/history';
import store from './store/store';
import App from './App';
import httpService from './utility/httpService';
import {common} from './utility/common';

import './index.css';
import './stylesheet/main.scss';

window.ePayment={};
httpService.setupInterceptors(store,history);

axios.get('/resources/app.json').then(function(response){
    if(common.isTokenExpired()){
        //clear data
        common.clearToken();
    }
    _.assign(window.ePayment, {
        resource: response.data
    });
    ReactDOM.render(<App history={history} store={store}/>, document.getElementById('e-safety'));
}).catch(error => console.error('Error:', error));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
