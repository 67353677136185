import config from 'config';
// import { authHeader } from '../helpers/auth-header';
import axios from 'axios';
import apiUrl from '../constants/apiUrl';
import ApiEndpoints from '../constants/ApiEndpoints';
import {common} from '../utility/common';
import {globalConstants} from '../constants/global';
export const userService = {
    login,
    changePassword,
    logout,
    refreshToken,
    getItems,
    addItem,
    deleteItem,
    updateItem,
    //checkTokenExpired,
    getActiveUsers,

    // NHAN VIEN Form
    register,
    getAllUsers,
    updateUser,
    importUsers,
    resetUserPassword
};

function login(username, password) {
    let params = {
        'username': username,
        'password': password,
        'grant_type': 'password',
        'client_id': 'web'
    };
    const searchParams = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');
    let url = `${config.apiUrl}/${apiUrl.login}`;
    // return axios.post(url, searchParams).then(res=>{
    //     if (res.data.access_token) {
    //         localStorage.setItem(globalConstants.USER, JSON.stringify(res.data));
    //         return res.data;
    //     }
    // }).catch(e=>{
    //     console.log(e);
    // });
    return axios.post(url, searchParams);
}
function changePassword(oldPassword, newPassword, confirmPassword){
    let params = {
        'oldPassword': oldPassword,
        'password': newPassword,
        'confirmPassword': confirmPassword
    };
    const url = `${config.apiUrl}/${apiUrl.changePass}`;

    const searchParams = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return axios.post(url, searchParams);
}
function refreshToken(oldRequest){
    let params = {
        'grant_type': 'refresh_token',
        'client_id': 'web',
        'refresh_token': common.getRefreshToken()
    };
    const searchParams = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');
    let url = `${config.apiUrl}/${apiUrl.login}`;
    localStorage.removeItem(globalConstants.USER);
    return axios.post(url, searchParams).then(res=>{
        if (res.data.access_token) {
            localStorage.setItem(globalConstants.USER, JSON.stringify(res.data));
        }
        return Promise.resolve({
            response: res,
            request: oldRequest
        });
    }).catch(e=>{
        return Promise.reject(e);
    });
}
// function checkTokenExpired(){
//     let token = localStorage.getItem(globalConstants.USER);
//     token = JSON.parse(token);
//     if( new Date(token[".expires"]) > new Date() ){
//         console.log('token expires OK');
//         return true;
//     }
//     return false;
// }
function logout() {
    common.clearToken();
}
function getItems() {
    let url = `${config.apiUrl}/${apiUrl.user}/items`;
    return axios.get(url).then(res=>{
        return res.data.content.items;
    }).catch(e=>{
        console.log(e);
    });
}
function addItem(obj) {
    let url = `${config.apiUrl}/${apiUrl.user}`;
    return axios.post(url, obj).then(res=>{
        Promise.resolve(res.data.content);
    },e=>{
        Promise.reject(e);
    });
}
function deleteItem(obj) {
    console.log(obj);
    let url = `${config.apiUrl}/${apiUrl.user}`;
    return axios.delete(url, { data: { id: obj.id } }).then(res=>{
        console.log(res.data.content);
    });
}
function updateItem(obj) {
    console.log(obj);
    let url = `${config.apiUrl}/${apiUrl.user}`;
    return axios.put(url, { data: { obj } }).then(res=>{
        Promise.resolve(res.data.content);
    },e=>{
        Promise.reject(e);
    });
}

function getAllUsers() {
    let url = `${config.apiUrl}/${ApiEndpoints.user}/Items`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function getActiveUsers() {
    let url = `${config.apiUrl}/${ApiEndpoints.user}/GetActiveUsers`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function updateUser(data) {
    let url = `${config.apiUrl}/${ApiEndpoints.user}/Update`;
    return axios.put(url, data).then(res=>{
        Promise.resolve(res.data.content);
    },e=>{
        Promise.reject(e);
    });
}

function register(data){
  let url = `${config.apiUrl}/${apiUrl.register}`;
  if(data.UseDefaultPassword){
    data.password = "123456";
    data.confirmPassword = "123456";
  }
  return new Promise((resolve,reject)=>{
      axios.post(url, data).then(res=>{
          resolve(res.data.content);
      },e=>{
          reject(e);
      });
  });
}

function importUsers(data){
    let url = `${config.apiUrl}/${apiUrl.importUsers}`;
    let formData = new FormData();
    var name = data.import.name;
    formData.append('file', data.import , name);
    return new Promise((resolve,reject)=>{
        axios.post(url, formData)
        .then(res=>{
            resolve(res);
        },e=>{
            reject(e);
            
        });
    });
  }
  
function resetUserPassword(data) {
    let url = `${config.apiUrl}/${apiUrl.resetUserPassword}`+ data;
    return new Promise((resolve,reject)=>{
        axios.get(url).then(res=>{
            resolve(res.status);
        },e=>{
            reject(e);
        });
    }); 
}