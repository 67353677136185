import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';
import {actionTypes} from '../constants/actionTypes';

import AccidentForm from '../presentations/AccidentForm';
import ContractorForm from '../presentations/ContractorForm';
import ImageForm from '../presentations/ImageForm';
import TrainingForm from '../presentations/TrainingForm';
import ViolationForm from '../presentations/ViolationForm';
import PunishmentForm from '../presentations/PunishmentForm';
import HazardousForm from '../presentations/HazardousForm';
import TeamForm from '../presentations/TeamForm';


const CategoryFormModal = (props) =>  {
    const rs = _.get(window.ePayment,'resource');
    const { isOpen, toggleFn, typeForm, catalogName } = props;
    const { handleSubmit, pristine, submitting, valid, dispatch} = props.props;
    return(
        <div>
            <Modal isOpen={isOpen}
                toggle={toggleFn}
                className={catalogName === 'project' ? 'modal-xl' : ''} wrapClassName='e-safety' backdrop='static'>
                <form onSubmit={handleSubmit((values)=>{props.props.onSubmit(values);})}>
                    <ModalHeader toggle={toggleFn}>{typeForm ==='add' ? <>{_.get(rs,'appModal.addTitle')}</> : <>{_.get(rs,'appModal.editTitle')}</>}</ModalHeader>
                    <ModalBody>
                        {catalogName === 'accident' && <AccidentForm />}
                        {catalogName === 'contractor' && <ContractorForm/>}
                        {catalogName === 'image' && <ImageForm />}
                        {catalogName === 'training' && <TrainingForm />}
                        {catalogName === 'violation' && <ViolationForm catalogName={catalogName} violationParents={props.violationParents} types={props.types}/>}
                        {catalogName === 'punishment' && <PunishmentForm catalogName={catalogName} punishmentParents={props.punishmentParents} />}
                        {catalogName === 'hazardous' && <HazardousForm hazardousParents={props.hazardousParents} />}
                        {catalogName === 'team' && <TeamForm />}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" type="submit" disabled={!valid || pristine || submitting}>{_.get(rs,'btnNames.save')}</Button>{' '}
                        <Button color="secondary" onClick={e => {
                            toggleFn&&toggleFn(); 
                            dispatch({type: actionTypes.CATELOGORY_ITEM_SELECTED,data: null});
                        }}>{_.get(rs,'btnNames.cancel')}</Button>
                    </ModalFooter>       
                </form>
            </Modal>
        </div>
    )
}

export default CategoryFormModal;