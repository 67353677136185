import React, {PropTypes} from 'react';
import {FieldProps} from './FieldProps';
import {inputError} from './inputError';
import iconChecked from '../images/icon-checked.svg';

const renderCheckbox = ({input, showError, showWarn, ...rest}) =>{
	return (
		<>
		
			<div className="checkbox">
				<label>
					<input
						{...input}
						{...rest}
						className={`${showError ? 'is-invalid' : ''}`} />
					<span className="icon-checked">
						<img className="icon" src={iconChecked} alt="icon" />
					</span>
				</label>
			</div>
		</>
	);
}

renderCheckbox.propTypes = {
	...FieldProps
};

export default inputError(renderCheckbox);
