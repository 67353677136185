import React, { Component} from 'react';
import _ from 'lodash';

class AppFooter extends Component {

    constructor(props) {
        super(props);

        this.state={
            rs: _.get(window.ePayment,'resource')
        }
    }

    render(){
        return (
            <div className="footer text-center">
                <>{_.get(this.state.rs,'appFooter.title')}</>
            </div>
        )
    }
}

export default AppFooter;
