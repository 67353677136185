export const actionTypes = {

    USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    USER_INFO_REQUEST: 'USER_INFO_REQUEST',
    USER_INFO_SUCCESS: 'USER_INFO_SUCCESS',
    USER_INFO_FAILURE: 'USER_INFO_FAILURE',

    USER_CHANGEPASS_REQUEST: 'USER_LOGIN_REQUEST',
    USER_CHANGEPASS_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_CHANGEPASS_FAILURE: 'USER_LOGIN_FAILURE',

    USER_REGISTER: 'USER_REGISTER',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_STORAGE: 'USER_STORAGE',

    USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    USER_REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

    USER_IMPORT_REQUEST:'USER_IMPORT_REQUEST',
    USER_IMPORT_SUCCESS:'USER_IMPORT_SUCCESS',
    USER_IMPORT_FAILURE:'USER_IMPORT_FAILURE',
    UPDATE_IMPORT_STATUS:'UPDATE_IMPORT_STATUS',

    USER_RESET_PASSWORD_REQUEST:'USER_RESET_PASSWORD_REQUEST',
    USER_RESET_PASSWORD_SUCCESS:'USER_RESET_PASSWORD_SUCCESS',
    USER_RESET_PASSWORD_FAILURE:'USER_RESET_PASSWORD_FAILURE',

    USER_DATA_REQUEST: 'USER_DATA_REQUEST',
    USER_DATA_SUCCESS: 'USER_DATA_SUCCESS',
    USER_DATA_FAILURE: 'USER_DATA_FAILURE',

    UPDATE_USERS_DATA: 'UPDATE_USERS_DATA',
    USER_ITEM_SELECTED: 'USER_ITEM_SELECTED',

    LIST_ACTIVEUSERS_REQUEST: 'LIST_ACTIVEUSERS_REQUEST',
    LIST_ACTIVEUSERS_SUCCESS: 'LIST_ACTIVEUSERS_SUCCESS',
    LIST_ACTIVEUSERS_FAILURE: 'LIST_ACTIVEUSERS_FAILURE',

    LIST_USERS_REQUEST: 'LIST_USERS_REQUEST',
    LIST_USERS_SUCCESS: 'LIST_USERS_SUCCESS',
    LIST_USERS_FAILURE: 'LIST_USERS_FAILURE',

    CATEGORY_DATA_REQUEST: 'CATEGORY_DATA_REQUEST',
    CATEGORY_DATA_SUCCESS: 'CATEGORY_DATA_SUCCESS',
    CATEGORY_DATA_FAIL: 'CATEGORY_DATA_FAIL',

    CATELOGORY_REQUEST: 'CATELOGORY_REQUEST',
    CATELOGORY_SUCCESS: 'CATELOGORY_SUCCESS',
    CATELOGORY_FAILURE: 'CATELOGORY_FAILURE',
    CATELOGORY_ITEM_SELECTED: 'CATALOG_ITEM_SELECTED',

    CATELOGORY_PROJECT_REQUEST: 'CATELOGORY_PROJECT_REQUEST',
    CATELOGORY_PROJECT_SUCCESS: 'CATELOGORY_PROJECT_SUCCESS',
    CATELOGORY_PROJECT_FAILURE: 'CATELOGORY_PROJECT_FAILURE',
    CATELOGORY_PROJECT_UPDATE_SUCCESS: 'CATELOGORY_PROJECT_UPDATE_SUCCESS',
    CATELOGORY_CRUD_SUCCESS_CLOSE_WAITING: 'CATELOGORY_CRUD_SUCCESS_CLOSE_WAITING',

    CATELOGORY_GETLIST_REQUEST: 'CATELOGORY_GETLIST_REQUEST',
    CATELOGORY_GETLIST_SUCCESS: 'CATELOGORY_GETLIST_SUCCESS',
    CATELOGORY_GETLIST_FAILURE: 'CATELOGORY_GETLIST_FAILURE',

    CATELOGORY_GETPARENTLIST_REQUEST: 'CATELOGORY_GETPARENTLIST_REQUEST',
    CATELOGORY_GETPARENTLIST_SUCCESS: 'CATELOGORY_GETPARENTLIST_SUCCESS',
    CATELOGORY_GETPARENTLIST_FAILURE: 'CATELOGORY_GETPARENTLIST_FAILURE',

    CATELOGORY_CRUD_REQUEST: 'CATELOGORY_CRUD_REQUEST',
    CATELOGORY_CRUD_SUCCESS: 'CATELOGORY_CRUD_SUCCESS',
    CATELOGORY_CRUD_FAILURE: 'CATELOGORY_CRUD_FAILURE',
    CATELOGORY_GETLISTS_SUCCESS:"CATELOGORY_GETLISTS_SUCCESS",

    GET_VIOLATION_CATEGORY_TYPE_REQUEST: 'GET_VIOLATION_CATEGORY_TYPE_REQUEST',
    GET_VIOLATION_CATEGORY_TYPE_SUCCESS: 'GET_VIOLATION_CATEGORY_TYPE_SUCCESS',
    GET_VIOLATION_CATEGORY_TYPE_FAILURE: 'GET_VIOLATION_CATEGORY_TYPE_FAILURE',

    UPDATE_CATEGORIES_DATA: 'UPDATE_CATEGORIES_DATA',
    RESET_FILTER_FORM: 'RESET_FILTER_FORM',

    PROJECT_STATUS_REQUEST: 'PROJECT_STATUS_REQUEST',
    PROJECT_STATUS_SUCCESS: 'PROJECT_STATUS_SUCCESS',
    PROJECT_STATUS_FAILURE: 'PROJECT_STATUS_FAILURE',

    PROJECT_INFO_REQUEST: 'PROJECT_INFO_REQUEST',
    PROJECT_INFO_SUCCESS: 'PROJECT_INFO_SUCCESS',
    PROJECT_INFO_FAILURE: 'PROJECT_INFO_FAILURE',

    PROJECT_TOWER_FAC_ZONE_REQUEST: 'PROJECT_TOWER_FAC_ZONE_REQUEST',
    PROJECT_TOWER_FAC_ZONE_SUCCESS: 'PROJECT_TOWER_FAC_ZONE_SUCCESS',
    PROJECT_TOWER_FAC_ZONE_FAILURE: 'PROJECT_TOWER_FAC_ZONE_FAILURE',

    PROJECT_TOWER_FAC_ZONE_MULTI_REQUEST: 'PROJECT_TOWER_FAC_ZONE_MULTI_REQUEST',
    PROJECT_TOWER_FAC_ZONE_MULTI_SUCCESS: 'PROJECT_TOWER_FAC_ZONE_MULTI_SUCCESS',
    PROJECT_TOWER_FAC_ZONE_MULTI_FAILURE: 'PROJECT_TOWER_FAC_ZONE_MULTI_FAILURE',

    GET_PROJECT_TOWER_FAC_ZONE_REQUEST: 'GET_PROJECT_TOWER_FAC_ZONE_REQUEST',
    GET_PROJECT_TOWER_FAC_ZONE_SUCCESS: 'GET_PROJECT_TOWER_FAC_ZONE_SUCCESS',
    GET_PROJECT_TOWER_FAC_ZONE_FAILURE: 'GET_PROJECT_TOWER_FAC_ZONE_FAILURE',

    PROJECT_CONTRACTOR_TEAM_ENGINEER_REQUEST: 'PROJECT_CONTRACTOR_TEAM_ENGINEER_REQUEST',
    PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS: 'PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS',
    PROJECT_CONTRACTOR_TEAM_ENGINEER_FAILURE: 'PROJECT_CONTRACTOR_TEAM_ENGINEER_FAILURE',

    GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_REQUEST: 'GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_REQUEST',
    GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS: 'GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_SUCCESS',
    GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_FAILURE: 'GET_PROJECT_CONTRACTOR_TEAM_ENGINEER_FAILURE',



    // PermissionPage
    PERMISSION_DATA_REQUEST: 'PERMISSION_DATA_REQUEST',
    PERMISSION_DATA_SUCCESS: 'PERMISSION_DATA_SUCCESS',
    PERMISSION_DATA_FAILURE: 'PERMISSION_DATA_FAILURE',

    GET_USERINGROUP_REQUEST: 'GET_USERINGROUP_REQUEST',
    GET_USERINGROUP_SUCCESS: 'GET_USERINGROUP_SUCCESS',
    GET_USERINGROUP_FAILURE: 'GET_USERINGROUP_FAILURE',

    GET_FEATUREGROUP_REQUEST: 'GET_FEATUREGROUP_REQUEST',
    GET_FEATUREGROUP_SUCCESS: 'GET_FEATUREGROUP_SUCCESS',
    GET_FEATUREGROUP_FAILURE: 'GET_FEATUREGROUP_FAILURE',

    GET_FEATUREUSER_REQUEST: 'GET_FEATUREUSER_REQUEST',
    GET_FEATUREUSER_SUCCESS: 'GET_FEATUREUSER_SUCCESS',
    GET_FEATUREUSER_FAILURE: 'GET_FEATUREUSER_FAILURE',

    SET_FEATUREGROUP_REQUEST: 'SET_FEATUREGROUP_REQUEST',
    SET_FEATUREGROUP_SUCCESS: 'SET_FEATUREGROUP_SUCCESS',
    SET_FEATUREGROUP_FAILURE: 'SET_FEATUREGROUP_FAILURE',

    SET_FEATUREUSER_REQUEST: 'SET_FEATUREUSER_REQUEST',
    SET_FEATUREUSER_SUCCESS: 'SET_FEATUREUSER_SUCCESS',
    SET_FEATUREUSER_FAILURE: 'SET_FEATUREUSER_FAILURE',

    GET_GROUP_REQUEST: 'GET_GROUP_REQUEST',
    GET_GROUP_SUCCESS: 'GET_GROUP_SUCCESS',
    GET_GROUP_FAILURE: 'GET_GROUP_FAILURE',

    SEARCH_USER_REQUEST: 'SEARCH_USER_REQUEST',
    SEARCH_USER_SUCCESS: 'SEARCH_USER_SUCCESS',
    SEARCH_USER_FAILURE: 'SEARCH_USER_FAILURE',

    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

    RESET_SEARCH_USER_MODAL: 'RESET_SEARCH_USER_MODAL',

    GET_PROJECT_BY_USER_SUCCESS: 'GET_PROJECT_BY_USER_SUCCESS',
    GET_PROJECT_BY_USER_FAILURE: 'GET_PROJECT_BY_USER_FAILURE',

    // Filter Report

    GET_ENGINEERS_SUCCESS: 'GET_ENGINEERS_SUCCESS',
    GET_ENGINEERS_FAILURE: 'GET_ENGINEERS_FAILURE',

    GET_CONTRACTOR_BY_PROJECT_REQUEST: 'GET_CONTRACTOR_BY_PROJECT_REQUEST',
    GET_CONTRACTOR_BY_PROJECT_SUCCESS: 'GET_CONTRACTOR_BY_PROJECT_SUCCESS',
    GET_CONTRACTOR_BY_PROJECT_FAILURE: 'GET_CONTRACTOR_BY_PROJECT_FAILURE',
    GET_ALL_CONTRACTOR_SUCCESS:'GET_ALL_CONTRACTOR_SUCCESS',
    GET_ALL_CONTRACTOR_REQUEST:'GET_ALL_CONTRACTOR_REQUEST',
    GET_ALL_CONTRACTOR_FAILURE: 'GET_ALL_CONTRACTOR_FAILURE',
   

    GET_TEAM_BY_CONTRACTOR_REQUEST: 'GET_TEAM_BY_CONTRACTOR_REQUEST',
    GET_TEAM_BY_CONTRACTOR_SUCCESS: 'GET_TEAM_BY_CONTRACTOR_SUCCESS',
    GET_TEAM_BY_CONTRACTOR_FAILURE: 'GET_TEAM_BY_CONTRACTOR_FAILURE',
    GET_ALL_TEAM_SUCCESS:'GET_ALL_TEAM_SUCCESS',
    GET_ALL_TEAM_REQUEST:'GET_ALL_TEAM_REQUEST',
    GET_ALL_TEAM_FAILURE: 'GET_ALL_TEAM_FAILURE',

    GET_TOWER_BY_PROJECT_REQUEST: 'GET_TOWER_BY_PROJECT_REQUEST',
    GET_TOWER_BY_PROJECT_SUCCESS: 'GET_TOWER_BY_PROJECT_SUCCESS',
    GET_TOWER_BY_PROJECT_FAILURE: 'GET_TOWER_BY_PROJECT_FAILURE',
    GET_NULL_TOWER_REQUEST:'GET_NULL_TOWER_REQUEST',
    GET_NULL_TOWER_SUCCESS:'GET_NULL_TOWER_SUCCESS',

    GET_LEVEL_BY_PROJECT_TOWER_REQUEST: 'GET_LEVEL_BY_PROJECT_TOWER_REQUEST',
    GET_LEVEL_BY_PROJECT_TOWER_SUCCESS: 'GET_LEVEL_BY_PROJECT_TOWER_SUCCESS',
    GET_LEVEL_BY_PROJECT_TOWER_FAILURE: 'GET_LEVEL_BY_PROJECT_TOWER_FAILURE',
    GET_NULL_LEVEL_SUCCESS:'GET_NULL_LEVEL_SUCCESS',

    GET_ZONE_BY_PROJECT_LEVEL_REQUEST: 'GET_ZONE_BY_PROJECT_LEVEL_REQUEST',
    GET_ZONE_BY_PROJECT_LEVEL_SUCCESS: 'GET_ZONE_BY_PROJECT_LEVEL_SUCCESS',
    GET_ZONE_BY_PROJECT_LEVEL_FAILURE: 'GET_ZONE_BY_PROJECT_LEVEL_FAILURE',
    GET_NULL_ZONE_SUCCESS:'GET_NULL_ZONE_SUCCESS',

    REPORT_HAZAROUS_REQUEST: 'REPORT_HAZAROUS_REQUEST',
    REPORT_HAZAROUS_SUCCESS: 'REPORT_HAZAROUS_SUCCESS',
    REPORT_HAZAROUS_FAILURE: 'REPORT_HAZAROUS_FAILURE',

    REPORT_DATA_REQUEST: 'REPORT_DATA_REQUEST',
    REPORT_DATA_SUCCESS: 'REPORT_DATA_SUCCESS',
    REPORT_DATA_FAILURE: 'REPORT_DATA_FAILURE'

}
