import React from 'react';
import { FormGroup, Label, Container,Row, Col} from 'reactstrap';
import { Field} from 'redux-form';
import renderInput from '../controls/renderInput';
import validate from '../validate/validate';
import _ from 'lodash';
import renderDropdownList from '../controls/renderDropdownList';
import renderMultiselect from '../controls/renderMultiselect';
import renderTextarea from '../controls/renderTextarea';

const ProjectMasterForm = (props) => {
        const rs = _.get(window.ePayment,'resource');
        const {action} = props;
        return (
            <Container fluid>
                <Row>
                    <Col md='4'>
                        <FormGroup>
                            <Label>{_.get(rs,'projectCategory.code')}</Label>
                            <Field name="projectCode" 
                                type='text' 
                                validate={validate.required} 
                                component={renderInput} 
                                disabled={action === 'view' || (!props.checkPermissionFeature('Project_Category', 'edit') && props.canUpdateDetail)}/>
                        </FormGroup>
                    </Col>
                    <Col md='4'>
                        <FormGroup>
                            <Label>{_.get(rs,'projectCategory.name')}</Label>
                            <Field name="projectName" 
                                type='text' 
                                validate={validate.required} 
                                component={renderInput} 
                                disabled={action === 'view' || (!props.checkPermissionFeature('Project_Category', 'edit') && props.canUpdateDetail)}/>
                        </FormGroup>
                    </Col>
                    <Col md='4'>
                        <FormGroup>
                            <Label>{_.get(rs,'projectCategory.status')}</Label>
                            <Field name="statusName" validate={validate.required}
                                    component={renderDropdownList}
                                    valueField ='rowID'
                                    defaultValue={props.projectStatus !== null ? props.projectStatus[0] : null}
                                    textField ='name'  disabled={action === 'view' || (!props.checkPermissionFeature('Project_Category', 'edit') && props.canUpdateDetail)}
                                    data={props.projectStatus !== null ? props.projectStatus : []}/>
                        </FormGroup>
                    </Col>
                    <Col md='12'>
                        <FormGroup>
                            <Label>Admin dự án</Label>
                            <Field name="users" validate={validate.required}
                                    component={renderMultiselect}
                                    textField ='userName'
                                    valueField ='userName' 
                                    disabled={action === 'view' || (!props.checkPermissionFeature('Project_Category', 'edit') && props.canUpdateDetail)}
                                    data={props.usersActiveList !== null ? props.usersActiveList : []}/>
                        </FormGroup>
                    </Col>
                    <Col md='12'>
                        <FormGroup>
                            <Label>{_.get(rs,'projectCategory.siteEngineer')}</Label>
                            <Field name="engineers" validate={validate.required}
                                    component={renderMultiselect}
                                    valueField='userName'
                                    textField ='userName' 
                                    disabled={action === 'view' || (!props.checkPermissionFeature('Project_Category', 'edit') && props.canUpdateDetail)}
                                    data={props.usersActiveList !== null ? props.usersActiveList : []}/>
                        </FormGroup>
                    </Col>
                    <Col md='12'>
                        <FormGroup>
                            <Label>{_.get(rs,'projectCategory.siteManager')}</Label>
                            <Field name="siteCommanders" validate={validate.required}
                                    component={renderMultiselect}
                                    textField ='userName'
                                    valueField='userName' disabled={action === 'view' || (!props.checkPermissionFeature('Project_Category', 'edit') && props.canUpdateDetail)}
                                    data={props.usersActiveList !== null ? props.usersActiveList : []}/>
                        </FormGroup>
                    </Col>
                    <Col md='12'>
                        <FormGroup>
                            <Label>Nhóm nhận thông báo</Label>
                            <Field name="groupsNotifications" validate={validate.required}
                                    component={renderMultiselect}
                                    textField ='userName'
                                    valueField='userName' disabled={action === 'view' || (!props.checkPermissionFeature('Project_Category', 'edit') && props.canUpdateDetail)}
                                    data={props.usersActiveList !== null ? props.usersActiveList : []}/>
                        </FormGroup>
                    </Col>
                    <Col md='12' style={{marginTop: '30px'}}>
                        <FormGroup>
                            <Label>Quản lý vi phạm</Label>
                        </FormGroup>
                    </Col>
                    <Col md='12' style={{paddingLeft: '30px'}}>
                        <FormGroup>
                            <Label>Safety manager</Label>
                            <Field name="safetyManager"
                                    component={renderMultiselect}
                                    textField ='userName'
                                    valueField='userName' disabled={action === 'view' || (!props.checkPermissionFeature('Project_Category', 'edit') && props.canUpdateDetail)}
                                    data={props.usersActiveList !== null ? props.usersActiveList : []}/>
                        </FormGroup>
                    </Col>
                    <Col md='12' style={{paddingLeft: '30px'}}>
                        <FormGroup>
                            <Label>QC manager</Label>
                            <Field name="qcManager"
                                    component={renderMultiselect}
                                    textField ='userName'
                                    valueField='userName' disabled={action === 'view' || (!props.checkPermissionFeature('Project_Category', 'edit') && props.canUpdateDetail)}
                                    data={props.usersActiveList !== null ? props.usersActiveList : []}/>
                        </FormGroup>
                    </Col>
                    <Col md='12' style={{marginTop: '30px'}}>
                        <FormGroup>
                            <Label>{_.get(rs,'categories.note')}</Label>
                            <Field name="note" type='text' 
                                component={renderTextarea} 
                                disabled={action === 'view' || (!props.checkPermissionFeature('Project_Category', 'edit') && props.canUpdateDetail)}/>
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
        )
}
export default ProjectMasterForm;
