import React, {Component} from 'react';

class NotFoundPage extends Component {
    render() {
        return (
            <div className="main-box text-center page-not-found">
                <h1>404</h1>
                <h3>Trang này không tìm thấy!</h3>
                <h4>Liên kết có thể bị hỏng</h4>
                <p>hoặc trang có thể đã bị xóa</p>
                <p>Vui lòng liê hệ COFICO Admin để hỗ trợ hoặc quay về Trang chủ</p>
            </div>
        )
    }
}

export default NotFoundPage;