import React from 'react';
import _ from 'lodash';
import { FormGroup, Label, Container,Row, Col } from 'reactstrap';
import { Field} from 'redux-form';
import renderInput from '../controls/renderInput';
import renderCheckbox from '../controls/renderCheckbox';
import renderTextarea from '../controls/renderTextarea';
import renderDropdownList from '../controls/renderDropdownList';
import validate from '../validate/validate';

const ViolationForm = props => {
    const rs = _.get(window.ePayment,'resource');
    return(
        <Container fluid>
            <Row>
                <Col md='12'>
                    <FormGroup>
                        <Label>{_.get(rs,'violationCategory.code')}</Label>
                        <Field name="violationCode" type='text' validate={validate.required} component={renderInput}/>
                    </FormGroup>
                </Col>
                <Col md='12'>
                    <FormGroup>
                        <Label>{_.get(rs,'violationCategory.name')}</Label>
                        <Field name="violationName" type='text' validate={validate.required} component={renderInput}/>
                    </FormGroup>
                </Col>
                <Col md='12'>
                    <FormGroup>
                        <Label>Loại vi phạm</Label>
                        <Field name="type" validate={validate.required}
                                component={renderDropdownList}
                                valueField ='generalCategoryCode'
                                textField ='generalCategoryName'
                                data={props.types !== null ? props.types : []}/>
                    </FormGroup>
                </Col>
                <Col md='12'>
                    <FormGroup>
                        <Label>{_.get(rs,'categories.parrent')}</Label>
                        <Field name="parentID"
                                component={renderDropdownList}
                                valueField ='id'
                                textField ='violationName'
                                data={props.violationParents !== null ? props.violationParents : []}/>
                    </FormGroup>
                </Col>
                <Col md='12'>
                    <FormGroup>
                        <Label>Biện pháp kiểm soát</Label>
                        <Field name="riskControl" type='text' component={renderTextarea}/>
                    </FormGroup>
                </Col>
                <Col md='6'>
                    <FormGroup>
                        <Label>{_.get(rs,'categories.sortorder')}</Label>
                        <Field name="sortOrder" type='text' validate={[validate.number, validate.integer]} component={renderInput}/>
                    </FormGroup>
                </Col>
                <Col md='6'>
                    <FormGroup>
                        <Label>{_.get(rs,'categories.active')}</Label>
                        <Field name="isActive" type='checkbox' component={renderCheckbox}/>
                    </FormGroup>
                </Col>
                {/* <Col md='12'>
                    <FormGroup>
                        <Label>{_.get(rs,'categories.note')}</Label>
                        <Field name="note" type='text' component={renderTextarea}/>
                    </FormGroup>
                </Col> */}
            </Row>
        </Container>
    )
}

export default ViolationForm;
