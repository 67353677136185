import {actionTypes} from '../constants/actionTypes';
import _ from 'lodash';

var initialState = {
    userInfo: null,
    usersActiveList: [],
    usersList: [],
    selectedItem: null,
    groups: null,
    usersInGroup: null,
    featureGroup: null,
    featureUser: null,
    searchUser: null,
    importStatus: null,
    importMessages:null,
    importrun2 : null
}

export function userReducer(state = initialState, action) {
  switch (action.type) { 

    case actionTypes.UPDATE_USERS_DATA:
        const data1 = {...state, categories: action.data};
        return data1;

    case actionTypes.USER_INFO_SUCCESS:
        return Object.assign(state, {userInfo: action.data});

    case actionTypes.LIST_ACTIVEUSERS_SUCCESS:
        return Object.assign(state, {usersActiveList: action.data});

    case actionTypes.LIST_USERS_SUCCESS:
        return Object.assign(state, {usersList: action.data});

    case actionTypes.USER_ITEM_SELECTED:
        stateClone = _.cloneDeep(state);
        stateClone.selectedItem = action.data;
        return stateClone;

    case actionTypes.USER_REGISTER_SUCCESS:
        let stateClone = _.cloneDeep(state);
        const type_action = action.data.type_action;
        let catalog_name = action.data.catalog_name;
        response_data = action.data.response;
        let request_data = action.data.request;
        let ctg = stateClone.usersList;
        if(type_action=='add'){
            // ctg.push(response_data);
            ctg.unshift(response_data);
        }
        else if(type_action === 'update'){
            const index = ctg.findIndex(obj => obj.id === action.data.request.id);
            ctg[index] = request_data;
        }
        // else if(type_action === 'delete'){
        //     const index = ctg.findIndex(obj => obj.id === action.data.request.id);
        //     ctg = ctg.splice(index, 1);
        // }
        // ctg = ctg.sort((a, b) => Number(a.sortOrder) > Number(b.sortOrder));
        return stateClone;
    case actionTypes.GET_GROUP_SUCCESS:
        return Object.assign(state, {groups: action.data});

    case actionTypes.GET_USERINGROUP_SUCCESS:
        return Object.assign(state, {usersInGroup: action.data});

    case actionTypes.GET_FEATUREGROUP_SUCCESS:
        return Object.assign(state, {featureGroup: action.data});

    case actionTypes.GET_FEATUREUSER_SUCCESS:
        return Object.assign(state, {featureUser: action.data});

    case actionTypes.SEARCH_USER_SUCCESS:
        return Object.assign(state, {searchUser: action.data});

    case actionTypes.ADD_USER_SUCCESS:
        stateClone = _.cloneDeep(state);
        let users = stateClone.usersInGroup;
        let response_data = action.data.response;
        let user = _.find(users, (s) => {return s.userName === action.data.user;});
        if (user === undefined || user === null){
            response_data = Object.assign(response_data, {userName: action.data.user.userName, fullName: action.data.fullName});
            users.push(response_data);
        }
        return stateClone;

    case actionTypes.DELETE_USER_SUCCESS:
        stateClone = _.cloneDeep(state);
        users = stateClone.usersInGroup;
        response_data = action.data.response;
        const index = users.findIndex(obj => obj.rowId === action.data.rowId);
        users = users.splice(index, 1);
        return stateClone;
    case actionTypes.RESET_SEARCH_USER_MODAL:
        return {
            ...state,
            searchUser:null
        }
    case actionTypes.UPDATE_IMPORT_STATUS:
        let stateCloneImportStatus = _.cloneDeep(state);
        stateCloneImportStatus.importStatus = null;
        return stateCloneImportStatus;
    case actionTypes.USER_IMPORT_SUCCESS:
        let stateCloneImportSuccess = _.cloneDeep(state);
        stateCloneImportSuccess.importStatus = action.data.response.status;
        return stateCloneImportSuccess;
    case actionTypes.USER_IMPORT_FAILURE:
        let stateCloneImportSuccessFail = _.cloneDeep(state);
        stateCloneImportSuccessFail.importStatus = action.data.response.response.status;
        stateCloneImportSuccessFail.importMessages = action.data.response.response.data.content.items;
        return stateCloneImportSuccessFail;
        //return Object.assign(state, {importStatus: action.data.response.status});
        

    default:
      return state;
  }
}