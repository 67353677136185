import React, {Component} from 'react';
import { Field} from 'redux-form';
import { Table} from 'reactstrap';
import {compose} from 'redux';
import { connect } from 'react-redux';
import commonWrapped from '../hocs/commonWrapped';
import withPermission from '../hocs/withPermission';
import renderCheckbox from '../controls/renderCheckbox';


class renderPermission extends Component{
    constructor(props){
        super(props);
    }

    render(){
        const {fields, checkPermissionFeature} = this.props;
        return(
            <div>
                {fields != null && fields !== undefined && fields.length >0 &&
                <Table bordered responsive striped>
                    <thead>
                        <tr>
                            <th className=''>Chức năng</th>
                            <th className='w-px-100 text-center'>Xem</th>
                            <th className='w-px-100 text-center'>Thêm</th>
                            <th className='w-px-100 text-center'>Sửa</th>
                            <th className='w-px-100 text-center'>Xóa</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(fields.map((feature, i) => {
                            const values = fields.get(i);
                            return (
                                <tr key={i}>
                                    <td className=''>{values.featureName}</td>
                                    <td className='w-px-100 text-center'><Field name={`${feature}.view`} component={renderCheckbox} type='checkbox' disabled={!checkPermissionFeature('Permission', 'edit')} /></td>
                                    <td className='w-px-100 text-center'><Field name={`${feature}.add`} component={renderCheckbox} type='checkbox' disabled={!checkPermissionFeature('Permission', 'edit')} /></td>
                                    <td className='w-px-100 text-center'><Field name={`${feature}.edit`} component={renderCheckbox} type='checkbox' disabled={!checkPermissionFeature('Permission', 'edit')} /></td>
                                    <td className='w-px-100 text-center'><Field name={`${feature}.delete`} component={renderCheckbox} type='checkbox' disabled={!checkPermissionFeature('Permission', 'edit')} /></td>
                                </tr>
                            );
                        }))}
                    </tbody>
                </Table>}
            </div>
        );
    }
}

export default compose(
    connect(),
    commonWrapped({showLoadingWholePage:true}),
    withPermission()
)(renderPermission);
