import React from 'react';
import _ from 'lodash';
import { Table } from 'reactstrap';

const renderUser = (rs, categoryList, editItemFn,resetPasswordItemFn, checkPermissionFeature) => {
  return(
    <Table bordered responsive striped hover>
        <thead>
        <tr>
            {checkPermissionFeature('User', 'edit') && <th className="action"></th>}
            <th className="w-px-200">Tên đăng nhập</th>
            <th className="w-px-200">Họ tên</th>
            <th>Email</th>
            <th className="w-px-150">Khóa tài khoản</th>
        </tr>
        </thead>
        <tbody>
        {categoryList !== null && categoryList !== undefined && categoryList.length == 0 && (
                <tr>
                    <td colSpan="5" className="text-center">Không có dữ liệu</td>
                </tr>
            )}
            {categoryList !== null && categoryList !== undefined && categoryList.length > 0 &&
            (categoryList.map((item, i) => {
                return (
                    <tr key={i}>
                      {checkPermissionFeature('User', 'edit') && <td className="text-center">
                          <button type="button" className="i-btn cta cta-edit" onClick={(e)=>{editItemFn&&editItemFn(item);}}>
                          <i className='fa fa-pencil' aria-hidden="true"></i></button>
                          <><button type="button" className="i-btn cta cta-edit" onClick={(e)=>{resetPasswordItemFn&&resetPasswordItemFn(item);}}>
                            <svg style={{fill:"#358cb3",marginTop:"-2px",width:"19px",height:"19px"}} version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                                <g><path d="M618.9,438.7c35.8,0,64.8,31.6,64.8,70.7v164.9c0,39-29,70.7-64.8,70.7H381.3c-35.8,0-64.8-31.6-64.8-70.7V509.4c0-39,29-70.7,64.8-70.7h57.5l0,0h122.5l0,0H618.9z M499.5,500.2c-33.8,0-61.3,26.2-61.3,58.6c0,21.6,16.8,38.2,35,48.3v76.1h52.5v-76.1c18.3-10.2,35-26.7,35-48.3C560.8,526.4,533.4,500.2,499.5,500.2z M500,316.3c-57.6,0-61.1,48.4-61.3,61.3v61.1h-61.6v-55.2c0,0,0-128.6,122.7-128.6c122.7,0,122.7,128.6,122.7,128.6v55.2h-61.2v-61.1C561.1,365,557.6,316.3,500,316.3z M500,990.1c-270.6,0-490-219.4-490-490c0-270.6,219.4-490,490-490c87.2,0,169,22.9,240,62.9l45.6-63.1l81.6,214.5L653.2,193l50.5-69.9C643.1,90.3,573.8,71.4,500,71.4c-236.8,0-428.8,192-428.8,428.8c0,236.8,192,428.7,428.8,428.7c236.8,0,428.8-192,428.8-428.7c0-57.8-11.6-112.9-32.3-163.2l56.6-23.3c23.7,57.5,37,120.4,37,186.5C990,770.7,770.6,990.1,500,990.1z"/></g>
                            </svg>
                          </button></>
                          {/* {item.provider === null || item.provider==="" 
                          ? <><button type="button" className="i-btn cta cta-edit" onClick={(e)=>{resetPasswordItemFn&&resetPasswordItemFn(item);}}>
                            <svg style={{fill:"#358cb3",marginTop:"-2px",width:"19px",height:"19px"}} version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                                <g><path d="M618.9,438.7c35.8,0,64.8,31.6,64.8,70.7v164.9c0,39-29,70.7-64.8,70.7H381.3c-35.8,0-64.8-31.6-64.8-70.7V509.4c0-39,29-70.7,64.8-70.7h57.5l0,0h122.5l0,0H618.9z M499.5,500.2c-33.8,0-61.3,26.2-61.3,58.6c0,21.6,16.8,38.2,35,48.3v76.1h52.5v-76.1c18.3-10.2,35-26.7,35-48.3C560.8,526.4,533.4,500.2,499.5,500.2z M500,316.3c-57.6,0-61.1,48.4-61.3,61.3v61.1h-61.6v-55.2c0,0,0-128.6,122.7-128.6c122.7,0,122.7,128.6,122.7,128.6v55.2h-61.2v-61.1C561.1,365,557.6,316.3,500,316.3z M500,990.1c-270.6,0-490-219.4-490-490c0-270.6,219.4-490,490-490c87.2,0,169,22.9,240,62.9l45.6-63.1l81.6,214.5L653.2,193l50.5-69.9C643.1,90.3,573.8,71.4,500,71.4c-236.8,0-428.8,192-428.8,428.8c0,236.8,192,428.7,428.8,428.7c236.8,0,428.8-192,428.8-428.7c0-57.8-11.6-112.9-32.3-163.2l56.6-23.3c23.7,57.5,37,120.4,37,186.5C990,770.7,770.6,990.1,500,990.1z"/></g>
                            </svg>
                          </button></> 
                          :<><button type="button" className="i-btn cta" disabled={true} style={{cursor : "no-drop"}}>
                          <svg  style={{fill:"#9999",marginTop:"-2px",width:"19px",height:"19px"}} version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                              <g><path d="M618.9,438.7c35.8,0,64.8,31.6,64.8,70.7v164.9c0,39-29,70.7-64.8,70.7H381.3c-35.8,0-64.8-31.6-64.8-70.7V509.4c0-39,29-70.7,64.8-70.7h57.5l0,0h122.5l0,0H618.9z M499.5,500.2c-33.8,0-61.3,26.2-61.3,58.6c0,21.6,16.8,38.2,35,48.3v76.1h52.5v-76.1c18.3-10.2,35-26.7,35-48.3C560.8,526.4,533.4,500.2,499.5,500.2z M500,316.3c-57.6,0-61.1,48.4-61.3,61.3v61.1h-61.6v-55.2c0,0,0-128.6,122.7-128.6c122.7,0,122.7,128.6,122.7,128.6v55.2h-61.2v-61.1C561.1,365,557.6,316.3,500,316.3z M500,990.1c-270.6,0-490-219.4-490-490c0-270.6,219.4-490,490-490c87.2,0,169,22.9,240,62.9l45.6-63.1l81.6,214.5L653.2,193l50.5-69.9C643.1,90.3,573.8,71.4,500,71.4c-236.8,0-428.8,192-428.8,428.8c0,236.8,192,428.7,428.8,428.7c236.8,0,428.8-192,428.8-428.7c0-57.8-11.6-112.9-32.3-163.2l56.6-23.3c23.7,57.5,37,120.4,37,186.5C990,770.7,770.6,990.1,500,990.1z"/></g>
                         </svg></button></>} */}
                          
                      </td>}
                      <td>{item.userName}</td>
                      <td>{item.fullName}</td>
                      <td>{item.email}</td>
                      <td className="text-center">
                        <div className="checkbox">
                            <label>
                                <input type="checkbox" checked={item.lockoutEnabled} disabled/>
                                <span className="icon-checked">
                                    <svg className="icon" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title></title><defs><path id="am" d="M8.926 18.652L4 13.725l1.767-1.768 3.074 3.074L17.959 5l1.849 1.682z"></path></defs><g fill="none" fillRule="evenodd"><path d="M0 0h24v24H0z"></path><mask id="bm" fill="#fff"><use xlinkHref="#am"></use></mask><path d="M0 0h24v24H0z" mask="url(#bm)" fill="#212121"></path></g></svg>
                                </span>
                            </label>
                        </div>
                      </td>
                  </tr>
                );
            }))}

        </tbody>
    </Table>
  );
}

const UserTable = props => {
    const {catalogName, categoryList, editItemFn,resetPasswordItemFn, checkPermissionFeature} = props;
    const rs = _.get(window.ePayment,'resource');
    return(
        <div>
          {renderUser(rs, categoryList, editItemFn,resetPasswordItemFn, checkPermissionFeature)}
        </div>
    )
}

export default UserTable;
