import React from 'react';
import _ from 'lodash';
import { FormGroup, Label, Container,Row, Col } from 'reactstrap';
import { Field} from 'redux-form';
import renderInput from '../controls/renderInput';
import renderCheckbox from '../controls/renderCheckbox';
import renderTextarea from '../controls/renderTextarea';
import validate from '../validate/validate';

const ContractorForm = () => {
    const rs = _.get(window.ePayment,'resource');
    return(
        <Container fluid>
            <Row>
                <Col md='12'>
                    <FormGroup>
                        <Label>{_.get(rs,'contractorCategory.code')}</Label>
                        <Field name="contractorCode" type='text' validate={validate.required} component={renderInput}/>
                    </FormGroup>
                </Col>
                <Col md='12'>
                    <FormGroup>
                        <Label>{_.get(rs,'contractorCategory.name')}</Label>
                        <Field name="contractorName" type='text' validate={validate.required} component={renderInput}/>
                    </FormGroup>
                </Col>
                <Col md='12'>
                    <FormGroup>
                        <Label>{_.get(rs,'categories.note')}</Label>
                        <Field name="note" type='text' component={renderTextarea}/>
                    </FormGroup>
                </Col>
                <Col md='12'>
                    <FormGroup>
                        <Label>{_.get(rs,'categories.active')}</Label>
                        <Field name="isActive" type='checkbox' component={renderCheckbox}/>
                    </FormGroup>
                </Col>
            </Row> 
        </Container>
    )
}

export default ContractorForm;