
import config from 'config';
import axios from 'axios';
import ApiEndpoints from '../constants/ApiEndpoints';

const permissionService = {
    getGroups,
    getUsersInGroup,
    getFeatureForGroup,
    getFeatureForUser,
    setFeatureForGroup,
    setFeatureForUser,
    searchUser,
    addUser,
    removeUser,
    getUserInfo
};

function searchUser(txt) {
    let url = `${config.apiUrl}/${ApiEndpoints.permissionSearch}?search=${txt}`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res);
        },function(e){
            reject(e);
        });
    });
}

function addUser(data) {
    let url = `${config.apiUrl}/${ApiEndpoints.permission}/SecurityUserGroupSubmit`;
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function getGroups() {
    let url = `${config.apiUrl}/${ApiEndpoints.permissionGroup}`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function getUsersInGroup(groupId) {
    let url = `${config.apiUrl}/${ApiEndpoints.permissionUser}/${groupId}`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function setFeatureForGroup(data) {
    let url = `${config.apiUrl}/${ApiEndpoints.permissionSetGroup}`;
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(res=>{
            resolve(res);
        },function(e){
            reject(e);
        });
    });
}

function getFeatureForGroup(groupId) {
    let url = `${config.apiUrl}/${ApiEndpoints.permissionFeatureGroup}/${groupId}`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function setFeatureForUser(data) {
    let url = `${config.apiUrl}/${ApiEndpoints.permissionSetUser}`;
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(res=>{
            resolve(res);
        },function(e){
            reject(e);
        });
    });
}

function getFeatureForUser(user) {
    let url = `${config.apiUrl}/${ApiEndpoints.permissionFeatureUser}/${user}`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

function removeUser(data){
    let url = `${config.apiUrl}/${ApiEndpoints.permission}/${data.rowId}`;
    return new Promise((resolve, reject) => {
        axios.delete(url).then(res=>{
            resolve(res);
        },function(e){
            reject(e);
        });
    });
}

function getUserInfo() {
    let url = `${config.apiUrl}/${ApiEndpoints.permissionGetUserInfo}`;
    return new Promise((resolve, reject) => {
        axios.get(url).then(res=>{
            resolve(res.data.content.items);
        },function(e){
            reject(e);
        });
    });
}

export default permissionService;
