import React from 'react';
import _ from 'lodash';
import { FormGroup, Label, Table, Row, Col, Button, Modal, ModalHeader, ModalBody, Container} from 'reactstrap';
import renderInput from '../controls/renderInput';
import { Field, reduxForm, reset} from 'redux-form';
import validate from '../validate/validate';
import {compose} from 'redux';
import { connect } from 'react-redux';
import commonWrapped from '../hocs/commonWrapped';
import { permissionAction } from '../actions/permissionAction';
import ConfirmModal from '../controls/confirmModal';


class AddUserInGroupPerModal extends React.Component{
    constructor (props, context){
        super(props, context);
        this.state={
            confirmModalData:{
                data:null,
                isOpen: false,
                mdTitle: 'Alert',
                mdBody: null
            },
            dataAfterFilter: []
        }
        this.debouncedOnChange = _.debounce(this.debouncedOnChange.bind(this), 200);
    }
    componentWillReceiveProps(){
        if(!this.props.isOpen){
            this.setState({dataAfterFilter: []});
        }
    }

    isMatch(txtField, txtSearch){
        if (txtField !== null && txtField !== undefined && _.isMatch(txtField.toLowerCase(),txtSearch))
            return true;
        return false;
    }

    searchUser = (values) => {
        let textSearch = values.user;
        let dataAfterFilter = this.props.usersActiveList;
        textSearch = textSearch?_.escapeRegExp(textSearch).trim().toLowerCase():'';
        if(textSearch !== ''){
          dataAfterFilter = this.props.usersActiveList && this.props.usersActiveList.filter((item) =>{
              if (this.isMatch(item.email,textSearch) || this.isMatch(item.fullName,textSearch) || this.isMatch(item.userName,textSearch))
                  return item;
          })
        }
        this.props.dispatch(reset('addUserInGroupPerForm'));
        this.debouncedOnChange(dataAfterFilter);
    }

    debouncedOnChange(dataAfterFilter) {
      this.setState({dataAfterFilter});
    }

    render(){
        const { dataAfterFilter } = this.state;
        const {groupId, usersInGroup, searchUser, isOpen, mdTitle, toggleFn, checkPermissionFeature} = this.props;
        const { handleSubmit, pristine, submitting, valid, reset } = this.props;
        
        return(
            <div>
                <Modal isOpen={isOpen} toggle={toggleFn} wrapClassName='e-safety' backdrop='static' className='searchModal'>
                    <ModalHeader toggle={toggleFn}>{mdTitle}</ModalHeader>
                    <ModalBody>
                    <Container fluid>
                    <form autoComplete="off" role="presentation" onSubmit={handleSubmit} className='scrollTableModal'>
                        <Row>
                            <Col md='3' className='mt-2'>
                                <FormGroup>
                                    <Label>Tìm kiếm nhân viên</Label>
                                </FormGroup>
                            </Col>
                            <Col md='6'>
                                <FormGroup>
                                    <Field name="user" autoComplete="off" component={renderInput} validate={[validate.required]} placeholder='Enter a name or email address...'/>
                                </FormGroup>
                            </Col>
                            <Col md='3' className='text-right'>
                                <FormGroup>
                                    <Button color="info" type="button" onClick={handleSubmit(values => this.searchUser({...values, type: 'search'}))} disabled={!valid || pristine || submitting}>Tìm kiếm</Button>{' '}
                                </FormGroup>
                            </Col>
                        </Row>
                        {(dataAfterFilter !== null && dataAfterFilter !== undefined) &&
                        [<Row key='1'>
                            <Col md='12'>
                                <Label className='text-secondary font-weight-bold'>Kết quả tìm kiếm</Label>
                            </Col>
                        </Row>,
                        dataAfterFilter.length > 0 ? <Table key='2' bordered responsive striped>
                            <thead>
                                <tr key='searchuser'>
                                    <th className='w-50'>Tên đăng nhập</th>
                                    <th className='w-50'>Họ tên</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataAfterFilter !== undefined && dataAfterFilter !== null && dataAfterFilter.length > 0 && dataAfterFilter.map((item, i) => {
                                    return(
                                        <tr key={`result-${i}`}>
                                            <td className='advandeNo w-50' onClick={() => {this.props.onClickSelectUser(groupId, item);}}>{item.userName}</td>
                                            <td className='w-50'>{item.fullName}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>: <div key="no-data">Không có dữ liệu</div>
                        ]}

                        {usersInGroup !== null && usersInGroup !== undefined && usersInGroup.length > 0 &&
                            [<Row key='3'>
                                <Col md='12' className='mt-3'>
                                    <Label className='text-secondary font-weight-bold'>Nhân viên trong nhóm</Label>
                                </Col>
                            </Row>,
                            <Table key='4' bordered responsive striped>
                            <thead>
                                <tr>
                                    {checkPermissionFeature('Permission', 'delete') && <th className='action'></th>}
                                    <th className='w-25'>Họ tên</th>
                                    <th className='w-25'>Tên đăng nhập</th>
                                    <th className='w-50'>Ghi chú</th>
                                </tr>
                            </thead>
                            <tbody>
                            {usersInGroup.map((item, i) =>
                            <tr key={i}>
                                {checkPermissionFeature('Permission', 'delete') && <td className='action'>
                                    <button type="button" className="i-btn cta cta-delete"
                                        onClick={(e)=>{
                                            const sefl = this;
                                            var confirmModalData = this.state.confirmModalData;
                                            confirmModalData.isOpen = !confirmModalData.isOpen;
                                            confirmModalData.data = item;
                                            confirmModalData.mdBody= `Bạn thật sự muốn xóa nhân viên "${item.fullName}" trong group?`;
                                            confirmModalData.okFn = (data)=>{
                                                sefl.props.onClickRemoveItem(data)
                                            }
                                            this.setState(confirmModalData);
                                            }}>
                                    <i className="fa fa-trash-o" aria-hidden="true"></i></button>
                                </td>}
                                <td className='w-25'>{item.fullName}</td>
                                <td className='w-25'>{item.userName}</td>
                                <td className='w-50'>{item.note}</td>
                            </tr>)}
                            </tbody>
                            </Table>]}
                    </form>
                    </Container>

                    </ModalBody>
                </Modal>
                <ConfirmModal {...this.state.confirmModalData} toggleFn={()=>{
                    let confirmModalData = this.state.confirmModalData;
                    confirmModalData.isOpen = !confirmModalData.isOpen;
                    this.setState({confirmModalData});
                }}/>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = (dispatch,props) => ({
    // onSubmit: values => {
    //     if(values.type === 'search'){
    //         dispatch(permissionAction.searchUser(values.user));
    //         dispatch(reset('addUserInGroupPerForm'));
    //     }
    // },
    onClickRemoveItem: values => {
        dispatch(permissionAction.removeUser(values));
    }
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'addUserInGroupPerForm'
    }),
    commonWrapped()
)(AddUserInGroupPerModal);
