const ApiEndpoints ={
    accidentCate: 'api/AccidentCategory',

    contractorCate: 'api/Contractor',

    imageCate: 'api/ImageCategory',

    projectCate: 'api/Project',
    projectTowerFacZone: 'api/ProjectTowerFacZone',

    trainingCate: 'api/TrainingCategory',

    violationCate: 'api/ViolationCategory',

    punishmentCate: 'api/ViolationPunishment',

    hazardousCate: 'api/HazardCategory',

    teamCate: 'api/Team',

    user: 'api/User',

    permission: 'api/Permission',
    permissionGetUserInfo: 'api/Permission/GetUserInfo',
    permissionSearch: 'api/Permission/SearchUser',
    permissionAdd: 'api/Permission/AddUserToGroup',
    permissionGroup: 'api/Permission/GetSecurityGroups',
    permissionUser: 'api/Permission/GetSecurityUserGroups',
    permissionFeatureGroup: 'api/Permission/GetSecurityGroupPermissionsFeatures',
    permissionFeatureUser: 'api/Permission/GetSecurityUserPermissionsFeatures',
    permissionSetGroup: 'api/Permission/SecurityGroupPermissionsSubmit',
    permissionSetUser: 'api/Permission/SecurityUserPermissionsSubmit',
}
export default ApiEndpoints
