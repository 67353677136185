import React, {PropTypes} from 'react';
import {FieldProps} from './FieldProps';
import {inputError} from './inputError';

const renderInput = ({input, placeholder, autoComplete, meta: { touched, error, warning }, showError, showWarn, ...rest}) =>{
	return (
		<div>
			<input
				{...input}
				{...rest}
				onChange={input.onChange}
				autoComplete={autoComplete || 'off'}
				autoCorrect="off"
				spellCheck="off"
				placeholder={placeholder} 
				className={`form-control input-config ${error ? ' is-invalid' : ''}`}
			/>
			{touched &&
						  ((error && <span className="text-danger">{error}</span>) ||
							(warning && <span>{warning}</span>))}
		</div>
	);
}

renderInput.propTypes = {
	...FieldProps
};

export default inputError(renderInput);
