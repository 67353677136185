import React, {Component} from 'react';
import _ from 'lodash';
import CategoryListing from '../components/CategoryListing';
import {history} from '../utility/history';


class CategoryPage extends Component {
    constructor (props, context) {
        super(props,context);
        this.state={
            catalogName: null,
            rs: _.get(window.ePayment,'resource'),
            actionType: null,
            featureName: null
        };
        this.getCatalogName = this.getCatalogName.bind(this);
        this.getFeatureName = this.getFeatureName.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const currentname = _.camelCase(this.props.match.params.name);
        let name = this.getCatalogName(currentname);
        let featureName = this.getFeatureName(currentname);
        if(this.state.catalogName !== name){
            this.setState({'catalogName': name, 'featureName': featureName});
        }
    }
    componentDidMount(){
        const reportArr = ['duAn', 'nhaThau', 'suCo', 'huanLuyenAnToan', 'viPham', 'loiViPham','hinhAnhCongTruong','moiNguy','toDoi'];
        const hasRoute = reportArr.includes(_.camelCase(this.props.match.params.name));
        if(!hasRoute){
            history.push('/not-found');
        }
        let currentname = _.camelCase(this.props.match.params.name);
        let name = this.getCatalogName(currentname);
        let featureName = this.getFeatureName(currentname);
        this.setState({'catalogName': name, 'featureName': featureName});
    }

    getCatalogName (currentname){
        let name;
        switch(currentname) {
            case _.camelCase('du-an'):
                name = _.camelCase('project')
                return name;
            case _.camelCase('nha-thau'):
                name = _.camelCase('contractor')
                return name;
            case _.camelCase('su-co'):
                name = _.camelCase('accident')
                return name;
            case _.camelCase('huan-luyen-an-toan'):
                name = _.camelCase('training')
                return name;
            case _.camelCase('vi-pham'):
                name = _.camelCase('violation')
                return name;
            case _.camelCase('loi-vi-pham'):
                name = _.camelCase('punishment')
                return name;
            case _.camelCase('hinh-anh-cong-truong'):
                name = _.camelCase('image')
                return name;
            case _.camelCase('moi-nguy'):
                name = _.camelCase('hazardous')
                return name;
            case _.camelCase('to-doi'):
                name = _.camelCase('team')
                return name;
            default:
                name = _.camelCase('project')
                return name;
        }
    }

    getFeatureName (currentname){
        let name;
        switch(currentname) {
            case _.camelCase('du-an'):
                name = 'Project_Category'
                return name;
            case _.camelCase('nha-thau'):
                name = 'Contractor_Category'
                return name;
            case _.camelCase('su-co'):
                name = 'Accident_Category'
                return name;
            case _.camelCase('huan-luyen-an-toan'):
                name = 'Training_Category'
                return name;
            case _.camelCase('vi-pham'):
                name = 'Violation_Category'
                return name;
            case _.camelCase('loi-vi-pham'):
                name = 'Punishment_Category'
                return name;
            case _.camelCase('hinh-anh-cong-truong'):
                name = 'Image_Category'
                return name;
            case _.camelCase('moi-nguy'):
                name = 'Hazard_Category'
                return name;
            case _.camelCase('to-doi'):
                name = 'Team_Category'
                return name;
            default:
                name = 'Project_Category'
                return name;
        }
    }

    _handleFormCategory=(type_action)=>{
        this.setState({actionType: type_action});
    }

    _handleChangeTypeAction = () => {
      this.setState({actionType: null});
    }

    render() {
        const {catalogName, featureName} = this.state;

        return (
            <div className="main-box">
                <h5 className="page-header">
                    {catalogName==='project' && <span>{_.get(this.state.rs,'categories.projects')}</span>}
                    {catalogName==='contractor' && <span>{_.get(this.state.rs,'categories.contractors')}</span>}
                    {catalogName==='accident' && <span>{_.get(this.state.rs,'categories.accidents')}</span>}
                    {catalogName==='training' && <span>{_.get(this.state.rs,'categories.trainings')}</span>}
                    {catalogName==='violation' && <span>{_.get(this.state.rs,'categories.violations')}</span>}
                    {catalogName==='punishment' && <span>{_.get(this.state.rs,'categories.punishments')}</span>}
                    {catalogName==='image' && <span>{_.get(this.state.rs,'categories.images')}</span>}
                    {catalogName==='hazardous' && <span>{_.get(this.state.rs,'categories.hazardous')}</span>}
                    {catalogName==='team' && <span>{_.get(this.state.rs,'categories.team')}</span>}
                </h5>

                {catalogName !== null && <CategoryListing
                  catalogName={catalogName}
                  featureName={featureName}
                  actionType={this.state.actionType}
                  handleFormCategory={this._handleFormCategory}
                  handleChangeTypeAction={this._handleChangeTypeAction}/>}
            </div>
        )
    }
}

export default CategoryPage;
